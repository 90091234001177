import { Component, OnInit, OnDestroy, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { PassDataService } from 'src/app/services/pass-data.service';
import { Constants } from 'src/app/Constants/constants';
import { Utils } from 'src/app/common/utils';

@Component({
  selector: 'app-service-request-vehicle',
  templateUrl: './service-request-vehicle.component.html',
  styleUrls: ['./service-request-vehicle.component.scss']
})
export class ServiceRequestVehicleComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isFromProfile: any;
  @Input() edititem: any;
  @Input() close: any;
  @Input() vehEdit:any
  @Output() public sendMsgToProfile = new EventEmitter<boolean>()


  vehicleYearControl = new FormControl(undefined, [Validators.required]);
  vehicleBrandControl = new FormControl(undefined, [Validators.required]);
  vehicleModelControl = new FormControl(undefined, [Validators.required]);
  vehicleEngineControl = new FormControl('', [Validators.required]);
  vehicleTransmissionControl = new FormControl('', [Validators.required]);
  fuelTypeControl = new FormControl('', [Validators.required]);
  mileageControl = new FormControl()
  carPlateControl = new FormControl()
  chasisNoControl = new FormControl()
  isAlive: boolean = true
  vehicleData: boolean = false;
  existvehicleData : boolean = false
  userDetails: any;
  backPressCount: number = 0;
  vechicleList: any;
  showButton: boolean = false
  vehicleid: any = ''
  generationid:any=''
  seriesid:any=''
  btnDisabled: boolean = false
  isDataLoading = false;
  disableField: any = {
    year: false,
    make: true,
    model: true,
    engine: true,
    transmission: true,
    fuelType: true,
    mileage: true,
  }
  vehicleModelYears: string[] = [];
  vehicleBrand: any = [];
  vehicleBrandName: string [] = [];
  vehicleModel: any = []
  vehicleModelName: string []= []
  vehicleEngine: any[] = [];
  vehicleEngineName: string[] = []
  vehicleTransmissionName: string[] = []
  vehicleFuelName: string[] = []
  showVehicleChangedPopup: boolean = false
  orderServiceVehicle: any;
  isMileageActive:boolean = false;
  isPlateActive:boolean = false;
  isChasisActive:boolean = false;

  vehicleSingleData: any = []

  constructor(private sharedService: SharedServiceService,
    private route: Router,public utils: Utils,
    private translate: TranslateService,
    private bookService: BookingServiceService,
    private passDataService: PassDataService, private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.edititem) {
      this.showButton = true
      let make = {
        id: this.edititem.dataItem.make_id,
        make: this.edititem.dataItem.make_name
      }
      let model = {
        id: this.edititem.dataItem.model_id,
        name: this.edititem.dataItem.model_name
      }
      let trim = {
        id: this.edititem.dataItem.trim_id,
        name:this.edititem.dataItem.trim_name
      }
      if(this.edititem.dataItem.transmission_type === ''){
        this.edititem.dataItem.transmission_type = '---'
      }
      this.vehicleYearControl.setValue(this.edititem.dataItem.start_production_year)
      this.vehicleBrandControl.setValue(make)
      this.vehicleModelControl.setValue(model)
      this.vehicleEngineControl.setValue(trim)
      this.vehicleTransmissionControl.setValue(this.edititem.dataItem.transmission_type)
      this.fuelTypeControl.setValue(this.edititem.dataItem.fuel_type)
      this.mileageControl.setValue(this.edititem.dataItem.mileage)
      this.carPlateControl.setValue(this.edititem.dataItem.plate)
      this.chasisNoControl.setValue(this.edititem.dataItem.VIN)
      this.vehicleid = this.edititem.dataItem.vechicle_id
      this.generationid=this.edititem.dataItem.generation_id
      this.seriesid=this.edititem.dataItem.series_id
      this.disableField.year = true
      this.disableField.make = true
      this.disableField.model = true
      this.disableField.engine = true
      this.disableField.transmission = true
      this.disableField.fuelType = true
      this.disableField.mileage = false
      this.btnValidate()
    }
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    if (userdata != undefined) {
      this.userDetails = JSON.parse(userdata)
      let vehicle = JSON.parse(sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE) || '{}')
      if(vehicle.quote_id){
        this.orderServiceVehicle = vehicle
      }
    }
    if (this.userDetails == undefined) {
      this.vechicleList = sessionStorage.getItem(Constants.APP.SESSION_VECHICLE_LIST)
      if (this.vechicleList != undefined && this.vechicleList != null && !this.isFromProfile) {
        this.vehicleData = true
        this.backPressCount = 1
        this.sharedService.updateShowingAddService(true)
      } else {
        this.backPressCount = 0
        this.vehicleData = false
        this.sharedService.updateShowingAddService(false)

      }
      this.existvehicleData = true
    } else {
      this.vehicleData = this.isFromProfile ? false : true
      this.fetchVehicleByUser()
    }

    this.passDataService.updateVehicleData('')
    this.passDataService.getBackPressed().pipe(takeWhile(() => this.isAlive)).subscribe((data: any) => {
      if (data === '2') {
        this.previousStep()
      }
    })
    this.sharedService.popupResponse$.pipe((takeWhile(() => this.isAlive))).subscribe((data) => {
      if(data == 'vehicleChanged'){
        this.orderServiceVehicle = undefined;
        this.nextStepper()
      }
    })
    this.fetchVechicleYear();
    if(this.vehicleData){
      this.sharedService.updateShowingAddService(true)
    }else{
      this.sharedService.updateShowingAddService(false)
    }

    if(this.mileageControl.value){
      this.isMileageActive = true;
    }
    if(this.carPlateControl.value){
      this.isPlateActive = true;
    }
    if(this.chasisNoControl.value){
      this.isChasisActive = true;
    }
  }
  onFocus(field: string) {  
    if(field === 'mileage'){
      this.isMileageActive = true;
    }
    else if(field === 'plate'){
      this.isPlateActive = true;
    }
    else if(field === 'chasis'){
      this.isChasisActive = true;
    }
  }
  
  onBlur(field: string) {  
    if(field === 'mileage' && !this.mileageControl.value){
      this.isMileageActive = false;
    }
    else if(field === 'plate'&& !this.carPlateControl.value){
      this.isPlateActive = false;
    }
    else if(field === 'chasis'&& !this.chasisNoControl.value){
      this.isChasisActive = false;
    }
  }
  modelYearChanged(data: string){
    if(data){
      this.fetchVechicleMake(data)
      this.disableField.make = false
    } else {
      this.disableField.make = true
    }
    this.vehicleYearControl.setValue(data)
    this.disableField.model = true
    this.disableField.engine = true
    this.disableField.transmission = true
    this.disableField.fuelType = true
    this.disableField.mileage = true
    this.vehicleBrandControl.setValue('')
    this.vehicleModelControl.setValue('')
    this.vehicleEngineControl.setValue('')
    this.vehicleTransmissionControl.setValue('')
    this.fuelTypeControl.setValue('')
    this.mileageControl.setValue('')
  }
  makeChanged(data: string){
    let vehicle = this.vehicleBrand?.find((item: any) => item.make == data)
    if(vehicle){
      this.vehicleBrandControl.setValue(vehicle)
      this.fetchVechicleModel(this.vehicleYearControl.value, `${vehicle.id}`)
      this.disableField.model = false
    } else {
      this.disableField.model = true
    }
    this.disableField.engine = true
    this.disableField.transmission = true
    this.disableField.fuelType = true
    this.disableField.mileage = true
    this.vehicleModelControl.setValue('')
    this.vehicleEngineControl.setValue('')
    this.vehicleTransmissionControl.setValue('')
    this.fuelTypeControl.setValue('')
    this.mileageControl.setValue('')
  }
  modelChanged(data: string){
    let model = this.vehicleModel.find((item: any) => item.name == data)
    if(model){
      this.vehicleModelControl.setValue(model)
      this.fetchVechicleTrim(this.vehicleYearControl.value, `${this.vehicleBrandControl.value.id}`, `${this.vehicleModelControl.value.id}`, this.vehicleModelControl.value.name)
      this.disableField.engine = false
    }else{
      this.disableField.engine = true
    }
    this.vehicleTransmissionName = []
    this.vehicleFuelName = []
    this.disableField.transmission = true
    this.disableField.fuelType = true
    this.disableField.mileage = true
    this.vehicleEngineControl.setValue('')
    this.vehicleTransmissionControl.setValue('')
    this.fuelTypeControl.setValue('')
    this.mileageControl.setValue('')
    this.cdRef.detectChanges(); // Manually trigger change detection
  }
  engineChanged(data: string){
    this.vehicleTransmissionName = []
    this.vehicleFuelName = []
    this.vehicleEngineControl.setValue('')
    this.vehicleTransmissionControl.setValue('')
    if(data){
      let engine = this.vehicleSingleData?.filter((item: { name: string; }) => item.name == data)
      if(engine){
        this.vehicleEngineControl.setValue(engine[0])
        if(engine.length == 1){
          if(engine[0].transmission_type === ''){
            this.vehicleTransmissionName.push('---')
            this.vehicleTransmissionControl.setValue('---')

          }else{
            this.vehicleTransmissionName.push(engine[0].transmission_type)
            this.vehicleTransmissionControl.setValue(engine[0].transmission_type)
          }
          if(engine.fuel_type === ''){
            this.vehicleFuelName.push('---')
            this.fuelTypeControl.setValue('---')
          }else{
            this.vehicleFuelName.push(engine[0].fuel_type)
            this.fuelTypeControl.setValue(engine[0].fuel_type)
          }
          this.disableField.transmission = false
          this.disableField.fuelType = false
          this.disableField.mileage = false
          this.mileageControl.setValue('')
        }else{
          engine.forEach((item:any) => {
            if(!this.vehicleTransmissionName.includes(item.transmission_type)){
              this.vehicleTransmissionName.push(item.transmission_type)
            }
          })
          if(this.vehicleTransmissionName.length == 1){
            engine.forEach((item: any) => {
              if(!this.vehicleFuelName.includes(item.fuel_type)){
                this.vehicleFuelName.push(item.fuel_type)
              }
            })
            this.disableField.fuelType = false
          }else{
            this.disableField.transmission = false
            this.vehicleFuelName = []
            this.disableField.fuelType = true
            this.fuelTypeControl.setValue('')
          }
        }
      }
      
    }else{
      this.vehicleTransmissionControl.setValue('')
      this.fuelTypeControl.setValue('')
      this.mileageControl.setValue('')
      this.disableField.transmission = true
      this.disableField.fuelType = true
      this.disableField.mileage = true
    }
    this.cdRef.detectChanges(); 
  }
  transmissionChanged(data: string){
    if(data){
      this.vehicleFuelName = []
      this.vehicleTransmissionControl.setValue(data)
      this.disableField.fuelType = false
      this.mileageControl.setValue('')
      let engine = this.vehicleSingleData?.filter((item: {
        name: string; transmission_type: string; 
}) => item.transmission_type == data && item.name == this.vehicleEngineControl.value.name)
      if(engine.length == 1){
        this.vehicleFuelName.push(engine[0].fuel_type)
        this.fuelTypeControl.setValue(engine[0].fuel_type)
      }else{
        engine.forEach((item: any) => {
          if(!this.vehicleFuelName.includes(item.fuel_type)){
            this.vehicleFuelName.push(item.fuel_type)
          }
        })
      }
      this.disableField.fuelType = false
    }else{
      this.vehicleTransmissionControl.setValue('')
      this.fuelTypeControl.setValue('')
      this.mileageControl.setValue('')
      this.disableField.fuelType = true
      this.disableField.mileage = true
    }
    this.cdRef.detectChanges();
  }
  fuelTypeChanged(data:string){
    if(data){
      this.fuelTypeControl.setValue(data)
      this.disableField.mileage = false
      this.mileageControl.setValue('')
    }else{
      this.fuelTypeControl.setValue('')
      this.mileageControl.setValue('')
      this.disableField.mileage = true
    }
    this.cdRef.detectChanges();
  }
  
  nextStepper() {
    if(this.orderServiceVehicle){
      this.sharedService.triggerPopup('vehicleChanged')
    } else {
      this.isDataLoading = true;
      this.sharedService.updateShowingAddService(true)
      if (this.isFromProfile) {
        this.saveVehcile()
      } else {
        let vechileDetails = {
          model_year: this.vehicleYearControl.value,
          vechile_make: this.vehicleBrandControl?.value?.id,
          vechile_make_name: this.vehicleBrandControl?.value?.make,
          vechile_model: this.vehicleModelControl?.value?.id,
          vechile_model_name: this.vehicleModelControl?.value?.name,
          vechile_engine: this.vehicleEngineControl?.value?.id,
          vechile_engine_name: this.vehicleEngineControl?.value?.name,
          transmission_type: this.vehicleTransmissionControl?.value,
          fuel_type : this.fuelTypeControl?.value,
          current_mileage: this.mileageControl?.value,
          generation: this.vehicleModelControl?.value?.generation_id,
          series: this.vehicleModelControl?.value?.series_id,
          img: this.vehicleBrandControl?.value?.logo,
          car_plate: this.carPlateControl?.value,
          chasis_no: this.chasisNoControl?.value
        }
        if (this.userDetails != undefined) {
          this.setVehciledata(vechileDetails)
          this.saveVehcile()
        } else {
          this.sharedService.updateData('3');
          this.setVehciledata(vechileDetails)
          this.passDataService.updateVehicleData('')
          this.isDataLoading = false
          this.route.navigate(['/service/estimates'])
        }
      }
    }
      }

  previousStep() {
    if (!this.vehicleData && (this.backPressCount == 1)) {
      this.vehicleData = true
    } else {
      this.sharedService.updateData('1');
      this.route.navigate(['/service/services'])
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.sharedService.updateData('2'); }, 500)
  }
  onInputMileageChange(value: string) {
    var reg = /(0*[1-9]+0*)+/;
    if (value.length != 0) {
      if (!value.match(reg)) {
        this.translate.get("ERROR_MESSAGE.error_vechicle's mileage").subscribe((data) => {
        })
      } else {
        if (value != '') {
        }
      }
    }
    this.btnValidate()
  }
  btnValidate() {
    let mileage_data = this.mileageControl.value.replace(/\D/g, '')
    if (this.vehicleYearControl.value != null && this.vehicleYearControl.value != '' 
    && this.vehicleBrandControl.value != null && this.vehicleBrandControl.value != '' 
    && this.vehicleModelControl.value != null && this.vehicleModelControl.value != '' 
    && this.vehicleEngineControl.value != null && this.vehicleEngineControl.value != '' 
    && this.vehicleTransmissionControl.value != null && this.vehicleTransmissionControl.value != ''
    && this.fuelTypeControl.value != null && this.fuelTypeControl.value != ''
    && this.mileageControl.value != null && this.mileageControl.value != '' && mileage_data !=0) {
      this.btnDisabled = true
    } else {
      this.btnDisabled = false
    }
  }

  numberOnly(event: any): void {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/\D/g, ''); 
  }

  fetchVechicleYear() {
    this.bookService.getCarYearList().subscribe(
      (res) => {
        this.vehicleModelYears = res.data;
      }
    )
  }

  fetchVechicleMake(year: string) {
    this.bookService.getCarMakeList(year).subscribe(
      (res) => {
        this.vehicleBrand = res.data
        this.vehicleBrandName = res.data?.map((item: any) => item.make)?.sort();
      }
    )
  }

  fetchVechicleModel(year: string, make_id: string) {
    this.bookService.getCarModelList(year, make_id).subscribe(
      (res) => {
        this.vehicleModel =res.data.model
        this.vehicleModelName = this.vehicleModel?.map((item: any) => item.name)?.sort();
      }
    )
  }

  fetchVechicleTrim(year: string, make_id: string, model_id: string, seriesName: string) {
    this.bookService.getCarTrimList(year, make_id, model_id, seriesName).subscribe({
      next: (res) => {
        this.vehicleSingleData = res.data
        this.vehicleEngine = res.data.filter((value: { name: any; }, index: any, self: any[]) =>
          index === self.findIndex((t) => (
            t.name === value.name
          ))
        );
        this.vehicleEngineName = []
        this.vehicleTransmissionName = []
        this.vehicleFuelName = []
      
        this.vehicleEngineName = this.vehicleEngine.map((item) => item.name)
     
      },
      error: (err) => {
        console.log(err)
       
      }
    })
  }

  onClickAddVehcile(value: any) {
    this.vehicleData = false
  }

  setVehciledata(vechileDetails: any) {
    sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(vechileDetails))
    this.vechicleList = sessionStorage.getItem(Constants.APP.SESSION_VECHICLE_LIST)
    let vechicleData = [];
    
    if (this.vechicleList != undefined && this.vechicleList != null && this.vechicleList != 'undefined') {
      vechicleData = JSON.parse(this.vechicleList)
      if(this.userDetails!=undefined){
        vechicleData.push(vechileDetails)
        sessionStorage.setItem(Constants.APP.SESSION_VECHICLE_LIST, JSON.stringify(vechicleData))
      }else{
        let vehicleExist = vechicleData.some((item:any)=> item.vechile_engine == vechileDetails.vechile_engine && item.vechile_make == vechileDetails.vechile_make && item.vechile_model == vechileDetails.vechile_model)
        if(!vehicleExist){
          vechicleData.push(vechileDetails)
        }
        sessionStorage.setItem(Constants.APP.SESSION_VECHICLE_LIST, JSON.stringify(vechicleData))
      }
    } else {
      vechicleData.push(vechileDetails)
      sessionStorage.setItem(Constants.APP.SESSION_VECHICLE_LIST, JSON.stringify(vechicleData))
    }
  }

  saveVehcile() {
      this.isDataLoading = true;
        let payload = {
          "user_id": this.userDetails.user_id,
          "make_id": this.vehicleBrandControl?.value?.id,
          "model_id": this.vehicleModelControl?.value?.id,
          "series_id": this.vehicleModelControl?.value?.series_id || this.seriesid,
          "generation_id": this.vehicleModelControl?.value?.generation_id || this.generationid,
          "trim_id": this.vehicleEngineControl?.value?.id,
          "year": this.vehicleYearControl?.value,
          "transmission_type": this.vehicleTransmissionControl?.value,
          "fuel_type" : this.fuelTypeControl?.value,
          "mileage": Number(this.mileageControl?.value),
          "car_plate": this.carPlateControl?.value,
          "chasis_no": this.chasisNoControl?.value,
          "vechicle_id": this.vehicleid,
        }
      
      this.bookService.addVehicleByUser(payload).subscribe({
        error: (error) => {
          if (this.isFromProfile) {
            this.sendMsgToProfile.emit(false)
          }
          this.isDataLoading = false;
        },
        next: (res) => {
          if (this.isFromProfile) {
            this.sendMsgToProfile.emit(true)
            this.edititem.dataItem.mileage=this.mileageControl.value
            this.close.close()
            this.utils.openSuccessSnackBar(this.translate.instant("COMMON.KILOMETER_UPDATE_SUCCESS"))
          
          } else {
            if (res.statusCode == 1) {
              let vehicleData = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE)
              if (vehicleData != null) {
                let vehicleDataDetails = JSON.parse(vehicleData)
                vehicleDataDetails.vechicle_id = res.vechicle_id
                sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(vehicleDataDetails))
                this.sharedService.updateData('3');
                this.passDataService.updateVehicleData('')
                this.route.navigate(['/service/estimates'])
              }
              
            }
          }
          this.isDataLoading = false;
        },
      });
    }

    fetchVehicleByUser() {
      this.bookService.fetchVehicleByUser(this.userDetails.user_id).subscribe({
        error: (error) => {
          this.existvehicleData = false
          this.vehicleData = false
          this.backPressCount = 1
          this.sharedService.updateShowingAddService(false)
        }, // errorHandler 
        next: (res) => {
          if(!res.vechicle){
            this.existvehicleData = false
            this.vehicleData = false
            this.sharedService.updateShowingAddService(false)
          }
        }, // nextHandler
      });
    }
    userLogin(){
      this.route.navigate(['/auth'], { state: { isFromVechile: true } })
    }
   
    ngOnDestroy(): void {
      this.isAlive = false
    }
  }
