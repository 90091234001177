import { MapsAPILoader } from '@agm/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-location-direction',
  templateUrl: './location-direction.component.html',
  styleUrls: ['./location-direction.component.scss']
})
export class LocationDirectionComponent implements OnInit {

  constructor(private mapsAPILoader: MapsAPILoader,
    public dialogRef: MatDialogRef<LocationDirectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  zoom: number = 14;
  lat: number = 41.015137;
  lng: number = 28.979530;
  public origin: any;
  public destination: any;
  ngOnInit(): void {
    this.getDirection();
  }

  getDirection() {
   
    let destLat = this.data.dataItem.latitude;
    let destLng = this.data.dataItem.longitude
    let address = localStorage.getItem('address') || ''
    let destination = this.data.dataItem.address1 + " "+this.data.dataItem.address2 + " "+this.data.dataItem.city+ " "+this.data.dataItem.state+ " "+this.data.dataItem.country+ " "+this.data.dataItem.zip_code
    this.origin = address;
    this.destination = destination
  }
}
