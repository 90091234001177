 <div style="max-height: 100vh !important;">
  <nav class="navbar" [@moveUp]="moveState" style="display: block;">
    <div class="is-flex is-justify-content-space-evenly" style="width: 100%;">
      <div class="navbar-brand is-pa-l-10">
        <img class="is-clickable" src="assets/img/arabama.png" width="150" height="40" routerLink="/home"/>
      </div>
      <div class="stepper-nav">
        <!-- <div class="container"> -->
          <div class="navbar-item desktop-stepper">
            <app-stepper style="min-width:100%;" [activeStepper]="stepperCount"></app-stepper>
            <!-- <app-stepper class="is-hidden-desktop is-hidden-tablet" [activeStepper]="stepperCount"></app-stepper> -->
          </div>
        <!-- </div> --> 
      </div>
      <div class="navbar-end is-flex is-align-items-center">
        <a class="navbar-item is-flex is-align-items-center pr-0">
              <img src="../../../assets/svg/help.svg" alt="" style="width: 30px; height: 30px;" (click)="help()">
        </a>
        <a class="navbar-item mr-6">
          <div class="close-img is-flex is-justify-content-center is-align-items-center" (click)="onClickClose()">
              <i class="fas fa-close" style="font-size: 14px;"></i>
          </div>
        </a>
      </div>
    </div>
    <div class="mobile-stepper">
      <app-stepper style="min-width:100%;" [activeStepper]="stepperCount"></app-stepper>
      <!-- <app-stepper class="is-hidden-desktop is-hidden-tablet" [activeStepper]="stepperCount"></app-stepper> -->
    </div>
  </nav>
  
<div *ngIf="!FromLocation" class="" [ngClass]="{'services-estimates': isEstimateRoute, 'container-wrap': !isEstimateRoute, 'scrolling-estimate': hideHeader && stepperCount == 3, 'non-scrolling-estimate': !(hideHeader && stepperCount == 3)}" [@moveUp]="moveState">
  <div *ngIf="isVisibleBackButton" class="columns is-multiline m-0 sec-title-wrapper pt-2">
    <div class="column is-8 px-0 py-0 is-flex-tablet is-justify-content-space-between is-align-items-center">
      <div class="is-flex is-align-items-center">
        <button class="button back_button" (click)="onBackPressed()" [ngClass]="(stepperCount === 1 && pageRoute === '1.1') || reschedule || deactivateBtn ? 'disable': ''">
          <span class="icon is-small is-left" style="height: 60px; color: #fff;">
            <i class="fas fa-arrow-left"></i>
          </span>
        </button>
        <button class="button back_button ml-2" [ngClass]="(stepperCount === 1 && serviceList?.length === 0 && !inComments )|| (stepperCount === 1 && pageRoute !== '1.1' && !inComments) || (stepperCount === 2 && !vehicleDetails) || (stepperCount === 2 && !hideForward) || (stepperCount === 3 && !estimateData) || (stepperCount === 4) || (stepperCount === 5)? 'disable': ''" (click)="onForwardPressed()">
          <span class="icon is-small is-left" style="height: 60px; color: #fff;">
            <i class="fas fa-arrow-right"></i>
          </span>
        </button>
        <div class="has-text-weight-bold ml-4 is-size-6-mobile is-flex is-justify-content-space-between	is-align-items-center w-100" style="font-size: 1.3rem;">
          <span class="pt-1"> {{ backText }} </span> 
          <img class="cart-img is-hidden-tablet ml-3 is-clickable" (click)="showQuoteDetail()" src="../../../assets/svg/cart_new.svg" alt="">
        </div>
      </div>
      <div class="is-flex is-align-items-center  is-justify-content-center">
        <span  *ngIf="stepperCount == 2 && !userData"class="has-text-weight-bold text-size-14 pt-1">
          {{'COMMON.IF_YOU_ARE_A_MEMBER' | translate}}&nbsp; <span class="is-clickable" style="color: #aad043; text-decoration: underline;" (click)="userLogin()"> {{'COMMON.LOGIN_ROUTE' | translate}}</span>
        </span>
      </div>
    </div>
    <!-- <div *ngIf="!isBackButton && isShowingSavingText && !userData" class="column is-4-desktop is-12-mobile cancel-estimate-btn is-flex is-align-items-end is-justify-content-end py-0">
      <button class="button btn_save_estimate mr-2 is-success" (click)="saveEstimate('')" >
        <span class="icon">
          <img src="assets/svg/save_estimate.svg" />
        </span>
        <span class="pl-2" style="color: white;font-weight: 500;"> {{'COMMON.SAVE_ESTIMATE' | translate}}</span>
        <span *ngIf="!isShowingSavingText" class="pl-2" style="color: white;font-weight: 500;"> {{'COMMON.UPDATE_ESTIMATE' | translate}}</span> -->
      <!-- </button> -->
      <!-- <button class="button btn_cancel mt-2" (click)="cancelRequest()"><span style="color: #aad043;font-weight: 500;">{{'COMMON.CANCEL_REQUEST' | translate}}</span></button> -->
    <!-- </div> -->
  </div>
  <div class="map-content" *ngIf="isShowingMap">
    <app-map-view  #mapLoad  (sendViewDetails)="passMapView()"></app-map-view>
  </div>
  <div class="columns m-0 mt-2">
    <div #leftColumn class="column pt-0 left-column" [ngClass]="isEstimateRoute? 'is-9' : 'is-8'" (scroll)="onScroll($event)">
      <router-outlet (activate)="onActivate($event)" (deactivate)="onDeactivate()"></router-outlet>
    </div>
    <div #marTopMobile class="column py-0 pr-0 mar-top-mobile is-hidden-mobile" [ngClass]="isEstimateRoute? 'is-3' : 'is-4'" style="height: 100%;">
      <app-popular-services-right-nav-list></app-popular-services-right-nav-list>
    </div>
  </div>
</div>
 </div> 

<div *ngIf="show_popup" class="modal fade is-active">
<div class="modal-card">
  <div class="modal-card-body py-5 is-flex is-justify-content-center is-align-items-center">
    <div>
      <div class="has-text-centered pb-4 has-text-weight-bold">
        {{'DASHBOARD.WOULD_YOU_LIKE_TO_SAVE_THE_ESTIMATION' | translate}}
      </div>
      <div class="is-flex is-justify-content-center">
        <div class="button btn_cancel has-text-weight-semibold m-2" (click)="cancelRequest()">
          {{'DASHBOARD.CANCEL' | translate}}
        </div>
        <div class="button btn_save_estimate has-text-weight-semibold m-2" (click)="saveEstimate('manual save estimation')">
          {{'DASHBOARD.SAVE' | translate}}
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
<div *ngIf="showEditPopup" class="modal fade is-active alteration-modal">
  <div class="modal-card alteration-card">
    <div class="modal-card-body py-5 is-flex is-justify-content-center is-align-items-center">
      <div>
        <div class="has-text-centered pb-4 has-text-weight-bold px-3">
          {{'COMMON.ANY_ALTERATION_TEXT' | translate}}
        </div>
        <div class="is-flex is-justify-content-center">
          <div class="button btn_cancel has-text-weight-semibold m-2" (click)="showEditPopup = false">
            {{'DASHBOARD.CANCEL' | translate}}
          </div>
          <div class="button btn_save_estimate has-text-weight-semibold m-2" (click)="continueEdit()">
            {{'COMMON.CONTINUE' | translate}}
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>

<div class="cart-popup" *ngIf="showCartPopup">
  <app-job-details [stepperCount]="stepperCount" (closePopup)="showCartPopup = false"></app-job-details>
</div>

<div *ngIf="showChatWithGaragePopup" class="modal fade is-active">
  <div class="modal-card">
      <div class="modal-card-body py-5 is-flex is-justify-content-center is-align-items-center">
        <div>
          <div class="has-text-centered pb-4 has-text-weight-bold">
            {{"DASHBOARD.WOULD_YOU_LIKE_TO_CHAT_WITH_GARAGE" | translate}} <br> {{"DASHBOARD.PLEASE_SAVE_THE_ESTIMATION" | translate}}
          </div>
          <div class="has-text-centered">
            <div class="button btn_cancel has-text-weight-semibold m-2" (click)="showChatWithGaragePopup = false">
              {{'DASHBOARD.CANCEL' | translate}}
            </div>
            <div class="button btn_save_estimate has-text-weight-semibold m-2" (click)="saveEstimatePopup()">
              {{'DASHBOARD.SAVE' | translate}}
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
<!-- messagePopup -->
<app-message *ngIf="showMessagePopup" [estimation]="messagePopupData.estimation" [quoteId]="messagePopupData.quoteId" (hideMessage)="hideMessagePopup($event)"></app-message>
<!-- vehicle changed popup  -->
<div
*ngIf="showVehicleChangedPopup"
class="modal fade is-active"
>
<div class="modal-card">
  <div class="modal-card-body py-5 is-flex is-justify-content-center is-align-items-center">
    <div>
      <div class="has-text-centered pb-4 has-text-weight-bold">
        {{ "DASHBOARD.YOU_CHANGED_THE_VEHICLE" | translate }}
      </div> 
      <div class="is-flex is-justify-content-space-evenly">
        <div
          class="button btn_save_estimate has-text-weight-semibold"
          (click)="nextStepper()"
        >
          {{ "DASHBOARD.OK" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
</div>

<div *ngIf="showSaveServicePopup" class="modal fade is-active">
  <div class="modal-card">
      <div class="modal-card-body py-5 is-flex is is-justify-content-center is-align-items-center">
        <div>
          <div class="has-text-centered pb-3 has-text-weight-bold">
            {{'DASHBOARD.WOULD_YOU_LIKE_TO_SAVE_SELECTED_SERVICE' | translate}}
          </div>
          <div class="is-flex is-justify-content-center">         
            <div class="button btn_cancel has-text-weight-semibold m-2" (click)="skipService()">
                {{'DASHBOARD.NO' | translate}}
            </div>
            <div class="button btn_save_estimate has-text-weight-semibold m-2" (click)="addAnotherService()">
                {{'COMMON.YES' | translate}}
            </div>
          </div>
        </div>
      </div>
  </div>
</div>


