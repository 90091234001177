
<div class="content-inner">
  <div class="header-content">
    <h4 class="has-text-weight-bold pt-1 is-hidden"> {{'SERVICE.TEXT_20' | translate}}
      <img class="is-hidden" (click)="previousStep()" src="assets/svg/arrow-left-circle.svg" style="float: right;" />
    </h4>
  <div>
      <div class="is-flex is-justify-content-space-between show-map-wrapper pb-1">
          <div  *ngIf="vechicleEstimateDetails.length !== 0" class="map-btn is-flex is-justify-content-space-around">
              <button  id="btnShowMap" (click)="onClickShowMap()" class="button button-common has-text-weight-semibold">{{ showMapText }}  <img class="location-icon" src="assets/svg/show-map.svg" /></button>
              <div *ngIf="vechicleEstimateDetails?.length > 1" style="width: 160px;" class="is-hidden-tablet">
                <app-dropdown [option_list]="[{name: 'COMMON.RATINGS', id: 'ratings'},{name:'COMMON.DISTANCE', id:'distance' },{ name:'COMMON.PRICE', id: 'price' }]" [selected_value]="'COMMON.RATINGS'" [alignmentEnd]="true"
                (option_changed)="serviceChanged($event)"></app-dropdown>
              </div>
          </div>
          <div *ngIf="vechicleEstimateDetails?.length > 1" class="buttons is-flex is-justify-content-end is-hidden-mobile">
              <div id="btnRating" class="filter-link is-clickable px-2 text-size-14 is-success" (click)="ratingSort()">{{'COMMON.RATINGS' |
                  translate}}</div>
              <div id="btnDistance" class="filter-link is-clickable px-2 text-size-14" (click)="distanceSort()" >{{'COMMON.DISTANCE' | translate}}</div>
              <div id="btnPrice" class="filter-link is-clickable px-2 text-size-14" (click)="priceSort()" >{{'COMMON.PRICE' | translate}}</div>
          </div>
      
      </div>
  </div>
  <div *ngIf="vechicleEstimateDetails.length !== 0 && !isMobile" class="columns m-0">
    <div class="column is-narrow p-0 is-flex is-align-items-center">
      <div class="pt-1 has-text-weight-bold is-flex is-align-items-center text-size-14"><p class="garage_count pt-1 mr-2 mb-1">{{vechicleEstimateDetails.length}}</p> {{displayMatches}}</div>

    </div>
    <div class="column is-fluid pr-0 py-0 pl-2">
      <div class="px-1 pt-1">
        <p class="text-size-14">{{'DASHBOARD.APPOINTMENT_TEXT_1' | translate}}</p>
        <p class="text-size-14">{{'DASHBOARD.APPOINTMENT_TEXT_1.1' | translate}}</p>
      </div>

    </div>
  </div>
  <div *ngIf="vechicleEstimateDetails.length !== 0 && isMobile">
    <div class="pt-1 has-text-weight-bold px-1 is-flex is-align-items-center text-size-14"><p class="garage_count mr-2">{{vechicleEstimateDetails.length}}</p> {{displayMatches}}</div>
  <div class="is-flex px-1 text-size-14">
   <p>{{'DASHBOARD.APPOINTMENT_TEXT_1' | translate}}<span *ngIf="!showMore" class="more" (click)="showMore = true">
    {{'DASHBOARD.MORE' | translate}}</span><span *ngIf="showMore"> {{'DASHBOARD.APPOINTMENT_TEXT_1.1' | translate}}</span>
  <span *ngIf="showMore" class="more" (click)="showMore = false">
    {{'DASHBOARD.LESS' | translate}}
  </span>
  </p>
  </div>
  </div>
  </div>
    <div class="">
        <div *ngIf="isDataLoading" class="spinner-wrapper" style="height: 300px;">
            <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="columns is-multiline my-0 ml-0" >
            <div class="column pl-0 pr-3 pt-2 pb-1" *ngFor="let item of vechicleEstimateDetails" [ngClass]="vechicleEstimateDetails.length <=2 ? 'is-10-desktop is-12-tablet' : 'is-6-desktop is-12-tablet'">
                <app-estimate-line-item [quoteId]="quoteId" [estimate_item]="item" [quotation]="quotation"></app-estimate-line-item>
            </div>
        </div>
    </div>
</div>
<div *ngIf="vechicleEstimateDetails.length == 0 && userId && !isDataLoading" class="p-5">
  <p class="text-size-20 has-text-weight-semibold color-arabama-green">{{ "COMMON.THANKYOU_FOR_REACHING_OUT_ARABAMA_TEXT_2" | translate }} <br><p class="text-size-16 pt-3 no-estimate-title">{{ "COMMON.THANKYOU_FOR_REACHING_OUT_ARABAMA_TEXT_1" | translate }}</p></p>
  <p class=" text-size-16 pt-3 no-estimate-title">{{ "COMMON.THANKYOU_TEXT_2" | translate }}</p>
  <div class="pt-5">
    <button class="button is-success has-text-weight-semibold continue-btn is-flex is-justify-content-center" routerLink="/dashboard">{{ "COMMON.GO_TO_DASHBOARD" | translate }}
    </button>  
  </div>
</div>
<div *ngIf="vechicleEstimateDetails.length == 0 && !userId && !isDataLoading" class="px-5 py-2">
    <p class="text-size-20 has-text-weight-semibold color-arabama-green">{{ "COMMON.THANKYOU_FOR_REACHING_OUT_ARABAMA_TEXT_2" | translate }}</p>
    <p class="text-size-16 pt-3 no-estimate-title pb-3">{{ "COMMON.THANKYOU_TEXT_1" | translate }}</p>
  </div>
  <div *ngIf="vechicleEstimateDetails.length == 0 && !userId && !isDataLoading" [ngClass]="isMobile? 'has-text-centered' : 'is-flex is-justify-content-space-between is-align-items-center'" class="px-5 py-2 ">
  <button class="button is-success has-text-weight-semibold continue-btn" (click)="signup()">{{'SERVICE.TEXT_30' | translate}}</button>

  <div class="pt-1">
    <span class="route-link has-text-weight-bold has-text-centered"> {{ 'COMMON.SIGNUP'
      | translate }} <span routerLink="/auth" class="login-route" (click)="saveEstimate()">{{ 'COMMON.LOGIN_ROUTE'
        | translate }}</span> </span>
  </div>
</div>