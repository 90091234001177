import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-upload-profile-image',
  templateUrl: './upload-profile-image.component.html',
  styleUrls: ['./upload-profile-image.component.scss']
})
export class UploadProfileImageComponent implements OnInit {

  constructor() { }

  upload: boolean = false;
  view_profile: boolean = false;
  view: boolean = false;
  selectedImage: any;
  imgname: any;
  upload_method: any;
  imageSrc: any;

  @Input() profile_img : any;
  @Output() upload_profile_img = new EventEmitter<any>();


  ngOnChanges(changes: SimpleChanges): void {
    this.profile_img = changes['profile_img']?.currentValue;
  }

  ngOnInit(): void {
  }


  viewProfile() {
    this.view_profile = true;
  }

  hideProfile() {
    this.view_profile = false;
  }

  onImageSelected(event: any) {
    this.selectedImage = event.target.files[0];
    this.imgname = event.target.files[0].name;
    const reader = new FileReader();
      reader.onload = (e: any) => {
        this.profile_img = e.target.result;
      };
      reader.readAsDataURL(this.selectedImage);
    this.upload_profile_img.emit({profile_image : this.selectedImage})
 
  }

  uploadGarageImage() {
    this.upload = true;
    this.view_profile = false;
  }
}
