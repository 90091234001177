import { Component, ElementRef, OnInit,OnDestroy, ViewChild,NgZone, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {FormControl} from '@angular/forms';
import { map, Observable, share, startWith, take, takeWhile, timeout} from 'rxjs';
import { Router} from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { Utils } from 'src/app/common/utils';
import { PassDataService } from 'src/app/services/pass-data.service';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { Constants } from 'src/app/Constants/constants';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit,OnDestroy {

  pincodeControl = new FormControl()

  pincodeOptions: any = new Observable<String[]>();

  @Input() FromLocation: boolean = false;
  @Output() locationInputChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('search')
  searchAddress: ElementRef | undefined;

  //current location
  private geoCoder: any;
  latitude: number = 0.0;
  longitude: number = 0.0;
  pincode: string = '';
  address: string = '';
  searchProvince: string = '';
  alive: boolean = true
  pincodefil:any[]=[]
  tempProvinceList : any = []
  isFocusClickProvince: boolean = false;
  outsideClickProvince: boolean = false;
  isShowDropDownProvince: boolean = false;
  isFocusOutProvince: boolean = false
  provinceList:any = new Observable<any[]>();
  selectedProvince: any;


  constructor(private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private route: Router,
              private utils: Utils,
              private translate: TranslateService,
              private passDataService: PassDataService,
              private bookservice: BookingServiceService,private sharedServices: SharedServiceService) { }

  ngOnInit(): void {
    this.provinceList = this.pincodeControl.valueChanges.pipe(startWith(''), map(value => this._pincodeFilter(value)),);
    this.fetchServicesAndZipCode();
   
    this.hideProvinceDropDown();

    

    this.mapsAPILoader.load().then(()=>{
      this.geoCoder = new google.maps.Geocoder;
      var southWest = new google.maps.LatLng(40.811404,28.595554);
      var northEast = new google.maps.LatLng(41.199239,29.428805);
      var istanbulBounds = new google.maps.LatLngBounds(southWest, northEast);
     
      var options = {      
        strictBounds: true,
        bounds: istanbulBounds,
        componentRestrictions: { country: "TR"},
        types: ['administrative_area_level_4']
      };
      let autoComplete = new google.maps.places.Autocomplete(this.searchAddress?.nativeElement,options);
      autoComplete.addListener("place_changed",() =>{
        this.ngZone.run(()=>{
          let place: google.maps.places.PlaceResult = autoComplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          if(place.address_components != undefined){
            let addressDetails = place.address_components;
            if(addressDetails.length > 0){
              addressDetails.forEach(element => {
                if(element.types[0].endsWith('postal_code')){
                  this.pincode = element.long_name;
                }
                if(element.types[0].endsWith('administrative_area_level_4')){
                  this.address = element.long_name
                }
                if(element.types[0].endsWith('administrative_area_level_2')){
                  this.address =this.address +" "+ element.long_name
                }
                if(element.types[0].endsWith('administrative_area_level_1')){
                  this.address =this.address+' - '+element.long_name
                }
                localStorage.setItem("address",this.address);
              }); 
              if(this.FromLocation){
                if(this.address.length > 0){
                  this.route.navigate(['/service'])
                  return
                }else{
                  this.utils.openErrorSnackBar(this.translate.instant('COMMON.SERVICE_UNAVAILABLE_FOR_THIS_LOCATION'))
                  return
                } 
              }
            }
          }
        })
      });
    })

    
    this.passDataService.getCurrentLocation().pipe(takeWhile(() => this.alive)).subscribe((position:any) => { 
      if(position != '' && position != undefined){
        if(position.coords){
          this.geoCoder = new google.maps.Geocoder;
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.getAddress(position.coords.latitude,position.coords.longitude)
        }
      }
    }) 
  }
  
 
  onFocusProvince() :void{
    this.isFocusOutProvince = false;
    this.isFocusClickProvince = true
    this.isShowDropDownProvince = false
    
  }
  onFocusOutProvince(){
    this.isFocusOutProvince = true
    this.hideProvinceDropDown()
        this.isShowDropDownProvince = false
        this.isFocusClickProvince = false
  }

  hideProvinceDropDown(){
    document.getElementById('btnProvince')?.classList.add('display_drop_down_province');
    document.getElementById('dropDownProvince')?.classList.add('show-dropdown-province')
      
  }

  showProvinceDropDown(){
   
    document.getElementById('btnProvince')?.classList.remove('display_drop_down_province');
    document.getElementById('dropDownProvince')?.classList.remove('show-dropdown-province')
    this.isShowDropDownProvince = true
    setTimeout( ()=>{
      this.isFocusClickProvince = false
    },500)
  }

  onInputProvinceChange(value:any){
    this.pincodefil = this.provinceList 
     value = value == null ? '':value
     if(typeof value === 'object' && value != undefined){
       value = Object.keys(value).length != 0 ? value.name : '';
     }
     let placeFilter = this.pincodefil.filter((option: any) => {
      const searchTerm = value != undefined ? value.trim().toLowerCase() : '';
      const placeLowerCase = option.place.toLowerCase();
      if (placeLowerCase.startsWith(searchTerm)) {
        return true;
      }
      return false;
    });
    let provinceFilter = this.pincodefil.filter((option: any) => {
      const searchTerm = value != undefined ? value.trim().toLowerCase() : '';
      const provinceLowerCase = option.province.toLowerCase();
      if (provinceLowerCase.startsWith(searchTerm)) {
        if(placeFilter.some((item) => item.place.toLowerCase() === option.place.toLowerCase())){
          return false
        }
        return true;
      }
      return false;
    });
    this.tempProvinceList = [...placeFilter, ...provinceFilter]
    
     this.pincodefil = this.tempProvinceList
     if(this.tempProvinceList.length == 0){
       this.hideProvinceDropDown()
     }else{
       this.isShowDropDownProvince = true
       this.showProvinceDropDown()
      
     }
   }

   formatProvince(input: string): string {
    if (!this.searchProvince || !input) {
      return input;
    }
    const regEx = new RegExp(this.searchProvince, 'gi');
    return input.replace(regEx, '<strong>$&</strong>');
  }

  onClickProvince(item:any){
    this.selectedProvince = item.place+", "+item.province;
    this.pincodeControl.setValue(this.selectedProvince);
    this.isShowDropDownProvince = true
    this.outsideClickProvince = false
    this.isFocusClickProvince = false
    localStorage.setItem("address",this.selectedProvince);
    this.hideProvinceDropDown()
    if(localStorage.getItem("address")){
      this.route.navigate(['/service'])
      return
    }
  }

  private setCurrentLocation() {
      let _this = this;
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          _this.latitude = position.coords.latitude;
          _this.longitude = position.coords.longitude;
          _this.getAddress(position.coords.latitude,position.coords.longitude)
        },function(err){
        });
      }
  }
  clearLocation(){
    this.searchProvince = ''
    this.isShowDropDownProvince = false
    localStorage.removeItem('address')
  }
  getAddress(latitude : any, longitude : any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results : any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.pincodeControl.setValue([results[0].formatted_address])
          if(results[0].address_components != undefined){
            let addressDetails = results[0].address_components;
            if(addressDetails.length > 0){
              addressDetails.forEach((element: any) => {
                if(element.types[0].endsWith('postal_code')){
                  this.pincode = element.long_name;
                }
                if(element.types[0].endsWith('street_number')){
                  this.address = element.long_name
                }
                if(element.types[0].endsWith('route')){
                  this.address = this.address+","+element.long_name
                }
                if(element.types[0].endsWith('sublocality_level_2')){
                  this.address = this.address+","+element.long_name
                }
                if(element.types[0].endsWith('political')){
                  this.address = this.address+","+element.long_name
                }
                if(element.types[0].endsWith('locality')){
                  this.address = this.address+","+element.long_name
                }
               
              });
            }
          }
          localStorage.setItem("zipcode",this.pincode);
          localStorage.setItem("address",this.address);
          if(this.FromLocation){
            if(this.pincode.length > 0 && this.address.length > 0){
              this.ngZone.run(()=>{
                this.route.navigate(['/service'])
              })  
              return
            }else{
              this.utils.openErrorSnackBar(this.translate.instant('COMMON.SERVICE_UNAVAILABLE_FOR_THIS_LOCATION'))
              return
            } 
          }
        } 
      } 
    
    });
  }
  getGeoLocation(address: string): Observable<any> {
    let geocoder = new google.maps.Geocoder;
    return new Observable( ()=>{
      geocoder.geocode({
        'address': address
    }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
        } else {
        }
    });
    })
  }
  
   fetchServicesAndZipCode(){
    this.bookservice.getServicesAndPincode().subscribe({
      next: (res)=> {
          if(res.statusCode == 1){  
             this.pincodefil = res.zipcode
             this.provinceList = res.zipcode
          }
        },
      error: (err)=>{
      }
    })
  }
  private _pincodeFilter(value: any): string[] {
    value = value == null ? '' : value
    if(typeof value === 'object' && value != undefined){
      value = Object.keys(value).length != 0 ? value.place : '';
    }
    return this.pincodefil.filter(option => option.place.toLowerCase().includes(value.toLowerCase()));
  }

displaypincode(value:any){
     return value ? value.place+","+value.province+","+value.state :'';
  }
  onInputPinChange(value:any){
    if(value != ''){
      this.pincode = '';
      this.address=value.place+","+value.province+","+value.state;
      localStorage.setItem("address",this.address)
      this.pincode=localStorage.getItem('address') || '';
      if(this.FromLocation){
        if(this.pincode.length !=0 && !this.pincode.includes("undefined")){
          this.route.navigate(['/service'])
          return
        }
      }
      return
    }
   
  }
 

  ngOnDestroy(): void {
    this.alive = false
  }
}
