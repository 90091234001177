import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout/layout.component';
import { IndexComponent } from './pages/index/index.component';
import { ServiceRequestVehicleComponent } from './components/service-request-vehicle/service-request-vehicle.component';
import { ServiceRequestLayoutComponent } from './layout/service-request-layout/service-request-layout.component';
import { ServiceRequestEstimatesComponent } from './components/service-request-estimates/service-request-estimates.component';
import { ServiceRequestBookComponent } from './components/service-request-book/service-request-book.component';
import { ServiceRequestConfirmComponent } from './components/service-request-confirm/service-request-confirm.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ServicesListComponent } from './components/services-list/services-list.component';
import { LocationSelectComponent } from './components/location-select/location-select.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { NewLoginUiComponent } from './pages/new-login-ui/new-login-ui.component';
import { HelpComponent } from './pages/help/help.component';
import { ReviewsComponent } from './tabs/reviews/reviews.component';
import { AccountInfoComponent } from './tabs/account-info/account-info.component';
import { VehicleComponent } from './tabs/vehicle/vehicle.component';
import { ServiceHistoryComponent } from './tabs/service-history/service-history.component';
import { ViewQuoteComponent } from './components/view-quote/view-quote.component';
import { JobDetailsComponent } from './components/job-details/job-details.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { LegalPolicyComponent } from './pages/legal-policy/legal-policy.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '', pathMatch: 'full', redirectTo: 'home'
      },
      {
        path: 'home', component: IndexComponent
      },
      {
        path: 'location', component: LocationSelectComponent  
      },
      {
        path: 'view-quote', component: ViewQuoteComponent
      },
      {
        path: 'job-details', component: JobDetailsComponent
      },
      {
        path: 'service', component: ServiceRequestLayoutComponent,
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'services'},
          {
            path: 'services', component: ServicesListComponent
          },
          {
            path: 'vehicle', component: ServiceRequestVehicleComponent
          },
          {
            path: 'estimates', component: ServiceRequestEstimatesComponent
          },
          {
            path: 'book', component: ServiceRequestBookComponent
          },
          {
            path: 'confirm', component: ServiceRequestConfirmComponent
          }
      ]
      },
      {
        path: 'dashboard',component: DashboardComponent
      },
      {
        path: 'profile',component: ProfileComponent,
        children : [
          { path: '', pathMatch: 'full', redirectTo: 'account-info' },
          {
            path: 'account-info', component: AccountInfoComponent
          },
          {
            path: 'car-park', component: VehicleComponent
          },
          {
            path: 'reviews', component: ReviewsComponent
          },
          {
            path: 'service-history', component: ServiceHistoryComponent
          },
        ]
      },
      {
        path: 'reviews', component: ReviewsComponent
      }
    ]
  },
  {
    path: 'auth',
    component: NewLoginUiComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'help',
    component: HelpComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent
  },
  {
    path: 'legal-policy/:id',
    component: LegalPolicyComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
