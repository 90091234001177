import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import * as moment from 'moment';
import { PassDataService } from 'src/app/services/pass-data.service';
import { Constants } from 'src/app/Constants/constants';
import {MatDialog} from '@angular/material/dialog';
import { ViewDetailsComponent } from 'src/app/dialog/view-details/view-details.component';
import { LocationDirectionComponent } from 'src/app/dialog/location-direction/location-direction.component';
import { Utils } from 'src/app/common/utils';
import { SocketService } from 'src/app/services/socket.service';
import { ViewGarageComponent } from 'src/app/dialog/view-garage/view-garage.component';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-estimate-line-item',
  templateUrl: './estimate-line-item.component.html',
  styleUrls: ['./estimate-line-item.component.scss']
})
export class EstimateLineItemComponent implements OnInit {
  @Input() estimate_item: any;
  @Input() quotation: any;
  @Input() quoteId!: number;
  amenitiesLength: number = 2;
  defaultAmenitiesCount: number = 2;  
  specializationLength: number = 2;
  defaultSpecializationCount: number = 2;
  estimation_item: any = [];
  show_message : boolean = false; 
  show_popup: boolean = false; 
  from: any;
  total :any;
  discountedValue: any;
  discountedType :any;
  isMobile: boolean = false;
  
  // private subscription!: Subscription;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private sharedService: SharedServiceService,
              private route: Router, 
              private translate: TranslateService,
              private passDataService: PassDataService,
              public dialog: MatDialog,
              private router: ActivatedRoute,
              private utils:Utils,
              private socketService: SocketService) { }

  ngOnInit(): void {
    this.router.queryParams.subscribe(params => {
      this.from = params['from'];
    });
    if(this.utils.isMobile()){
      this.defaultAmenitiesCount = 2
      this.amenitiesLength = 2 
    }
    if(this.utils.isMobile()){
      this.defaultSpecializationCount = 2
      this.specializationLength = 2 
    }
    this.getOpeningHours()
    if(this.estimate_item.invoice_quote != null){
      this.estimation_item = this.estimate_item.invoice_quote.items
      this.estimate_item.quote.finalPrice = this.estimate_item.quote.pre_subtotal
      this.estimate_item.original_price = 0;
      this.estimate_item?.invoice_quote?.items?.forEach((element: any) => {     
        this.estimate_item.original_price += element?.base_price

        if(element.discount_value != null){
          this.discountedValue = element.discount_value
          this.discountedType = element.discount_type
        }
        else if(element.discount_value == null){
          this.discountedValue = this.estimate_item.invoice_quote.bestDiscountPrice
          this.discountedType = this.estimate_item.invoice_quote.bestDiscountType
        }
      })  
      this.total = this.estimate_item.original_price;   
      if(this.estimate_item.quote_data){
        if(this.estimate_item.quote_data?.my_dis_status == 1){
          this.total = this.total - this.discountedValue           
        }
        if(this.estimate_item.quote_data?.custom_dis_status == 1){
          this.total = this.total - this.estimate_item.quote_data.custom_dis_value            
        }
      }
      else{
        this.total = this.total - this.discountedValue           
      }
    
      this.estimate_item.original_price = this.estimate_item.original_price.toLocaleString('tr',{style: 'currency', currency: 'TRY'}).replace('₺', '₺ ')   
     this.total = this.total.toLocaleString('tr',{style: 'currency', currency: 'TRY'}).replace('₺', '₺ ')
     
    }
    this.getRating();  
    
    if(this.quotation){
      this.estimation_item.forEach((element:any) =>{
        this.quotation.items.forEach((element1: any)=> {
          if(element1.service_id == element.service_id){
            element.estimation_type = element1.estimation_type;
          }
        });
      });
    if(this.quotation.garage_id == this.estimate_item.id){
      this.openViewDetails();
    }    
  }
    this.setupSocketListeners()
    this.sharedService.popupResponse$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if(data === 'chatWithGarage'){
        this.saveEstimate()
      } else if (data = 'hideMessagePopup'){
        this.estimate_item.msg_notification_count = 0
      }
    })
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768;
    });
  }
  setupSocketListeners() {
    this.socketService
      .listen('chat')
      .subscribe((data) => {        
        if(this.quoteId === data.job_id && this.estimate_item.id === data.sender_id){
          this.estimate_item.msg_notification_count++          
        } 
      });
  }

  nextStepper(item:any) {
    this.sharedService.updateData('4');
    sessionStorage.setItem(Constants.APP.SESSION_ESTIMATE_DETAILS,JSON.stringify(item));
    this.sharedService.setEstimateData(item)
    this.route.navigate(['/service/book'])   
  }

  getOpeningHours(){
    const date = moment();
    this.estimate_item.timings.forEach((element:any) => {
      
       if(element.start_time && element.end_time){
          if(date.day() === element.day_of_week){
            //  var diff = moment.duration(moment(element.end_time,"HH:mm").diff(moment()));  
            //  this.estimate_item.openStatus = diff.asHours() > 0 ? true : false
            //  this.estimate_item.closeTime = moment(element.end_time,"hh A").format("HH:mm")

             const now = moment();
             const closingTimeMoment = moment(element.end_time, "HH:mm");
             const diff = moment.duration(closingTimeMoment.diff(now));
             // Determine open status
             this.estimate_item.openStatus = diff.asHours() > 0;
             // Format the opening and closing times
             this.estimate_item.openTime = moment(element.start_time, "HH:mm").format("HH:mm");
             this.estimate_item.closeTime = closingTimeMoment.format("HH:mm");
             
          }
       }
    });
  }
  getRating(){
    if(this.estimate_item.reviews != null){
      this.estimate_item.rating = {}
      let totalRating = 0;
      this.estimate_item.reviews.forEach((element:any) => {
         totalRating += element.ratings;
      });
      this.estimate_item.rating.cum_rating = totalRating / this.estimate_item.reviews.length
      this.estimate_item.rating.cum_rating = this.estimate_item.rating.cum_rating.toFixed(1);
      this.estimate_item.rating.totalRating = this.estimate_item.reviews.length
      if(this.estimate_item.rating.cum_rating == 'NaN')
      this.estimate_item.rating.cum_rating =0
    }
  }
  openGarageDetails(){
    let width = '75%'
    let heigth = 'auto'
    let maxWidth = '80vw'
    if(this.utils.isMobile()){
      width= '100%'
      heigth = 'auto'
      maxWidth = '100vw'
    }
    const viewGarage = this.dialog.open(ViewGarageComponent,{
      height: heigth,
      width: width,
      maxWidth:maxWidth,
      disableClose: true,
      data:{
        dataItem: this.estimate_item,
        quotation: this.quotation
      },
    })
    viewGarage.afterClosed().subscribe(result => {
    });
  }

  openViewDetails(){  
    this.estimate_item.quote_id = this.quoteId  
    this.route.navigate(['view-quote'], {state: { data: { dataItem: this.estimate_item, quotation: this.quotation}}, queryParams: {from: this.from}})
  }

  openViewDirection(){
    const viewDetails = this.dialog.open(LocationDirectionComponent,{
      height: '64%',
      width: '100%',
      data:{
        dataItem: this.estimate_item
      }
    })
    viewDetails.afterClosed().subscribe(result => {
    });
  }
  messagePopup(){
    if(this.quoteId){
      // this.show_message = !this.show_message
      this.sharedService.triggerPopup('messagePopup')
      let data =  {
        estimation: this.estimate_item,
        quoteId: this.quoteId
      }
      this.sharedService.setMessagePopupData(data)
    }else{
      this.sharedService.triggerPopup('chatWithGarage')
    }
  }
  saveEstimate(){
    this.route.navigate(['/auth'], { state: { isFromEstimateValue: true,message:true } })
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
} 
