import { Component, Input, OnInit,OnChanges, SimpleChanges, HostListener, ViewChild, Output, EventEmitter } from '@angular/core';
import { Constants } from 'src/app/Constants/constants';
import { USER } from 'src/app/services/booking-service.service';
import { Router } from '@angular/router';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { PassDataService } from 'src/app/services/pass-data.service';
import { takeWhile } from 'rxjs';
import { SocketService } from 'src/app/services/socket.service';
import { SideNotificationComponent } from '../side-notification/side-notification.component';
import { animate, state, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-10%)' }), // Start invisible and off-screen
        animate('0.7s ease-in-out', style({ opacity: 1, transform: 'translateY(0%)' })), // Slide in and become visible
      ]),
      transition(':leave', [
        animate('0.4s ease-in-out', style({ opacity: 0, transform: 'translateY(-10%)' })), // Slide out and become invisible
      ]),
    ])
  ],
})
export class UserHeaderComponent implements OnInit,OnChanges {

  constructor(private router: Router,
              private sharedServices: SharedServiceService,
              private passServices: PassDataService,
              private socketService: SocketService) { 
    this.user = {} as USER
  }
  user: USER;
  @ViewChild(SideNotificationComponent) sideNotificationComponent: SideNotificationComponent | undefined;
  @Input() userFirstName:any;
  @Input() userLastName:any;
  @Output() hideSearchField = new EventEmitter<any>();

  showBurgerMenu: boolean = false;
  showBurgerClick: boolean = false;
  alive: boolean = true;
  show_notification: boolean = false;
  notification_count: number = 0;
  searchField: boolean = false;
  isMobile: boolean = false;
  isSlideOutComplete: boolean = true;
  nameHover: boolean = false

  ngOnInit(): void {
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    if(userdata != undefined){
      let userDetails = JSON.parse(userdata)
      this.user.userId = userDetails.user_id || '0'
      this.user.firstName = userDetails.first_name
      this.user.lastName = userDetails.last_name
      this.user.email = userDetails.email
      this.user.mobile = userDetails.phone_no
    } 
    
    this.passServices.getOutsideClickListener().pipe(takeWhile(() => this.alive)).subscribe(() => { 
      if(this.showBurgerMenu && !this.showBurgerClick){
        this.showBurgerMenu = false;
      }   
      this.showBurgerClick = false; 
    });
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768;
    });

      this.sharedServices.updateProfile$.pipe(takeWhile(() => this.alive)).subscribe((data: any) => {
        if(data){
          this.updateProfile()
        }
    })  
  
  }
  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if(propName == 'userFirstName'){
        const change = changes[propName];
        const firstName  = change.currentValue;
        this.user.firstName = firstName
      }
      if(propName == 'userLastName'){
        const change = changes[propName];
        const lastName  = change.currentValue;
        this.user.lastName = lastName
      }
    }
  }
  
  clickProfile(){
    this.passServices.pageIndex = "profile"
    this.router.navigate(['/profile'])
  }

  serviceHistory(){
    this.passServices.pageIndex = "service_history";
    this.router.navigate(['/profile/service-history'])
  }

  carParking(){
    this.passServices.pageIndex = "car_parking";
    this.router.navigate(['/profile/car-park'])
  }

  help(){
    this.router.navigate(['/help'])
  }

  clickHammer(data:any){
    if(data){
      this.showBurgerClick = true;
    }
    this.showBurgerMenu = this.showBurgerMenu ? false : true
    
  }
  

  logout(){
    localStorage.removeItem(Constants.APP.SERVICE_LIST)
    localStorage.removeItem("address")
    localStorage.removeItem("zipcode")
    sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
    sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
    sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
    localStorage.removeItem(Constants.APP.LOCATION_ARRAY)
    localStorage.removeItem(Constants.APP.SESSION_ID)
    localStorage.removeItem(Constants.APP.SESSION_USER_DATA)
    sessionStorage.removeItem(Constants.APP.SESSION_VECHICLE_LIST)
    sessionStorage.removeItem(Constants.APP.QUOTE_ID)
    localStorage.removeItem(Constants.APP.APPLIED_GARAGES_ID)
    this.sharedServices.clearData()
    document.body.classList.remove('inner-stepper');
    this.router.navigate(['/home'])
    this.socketService.disconnect()
  }

  notification(type: string){
    this.show_notification = true
  }
  hideNotification(ev: boolean){    
    this.show_notification = ev;
  }
  reviews(){
    // this.passServices.pageIndex = "reviews";
    this.router.navigate(['/profile/reviews'])
  }
  @HostListener('document:click', ['$event'])
 
  
  onDocumentClick(event: MouseEvent) {
    const ClickedElement = event.target as HTMLElement
    const iconContainer = ClickedElement.closest('.icon-container');
    const notificationInside = ClickedElement.closest('.notify-content');
    const searchInside = ClickedElement.closest('.inner_nav');
    const searchOut = ClickedElement.closest('.searcharea');
    const searchn = ClickedElement.closest('.list-group-item');
    if (!iconContainer && !notificationInside) {
      this.show_notification =  false;
    } 
    if((!searchInside && !searchOut) && !searchn){
      this.searchField =  false;
      this.animationDone()
      this.hideSearchField.emit(this.searchField);
    }
  }
  openSearch(){
    this.searchField = true;
    this.isSlideOutComplete = false; 
    this.hideSearchField.emit(this.searchField);
  }

  animationDone() {
    setTimeout(() => {
      this.isSlideOutComplete = true; // Animation completes after delay
    }, 800);  }

    homePage(){
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/dashboard'])
      });
    }

    updateProfile(){
      let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
      if(userdata != undefined){
        let userDetails = JSON.parse(userdata)
        this.user.userId = userDetails.user_id || '0'
        this.user.firstName = userDetails.first_name
        this.user.lastName = userDetails.last_name
        this.user.email = userDetails.email
        this.user.mobile = userDetails.phone_no
      }
    }

    ngOnDestroy(): void {
      this.alive =false
    }
}
