import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Constants } from 'src/app/Constants/constants';
import { Utils } from 'src/app/common/utils';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-estimate-tab',
  templateUrl: './estimate-tab.component.html',
  styleUrls: ['./estimate-tab.component.scss']
})
export class EstimateTabComponent implements OnInit {

  @Input() estimate_item: any = {}
  @Input() dialog: any;
  @Input() quotation: any;
  @Input() fromMap: boolean = false;
  @Input() showImage: boolean = false;
  @Output() closeImage = new EventEmitter<void>();
  itemList: any = [];
  estimationList: any = [];
  currentStep: number = -1;
  currentId: any;
  servicesList: any = [];
  checked:boolean = true;
  total_price: number = 0;
  total_discount: number = 0;
  show_message : boolean = false; 
  show_popup: boolean = false; 
  quoteId: any;
  userDetails: any;
  total:any;
  discountedValue: any;
  discountedType :any;
  isQuoteDetailsVisible: boolean = true;
  itemDetailsVisible: boolean[] = [];
  amenitiesLength: number = 3;
  defaultAmenitiesCount: number = 3; 
  specializationLength: number = 3;
  defaultSpecializationCount: number = 3; 
  showDropdown = false;

  constructor(private sharedService: SharedServiceService,
    private route: Router, private utils:Utils) { }

  ngOnInit(): void { 
    if(this.utils.isMobile()){
      this.defaultAmenitiesCount = 2
      this.amenitiesLength = 2 
      this.defaultSpecializationCount = 2
      this.specializationLength = 2 
    }
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    if (userdata != undefined) {
      this.userDetails = JSON.parse(userdata)
    }    
    let TotalBaseValue = 0
    this.estimate_item.invoice_quote.items.forEach((price: any) => {
      TotalBaseValue += price.base_price
    })    
    if(this.estimate_item?.quote){
      this.estimationList = this.estimate_item
      this.itemList = this.estimate_item.invoice_quote.items
      if(this.itemList?.length > 0){
        this.itemList.forEach((element: any) => {
        
         
          if(element.discount_value != null){
            this.discountedValue = element.discount_value
            this.discountedType = element.discount_type
          }
          else if(element.discount_value == null){
            this.discountedValue = this.estimate_item.invoice_quote.bestDiscountPrice
            this.discountedType = this.estimate_item.invoice_quote.bestDiscountType
          }
          this.total_price = TotalBaseValue;   
          this.total_discount = 0
          if(this.estimate_item.quote_data){
            if(this.estimate_item.quote_data?.my_dis_status == 1){
              this.total_price = this.total_price - this.discountedValue   
              this.total_discount += this.discountedValue           
                        
            }
            if(this.estimate_item.quote_data?.custom_dis_status == 1){
              this.total_price = this.total_price - this.estimate_item.quote_data.custom_dis_value    
            if (typeof this.estimate_item.quote_data.custom_dis_value === 'string') {
                this.total_discount += Number(this.estimate_item.quote_data.custom_dis_value);
            } else {
                this.total_discount += this.estimate_item.quote_data.custom_dis_value;
            } 
            }
          
          }
          else{
            this.total_price = this.total_price - this.discountedValue   
            this.total_discount += this.discountedValue
          }
          this.total_price = this.total_price > 0 ? this.total_price : 0
          this.total = this.total_price .toLocaleString('tr',{style: 'currency', currency: 'TRY'}).replace('₺', '₺ ')

        
          if(element.service_comment?.length > 35){
            element.showMore = true
          }else{
            element.showMore = false
          }  
          this.getLocalServiceList(element.service_id,element)
        });
        
      }      
      if(this.estimate_item.quote_data.completed_price !== null && this.estimate_item.quote_data.completed_price !== '' && this.estimate_item.quote_data.completed_price !== 'null'){
        this.estimate_item.quote_data.completed_price = Number(this.estimate_item.quote_data?.completed_price).toLocaleString('tr',{style: 'currency', currency: 'TRY'}).replace('₺', '₺ ')
      }
      else{
        this.estimate_item.quote_data.completed_price = null
      }
    }
    this.itemDetailsVisible = new Array(this.itemList.length).fill(true); 
  }
  saveEstimate(){
    this.route.navigate(['/auth'], { state: { isFromEstimateValue: true,message:true } })  
  }
  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }
  getLocalServiceList(id:number,elementItem:any){
    var services = localStorage.getItem(Constants.APP.SERVICE_LIST);
    if(services!="" && services!='null' && services!=undefined){
      let localData = JSON.parse(services);
      localData.forEach((element: any) => {
        if(Number(id) === Number(element.answer.id)){
          elementItem.service_item = element.question.answer +'-'+ element.answer.answer
          this.servicesList.push(element)
        }
      });
     }
  }
  toggleQuoteDetails() {
    this.isQuoteDetailsVisible = !this.isQuoteDetailsVisible;
  }

  toggleItemDetails(index: number) {
    this.itemDetailsVisible[index] = !this.itemDetailsVisible[index];
  }
  nextStepper(item:any) {
    this.sharedService.updateData('4');
    sessionStorage.setItem(Constants.APP.SESSION_ESTIMATE_DETAILS,JSON.stringify(item));
    this.sharedService.setEstimateData(item)
    this.route.navigate(['/service/book'])
  }
  backPressed(){
    this.closeImage.emit();
  }

  setStep(step:any){
    this.currentStep = step;
    }
    messagePopup(){
      this.quoteId = this.estimate_item.quote_id
      if(this.userDetails){
        this.show_message = true
      } else {
        this.show_popup = true
      }
    }
    hideMessagePopup(ev: any){
      this.show_message = ev;
      this.estimate_item.msg_notification_count = 0
    }

}


interface Estimate {
  latitude?: string;
  longitude?: string;
  locationUrl?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  zip_code?: string;
  timings?: [{
    id?: number,
    day_of_week?: number | 0,
    start_time?: string,
    end_time?: string,
    time?: string,
    day?: string
  }];
  quote?:{
    items ?: [Item] | [],
    total?: string

  }
}
interface Item{
  service_id?: string,
  garage_id?: string,
  vechicle_id?: string,
  qty?: number | 0

}
