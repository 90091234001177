<footer class="footer footer_custom">
    <div class="content">
       <div class="columns mb-0">
        <div class="column is-3">
            <div class="footer-logo"><img src="../../../assets/img/arabama-logo.png" /></div>
            <h2 class="is-size-5 has-text-white has-text-weight-bold">08504272727</h2>
            <h6 class="is-size-6 has-text-success">
                <a class="has-text-success" href="mailto:team@arabaamaservis.com"> team@arabamaservis.com </a></h6>
        </div>
        <div class="column is-2">
          <h5 class="is-size-6 has-text-white has">{{ 'HOME.TEXT_74' | translate }}</h5>
          <a routerLink="/coming-soon" class="has-text-white text-size-14">{{ 'HOME.TEXT_74' | translate }}</a><br>
          <a routerLink="/coming-soon" class="has-text-white text-size-14">{{ 'HOME.TEXT_75' | translate }}</a><br>
          <a routerLink="/coming-soon" class="has-text-white text-size-14">{{ 'HOME.TEXT_76' | translate }}</a><br>
        </div>
        <div class="column is-2">
               <h5 class="is-size-6 has-text-white has">{{ 'HOME.TEXT_36' | translate }}</h5>
               <a routerLink="/coming-soon" class="has-text-white text-size-14">{{ 'HOME.TEXT_37' | translate }}</a><br>
               <a routerLink="/coming-soon" class="has-text-white text-size-14">{{ 'HOME.TEXT_38' | translate }}</a><br>
               <a routerLink="/coming-soon" class="has-text-white text-size-14">{{ 'HOME.TEXT_39' | translate }}</a><br>
               <a routerLink="/coming-soon" class="has-text-white text-size-14">{{ 'HOME.TEXT_40' | translate }}</a><br>
               <a routerLink="/coming-soon" class="has-text-white text-size-14">{{ 'HOME.TEXT_63' | translate }}</a>
        </div>
        <div class="column is-2">
            <h5 class="is-size-6 has-text-white">{{ 'HOME.TEXT_41' | translate }}</h5>
            <div *ngFor="let category of legalCategories">
              <a class="has-text-white text-size-14" (click)="openInNewTab(category)">{{ category.category_name }}</a><br>
            </div>
        </div>
        <div class="column is-3">
            <p class="social-icon-wrapper">
              <button class="button is-rounded social-icon mr-2">
                <span class="icon is-small">
                  <i class="fab fa-instagram"></i>
                </span>
              </button>
              <button class="button is-rounded social-icon mr-2">
                <span class="icon is-small">
                  <i class="fab fa-youtube"></i>
                </span>
              </button>
                <button class="button is-rounded social-icon mr-2">
                  <span class="icon is-small ">
                    <i class="fab fa-linkedin"></i>
                  </span>
                </button>
                <button class="button is-rounded social-icon mr-2">
                  <span class="icon is-small ">
                    <i class="fab fa-facebook"></i>
                  </span>
                </button>
                <button class="button is-rounded social-icon mr-2">
                  <span class="icon is-small">
                    <i class="fab fa-twitter"></i>
                  </span>
                </button>
                <button class="button is-rounded social-icon is-hidden">
                  <span class="icon is-small">
                    <i class="fab fa-google-plus-g"></i>
                  </span>
                </button>
              </p> 
        </div>
       </div>
       <div class="columns is-half">
       <div class="column is-5">
        <span style="color: white;font-weight: bold;font-size: small;">{{date}} - arabamaservis.com  Tüm hakkı saklıdır.</span>
      </div> 
      <div class="column">
        <span style="color: white;font-weight: bold;font-size: small;">sürüm 1.0</span>
      </div> 
      </div>
    </div>
  </footer>