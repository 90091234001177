<div class=" vehicle-list">
  <div class="columns m-0 is-vcentered">
    <div class="dropdown is-hoverable is-right edit_del">
      <div class="dropdown-trigger">
        <button class="button" style="border: none;" aria-haspopup="true" aria-controls="dropdown-menu">
          <span class="icon">
            <img src="../../../assets/svg/ellipsis-solid.svg">
          </span>
        </button>
      </div>
      <div class="dropdown-menu drop-down-action" id="{{vehcileData.vechicle_id}}">
        <div class="vehcard">
          <a class="is-flex is-justify-content-space-evenly" >
            <img class="edit-icon m-1" src="../../../assets/svg/edit.svg" (click)="clickEdit()" style="height: 20px;"/>
            <img class="del-icon m-1" src="../../../assets/svg/delete.svg" (click)="clickDelete()" style="height: 20px;"/>
          </a>
        </div>      
      </div>
    </div>
    <div class="column is-3 is-full-mobile brand-logo is-flex is-align-items-center is-justify-content-center">
      <img [src]="vehcileData.img" />
    </div>
    <div class="column is-9 is-full-mobile">
      <h2 class="mb-4 mx-2 is-size-6-mobile">{{vehcileData.vehicleFullName}}</h2>
      <ul class="is-flex is-flex-wrap-wrap">
        <li class="mx-2">{{'PROFILE.MILEAGE' | translate}}: <strong>{{vehcileData.mileage}}</strong></li>
        <li class="mx-2">|</li>
        <li class="mx-2">{{'PROFILE.PLATE' | translate}}: <strong>{{vehcileData.plate}}</strong></li>
        <li class="mx-2">|</li>
        <li class="mx-2">{{'PROFILE.VIN' | translate}}: <strong>{{vehcileData.VIN}}</strong></li>
        
      </ul>
    </div>
  </div>
  
</div>