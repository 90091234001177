<div class="modal fade is-active">
    <div class="modal-card">
      <header class="modal-card-head pt-3 pb-0 is-flex pl-6 is-justify-content-space-between">
        <div class="has-text-weight-bold is-size-5-desktop is-size-5-tablet is-size-5-mobile popup-heading">{{'DASHBOARD.ASK_THE' | translate}}  <span class="garage-name">{{estimation.name}}</span></div>
        <button class="close-button delete is-large" (click)="hidePopup()"></button>
      </header>
        <div class="modal-card-body py-0">
          <div class="box-container columns my-4 is-flex is-justify-content-center">
            <div class="job-panel p-0">
              <div class="box-content"  #chatContainer>
                <div class="columns m-0 is-mobile mt-2" *ngFor="let msg of message_list" [ngClass]="msg.sender_id ===userData.user_id? 'is-justify-content-end': 'is-justify-content-start'">
                  <div class="column is-narrow py-0 pl-0" [ngClass]="msg.sender_id ===userData.user_id? 'is-hidden': 'driver-msg'">
                        <div
                        [ngClass]="{
                          'msg-initials-driver': msg.sender_id !==userData.user_id}"
                        class="msg-initials is-flex is-align-items-center is-justify-content-center pt-1 font-14"
                        id="chat"
                      >
                      <strong>
                        {{ getInitials(estimation.name) }}
                      </strong>
                    </div>
                  </div>
                  <div class="column is-fluid msg-container" [ngClass]="msg.sender_id ===userData.user_id? 'garage': 'driver'">
                    <div class="msg-content px-3 font-14">
                      <strong *ngIf="msg.sender_id ===userData.user_id" class="name">
                        {{ userData.first_name }} {{userData.last_name}}
                      </strong>
                      <strong *ngIf="msg.sender_id !==userData.user_id" class="name">
                        {{ estimation.name }}
                      </strong>
                        <p class="date-time font-14">{{ msg.date_create | msgDate}}</p>
                      <p class="msg">
                        {{ msg.message_text }}
                      </p>
                    </div>
                  </div>
                  <div class="column is-narrow py-0 pr-0" [ngClass]="msg.sender_id !==userData.user_id? 'is-hidden': 'driver-msg'">
                    <div
                    [ngClass]="{
                      'msg-initials-garage': msg.sender_id ===userData.user_id}"
                    class="msg-initials is-flex is-align-items-center is-justify-content-center pt-1 font-14"
                    id="chat"
                  >
                  <strong *ngIf="userData.user_id === msg.sender_id">
                    {{ getInitials(userData.first_name + ' ' + userData.last_name) }}
                  </strong>
                </div>
              </div>
                </div>
              </div>
              <div class="sticky-bottom">
                <div class=" is-flex is-align-items-center m-0 chat-input">
                  <div class="message mb-0 mr-2 py-0">
                    <input type="text" placeholder="{{'DASHBOARD.TYPE_A_MESSAGE' | translate}}" [(ngModel)]="message" name="message" class="input" autocomplete="off" autofocus (keydown)="onKeyDown($event)"/>
                  </div>
                 
                <img
                class="is-clickable"
                src="../../../../assets/svg/send-button_send.svg"
                alt=""
                (click)="disableBtn ? '' : sendMessage()"
              />
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
