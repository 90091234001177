import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-garage-selection-dialog',
  templateUrl: './garage-selection-dialog.component.html',
  styleUrls: ['./garage-selection-dialog.component.scss']
})
export class GarageSelectionDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<GarageSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { garages: any[] }
  ) {}
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  selectGarage(garage: any) {
    this.dialogRef.close(garage); // Return the selected garage
  }
}
