import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/Constants/constants';
import { BookingServiceService } from 'src/app/services/booking-service.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  currentStep!: number;
  faqCategories: any;
  selectedCategory :any;
  faqDetails: any;
  driverFaqDetails: any;

  constructor(private router: Router, private BookingService: BookingServiceService) { }

  ngOnInit(): void {
    this.router.navigate(['/faq']);
    this.fetchAllCategory()
  }
  setStep(step: any) {
    this.currentStep = step;
  }
  fetchAllCategory() {
    let data={ user_type : 'driver-faq', status: 'A'}    
    this.BookingService.fetchFaqCategory(data).subscribe({
      next: (res) => {
        this.faqCategories = res.data || []
        this.fetchFaqDetails()
        // localStorage.setItem(Constants.APP.SELECTED_FAQ_CATEGORY, JSON.stringify(this.selectedCategory));  
               
      }, error: (err)=> {
        console.log(err)
      }
    })
  }
  categoryChange(category: any){
    this.selectedCategory = category
    // localStorage.setItem(Constants.APP.SELECTED_FAQ_CATEGORY, JSON.stringify(this.selectedCategory));
    this.driverFaqDetails = this.faqDetails.filter(
      (faq : any) => faq.category_id === this.selectedCategory.id
    );  }
  fetchFaqDetails() {
    let data = { user_type: 'driver-faq'}    
    this.BookingService.fetchFaqDetails(data).subscribe({
      next: (res) => {
        this.faqDetails = res.data || []
        this.faqCategories = this.faqCategories.filter((category : any) => {
          return this.faqDetails.some((faq : any) => faq.category_id === category.id);
        });
        this.driverFaqDetails = [];
        if (this.faqCategories.length > 0) {
          this.selectedCategory = this.faqCategories[0];
          this.driverFaqDetails = this.faqDetails.filter(
            (faq : any) => faq.category_id === this.selectedCategory.id
          );
        } else {
          this.selectedCategory = null;
          this.driverFaqDetails = [];
        }        
      }, error: (err)=> {
        console.log(err)
      }
    })
  }
}
