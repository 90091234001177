<div *ngIf="showImage">
    <!-- <button class="button back_button my-2 ml-2" (click)="backPressed()">
        <span class="icon is-small is-left" style="height: 60px; color: #fff; ">
          <i class="fas fa-arrow-left"></i>
        </span>
    </button> -->
    <div class="is-flex is-justify-content-end">
        <button class="button mx-2 is-success  has-text-weight-semibold ask-btn" (click)="backPressed()">
            Alinti detaylari
         </button>
    </div>
    <div>
        <div class="font-18 has-text-weight-semibold">{{estimate_item.name}}</div>
        <div class="font-14 pt-2 pb-1" style="color: #4a4a4a;"><span> <strong>{{estimate_item.distance | number :
            '1.2-2'}} km</strong></span> - {{estimate_item.city}} / {{estimate_item.state}}
        </div>
        <div *ngIf="estimate_item?.specialization.length > 0" class="features-wrapper columns m-0 pb-1 is-multiline">
            <div class="features-inner pt-2" *ngFor="let item of estimate_item?.specialization index as i;">
              <div [ngClass]="i !=0 ? ' ' : ''" class="d-flex is-align-items-center specialization-data">
                <span class="font-14 px-3 py-1 m-0 pt-1" style="line-height: 1.2;">{{ item.name }}</span>
              </div>
            </div>
            <!-- <div class="is-clickable more pt-3" *ngIf="estimate_item?.specialization?.length > defaultSpecializationCount && specializationLength !== estimate_item?.specialization?.length" (click)="specializationLength = estimate_item?.specialization?.length">+{{estimate_item?.specialization?.length - defaultSpecializationCount}} {{'DASHBOARD.MORE' | translate}}</div> -->
            <!-- <div class="is-clickable more pt-3" *ngIf="estimate_item?.specialization?.length === specializationLength && estimate_item?.specialization?.length !== defaultSpecializationCount" (click)="specializationLength = defaultSpecializationCount">{{'DASHBOARD.LESS' | translate}}</div> -->
          </div>
          <div *ngIf="estimate_item?.amenities.length > 0" class="features-wrapper columns is-multiline pt-1 mt-1 m-0">
            <div class="features-inner pb-1"
              *ngFor="let item of estimate_item?.amenities index as i;">
              <div [ngClass]="i !=0 ? '' : ''" class="d-flex is-align-items-center">
                  <img class="image is-24x24" src="{{item.logo_url}}" />
                <span class="font-14 pl-1 pt-1">{{ item.name }}</span>
              </div>
            </div>
            <!-- <div class="is-clickable more pt-1" *ngIf="estimate_item?.amenities?.length > defaultAmenitiesCount && amenitiesLength !== estimate_item?.amenities?.length" (click)="amenitiesLength = estimate_item?.amenities?.length">+{{estimate_item?.amenities?.length - defaultAmenitiesCount}} {{'DASHBOARD.MORE' | translate}}</div> -->
            <!-- <div class="is-clickable more pt-1" *ngIf="estimate_item?.amenities?.length === amenitiesLength && estimate_item?.amenities?.length !== defaultAmenitiesCount" (click)="amenitiesLength = defaultAmenitiesCount">{{'DASHBOARD.LESS' | translate}}</div> -->
          </div>
          <div class="columns is-flex m-0 pt-2">
            <div *ngIf="estimate_item.network_name === 'Bosch Car Service'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
              <img class="mr-1 my-1" src="../../../assets/img/bosch_car_service.jpg" alt="" style="width: 30px; height: 30px;">          
            </div>
            <div *ngIf="estimate_item.tse_quality_checked === '1'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
              <img class="mr-1 my-1" src="../../../assets/img/tse.png" alt="" style="width: 45px; height: 30px;">
            </div>
            <div *ngIf="estimate_item.exhaust_emission_service === 'yes'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
              <img class="mr-1 my-1" src="../../../assets/img/exhaust_emission.png" alt="" style="min-width: 55px; width: 55px; height: 30px;">
    
            </div>
          </div>
          <div *ngIf="estimate_item.about" class="font-14 pt-4">
            <p class="has-text-weight-semibold font-14 mb-0">About garage</p>
            <div class="pt-1">
              {{estimate_item.about}}
          </div>
          <!-- <div *ngIf="estimate_item.about?.length > 50 && estimate_item.showMore" class="comments">
              {{estimate_item.about?.slice(0, 50)}} <span class="is-clickable more" (click)="estimate_item.showMore = false">{{'DASHBOARD.MORE' | translate}}</span>
          </div>
          <div *ngIf="estimate_item.about?.length > 50 && !estimate_item.showMore" class="comments">
              {{estimate_item.about}} <span class="is-clickable more" (click)="estimate_item.showMore = true">{{'DASHBOARD.LESS' | translate}}</span>
          </div> -->
          </div>
    </div>
    <div *ngIf="estimate_item?.media.length > 0" class="image-gallery pt-3">
        <div *ngFor="let media of estimate_item?.media;" class="image-item">
            <img [src]="media.img_url" alt="" class="image">
        </div>
    </div>
    
</div>
<div *ngIf="!showImage">
    <div class="columns m-0" *ngIf="!quotation">
        <div class="column p-0 is-flex is-flex-wrap-wrap is-justify-content-end">            
            <div class="button-field is-flex is-flex-wrap-wrap" *ngIf="!fromMap">
                <button class="button mx-2 has-text-weight-semibold ask-btn" (click)="messagePopup()">
                    <img class="mr-2" src="assets/svg/chat.svg" width="20px" height="20px"/>
                    {{"COMMON.ASK_QUESTION" | translate }}
                </button>
                <button class="button mx-2 is-success  has-text-weight-semibold ask-btn" (click)="nextStepper(estimationList)">
                    {{"COMMON.CREATE_AN_APPOINTMENT" | translate }}
                 </button>
            </div>
        </div>          
    </div>
    <div class="columns m-0 itemized_estimate bg-topfield my-2">
        <div class="column is-6 pt-1 pb-0" *ngIf="!fromMap">
            <p class="color-arabama-green font-18 has-text-weight-semibold">Services</p>
            <div class="columns m-0 itemized_estimate active pt-2" >
                <p *ngFor="let item of itemList; let i = index; last as isLast" class="font-14">{{item.service_name}}<span *ngIf="!isLast">,&nbsp;</span></p>
            </div>
        </div>
        <div  class="column is-6 py-0 is-flex is-align-items-center is-justify-content-end">
            <div class="">
                <p *ngIf="estimate_item.original_price !== total" class="has-text-weight-bold dis-field">Original&nbsp;price</p>
                <p *ngIf="total_discount != 0" class="has-text-weight-bold dis-field">Discount</p>
                <p class="has-text-weight-bold is-size-5-tablet has-text-right heading-b-color">{{"COMMON.TOTAL_TXT" | translate }}&nbsp;{{"COMMON.PRICE_TXT" | translate }}</p>
            </div>
            <div class="has-text-right pl-5">
                <p class="has-text-weight-medium text-size-14 diagonal-strikethrough" *ngIf="estimate_item.original_price !== total">{{estimate_item.original_price}}</p>
                <p *ngIf="total_discount != 0" class="has-text-weight-bold dis-field">₺&nbsp;{{ total_discount | number:'1.2-2':'tr'}}</p>
                <p class="has-text-weight-bold is-size-5-tablet has-text-right heading-b-color">₺&nbsp;{{total_price | number:'1.2-2':'tr' }}</p>
            </div>
        </div>
    </div>

    <div class="pb-3 is-flex is-flex-direction-column is-justify-content-space-between" style="background-color: #ffffff; border-radius: 10px;" [ngClass]="fromMap ? '' : 'fromMap'">
        <div>
            <div class="column py-0 is-flex is-justify-content-end" *ngIf="!fromMap">
                <p
                class="font-14 has-text-weight-semibold less-details color-arabama-green is-clickable"
                (click)="toggleQuoteDetails()">{{ isQuoteDetailsVisible ? 'Less details' : 'View details' }}
              </p>
            </div>
            <div *ngIf="isQuoteDetailsVisible" class="quote_details is-hidden-mobile">
                <div class="columns m-0" *ngIf="!fromMap" >
                    <div class="column pb-1 is-6">
                     <p class="font-14 has-text-weight-semibold color-arabama-green">{{'COMMON.ITEMIZED_ESTIMATE' | translate}}
                     </p>
                    </div>
                    <div class="column pb-1 is-2 has-text-centered">
                     <p class="font-14 has-text-weight-semibold">{{"COMMON.QUANTITY" | translate }}
                     </p>
                    </div>
                    <div class="column pb-1 is-2 has-text-centered">
                     <p class="font-14 has-text-weight-semibold">{{"COMMON.UNIT_PRICE" | translate }}&nbsp;(₺)
                     </p>
                    </div>
                    <div class="column pb-1 is-2 has-text-centered">
                     <p class="font-14 has-text-weight-semibold">{{"COMMON.TOTAL_PRICE" | translate }}&nbsp;(₺)
                     </p>
                    </div>
                 </div>
                 <div class="itemized_estimate active pt-0" *ngFor="let item of itemList; let i = index"  style="border-top: 2px solid #BBBBBB;">
                     <div class="py-1 is-flex is-justify-content-space-between is-align-items-center" style="background-color: #f6f6f6;">
                        <p class="font-14 px-3 has-text-weight-semibold">{{item.service_name}}</p>
                     <p class="pr-3 is-clickable" (click)="toggleItemDetails(i)">
                    </p>
                     </div>
                     <div  class="item_details">
                         <div  class="columns m-0" *ngFor="let add_item of item.offer_item">
                             <div class="column is-6 pt-1 pb-0">
                                 <p class="font-14"><span *ngIf="add_item.item_code">{{add_item.item_code}} -</span>{{add_item.item_name}} - {{add_item.item_brand}}</p>
                                </div>
                                <div class="column is-2 pt-1 pb-0 has-text-centered">
                                 <p class="font-14 has-text-weight-semibold">{{add_item.item_quantity}}
                                 </p>
                                </div>
                                <div class="column is-2 pt-1 pb-0">
                                 <p class="font-14 has-text-weight-semibold has-text-right">{{add_item.item_price | number:'1.2-2':'tr'}}
                                 </p>
                                </div>
                                <div class="column is-2 pt-1 pb-0">
                                 <p class="font-14 has-text-weight-semibold has-text-right">{{add_item.item_totalprice | number:'1.2-2':'tr'}}
                                 </p>
                                </div>
                         </div>
                         <div class="columns m-0" *ngIf="item.labour_price">
                             <div class="column is-6 pt-1 pb-0">
                                 <p class="font-14"><span *ngIf="item.labour_code">{{item.labour_code}} -</span>Iscilik</p>
                                </div>
                                <div class="column is-2 pt-1 pb-0 has-text-centered">
                                 <p class="font-14 has-text-weight-semibold">{{item.labour_quantity}}
                                 </p>
                                </div>
                                <div class="column is-2 pt-1 pb-0">
                                 <p class="font-14 has-text-weight-semibold has-text-right">{{item.labour_price | number:'1.2-2':'tr'}}
                                 </p>
                                </div>
                                <div class="column is-2 pt-1 pb-0">
                                 <p class="font-14 has-text-weight-semibold has-text-right">{{item.labour_totalprice | number:'1.2-2':'tr'}}
                                 </p>
                                </div>
                         </div>
                         <div *ngIf="item.service_comment" class="column is-6 py-1 is-flex is-align-items-flex-start" >
                            <img class="mr-2 is-clickable notes-icon" src="../../../assets/svg/notes.svg" alt="">
                            <div *ngIf="item.service_comment.length < 35" class="comments font-14">
                                {{item.service_comment}}
                            </div>
                            <div *ngIf="item.service_comment.length > 35 && item.showMore" class="comments font-14">
                                {{item.service_comment.slice(0, 35)}} <span class="is-clickable more font-14" (click)="item.showMore = false">{{'DASHBOARD.MORE' | translate}}</span>
                            </div>
                            <div *ngIf="item.service_comment.length > 35 && !item.showMore" class="comments font-14">
                                {{item.service_comment}} <span class="is-clickable more font-14" (click)="item.showMore = true">{{'DASHBOARD.LESS' | translate}}</span>
                            </div>
                        </div>
                     </div>
                 </div>
            </div>
        </div>
    
        
    
    
    
            <!-- Mobile layout --> 
            <div class="is-hidden-desktop is-hidden-tablet" >
                <div *ngIf="isQuoteDetailsVisible" class="quote_details">
                    <div class="columns is-flex m-0" *ngIf="!fromMap" >
                        <div class="column pb-1 is-4-mobile">
                         <p class="font-14 has-text-weight-semibold color-arabama-green">{{'COMMON.ITEMIZED_ESTIMATE' | translate}}
                         </p>
                        </div>
                        <div class="column pb-1 is-2-mobile pl-0 has-text-centered">
                         <p class="font-14 has-text-weight-semibold">{{"COMMON.QUANTITY" | translate }}
                         </p>
                        </div>
                        <div class="column pb-1 is-3-mobile pl-0 has-text-centered">
                         <p class="font-14 has-text-weight-semibold">{{"COMMON.UNIT_PRICE" | translate }}&nbsp;(₺)
                         </p>
                        </div>
                        <div class="column pb-1 is-3-mobile pl-0 has-text-centered">
                         <p class="font-14 has-text-weight-semibold">{{"COMMON.TOTAL_PRICE" | translate }}&nbsp;(₺)
                         </p>
                        </div>
                     </div>
                     <div class="itemized_estimate active pt-0" *ngFor="let item of itemList; let i = index"  style="border-top: 2px solid #BBBBBB;">
                         <div class="py-1 is-flex is-justify-content-space-between is-align-items-center" style="background-color: #f6f6f6;">
                            <p class="font-14 px-3 has-text-weight-semibold">{{item.service_name}}</p>
                         <p class="pr-3 is-clickable" (click)="toggleItemDetails(i)">
                        </p>
                         </div>
                         <div  class="item_details">
                             <div  class="columns is-flex m-0" *ngFor="let add_item of item.offer_item">
                                 <div class="column is-5-mobile pt-1 pb-0">
                                     <p class="font-14"><span *ngIf="add_item.item_code">{{add_item.item_code}} -</span>{{add_item.item_name}} - {{add_item.item_brand}}</p>
                                    </div>
                                    <div class="column is-1-mobile pl-0 pt-1 pb-0">
                                     <p class="font-14 has-text-weight-semibold">{{add_item.item_quantity}}
                                     </p>
                                    </div>
                                    <div class="column is-3-mobile pl-0 pt-1 pb-0">
                                     <p class="font-14 has-text-weight-semibold has-text-right">{{add_item.item_price | number:'1.2-2':'tr'}}
                                     </p>
                                    </div>
                                    <div class="column is-3-mobile pl-0 pt-1 pb-0">
                                     <p class="font-14 has-text-weight-semibold has-text-right">{{add_item.item_totalprice | number:'1.2-2':'tr'}}
                                     </p>
                                    </div>
                             </div>
                             <div class="columns is-flex m-0" *ngIf="item.labour_price">
                                 <div class="column is-5-mobile pt-1 pb-0">
                                     <p class="font-14"><span *ngIf="item.labour_code">{{item.labour_code}} -</span>Iscilik</p>
                                    </div>
                                    <div class="column is-1-mobile pt-1 pb-0 pl-0">
                                     <p class="font-14 has-text-weight-semibold">{{item.labour_quantity}}
                                     </p>
                                    </div>
                                    <div class="column is-3-mobile pt-1 pb-0 pl-0">
                                     <p class="font-14 has-text-weight-semibold has-text-right">{{item.labour_price | number:'1.2-2':'tr'}}
                                     </p>
                                    </div>
                                    <div class="column is-3-mobile pt-1 pb-0 pl-0">
                                     <p class="font-14 has-text-weight-semibold has-text-right">{{item.labour_totalprice | number:'1.2-2':'tr'}}
                                     </p>
                                    </div>
                             </div>
                             <div *ngIf="item.service_comment" class="column is-6 py-1 is-flex is-align-items-flex-start" >
                                <img class="mr-2 is-clickable notes-icon" src="../../../assets/svg/notes.svg" alt="">
                                <div *ngIf="item.service_comment.length < 35" class="comments font-14">
                                    {{item.service_comment}}
                                </div>
                                <div *ngIf="item.service_comment.length > 35 && item.showMore" class="comments font-14">
                                    {{item.service_comment.slice(0, 35)}} <span class="is-clickable more font-14" (click)="item.showMore = false">{{'DASHBOARD.MORE' | translate}}</span>
                                </div>
                                <div *ngIf="item.service_comment.length > 35 && !item.showMore" class="comments font-14">
                                    {{item.service_comment}} <span class="is-clickable more font-14" (click)="item.showMore = true">{{'DASHBOARD.LESS' | translate}}</span>
                                </div>
                            </div>
                         </div>
                     </div>
                </div>
            </div> 
        <!-- Mobile layout -->
    
            <div *ngIf="isQuoteDetailsVisible" class="columns m-0 mb-4" style="border-top: 3px solid #BBBBBB;">
                <div class="column is-12 is-flex is-align-items-center" *ngIf="!fromMap">
                    <p class="price-text">{{"COMMON.PRICE_TEXT" | translate }}
                    </p>
                </div>
                
            </div>
    </div>
</div>
<app-message *ngIf="show_message" [estimation]="estimate_item" [quoteId]="quoteId" (hideMessage)="hideMessagePopup($event)"></app-message>
<div *ngIf="show_popup" class="modal fade is-active">
    <div class="modal-card">
        <div class="modal-card-body py-5">
          <div class="has-text-centered pb-4 has-text-weight-semibold">
            {{'DASHBOARD.WOULD_YOU_LIKE_TO_CHAT_WITH_GARAGE' | translate}} <br> {{'DASHBOARD.PLEASE_SAVE_THE_ESTIMATION' | translate}}
          </div>
          <div class="is-flex is-justify-content-center">
            <div class="button btn_cancel has-text-weight-semibold m-2" (click)="show_popup = false">
              {{'DASHBOARD.CANCEL' | translate}}
            </div>
            <div class="button btn_save_estimate has-text-weight-semibold m-2" (click)="saveEstimate()">
              {{'DASHBOARD.SAVE' | translate}}
            </div>
          </div>
        </div>
    </div>
  </div>