<div class="card appointment-bg calendar-wrapper">
    <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
    <h5 class="text-size-18 subheading-color-1 trans">{{name.name}}</h5>
  </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="is-flex">
      <div class="is-flex is-justify-content-center is-align-items-center scroll-btn">
        <div (click)="scrollToLeft()" class="button arrow-button is-medium px-0">
          <span class="icon is-medium">
            <i class="fas fa-chevron-left"></i>
          </span>
        </div>
      </div>
      <div class="date-list">
        <div class="is-flex date-overflow py-2" #dateList>
          <span class="mx-1 date-cell" *ngFor="let d of dateArrayList; index as i">
            <div [ngClass]="d.selected ? 'has-background-success' : ''" (click)="updateDate(d)"
              class="py-3 has-text-centered date-bg-design">
              <span class="is-size-9 has-text-weight-bold text-dec-up">{{ d.month }}</span><br>
              <span class="is-size-9 has-text-weight-bold text-dec-up"
                [ngClass]="d.selected? 'has-text-white' : 'has-text-success' ">{{d.date}}</span><br>
              <span class="is-size-9 has-text-weight-bold text-dec-up">{{ d.day }}</span>
            </div>
          </span>
        </div>
      </div>
      <div class="is-flex is-justify-content-center is-align-items-center scroll-btn">
        <div (click)="scrollToRight()" class="button arrow-button is-medium px-0">
          <span class="icon is-medium">
            <i class="fas fa-chevron-right"></i>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoading" class="is-flex is-flex-wrap-wrap pb-3">
      <div class="time-cell" *ngFor="let t of timingData;index as i">
        <div class="px-2 py-2">
          <div (click)="updateTime(step-1,i)" [ngClass]="t.isSelected ? 'is-success ' : ''"
          class="cursor-default time-slot py-2 has-text-centered">{{t.time}}</div>
        </div>
      </div>
    </div>
      <div *ngIf="isLoading" class="spinner-wrapper is-flex is-justify-content-center" style="height: 174px;"><mat-spinner [diameter]="50"></mat-spinner>
      </div>
   
  </mat-expansion-panel>
</div>
