<nav class="navbar">
    <div class="navbar-brand is-pa-l-10">
      <img class="is-clickable" src="assets/img/arabama.png" width="150" height="40"  routerLink="/home"/>
    </div>
    <div class="stepper-nav">
        <div class="navbar-item">
          <app-stepper style="min-width:100%;" [activeStepper]="stepperCount"></app-stepper>
        </div>
    </div>
    <div class="navbar-end">
      <a class="navbar-item">
        <button class="button is-success" (click)="onClickClose()">
          <span class="icon is-small">
            <i class="fas fa-close"></i>
          </span>
        </button>
      </a>
    </div>
  </nav>
<div class="quote-content" [@slideInOut]>
    <div class="columns ps-relative m-0 is-centered pb-2">
        <div class="column is-12-mobile is-12-tablet is-10-desktop p-0">
          <div class="">
            <div class="content is-flex is-align-items-center px-3 pt-2">
                <button class="button back_button" (click)="backPressed()">
                    <span class="icon is-small is-left" style="height: 60px; color: #fff; ">
                      <i class="fas fa-arrow-left"></i>
                    </span>
                </button>
              <h3 class="pl-3 is-size-6-mobile my-0 heading-b-color">{{"COMMON.QUOTE_DETAILS" | translate}}</h3>
            </div>
          </div>
        </div>
      </div>  
      <div class="columns m-0 is-centered is-multiline mx-3">
          <div class="column is-12-mobile is-12-tablet is-3-desktop" style="background-color: #ffffff; border-top-left-radius: 10px; border-bottom-left-radius: 10px;">
            <div *ngIf="!showGallery" class="" style="background-color: #f2f2f2; border-radius: 5px; height: 100%;">
              <div class="content p-3 pt-0">
                <div class="is-flex-mobile is-flex-tablet-only">
                  <div class="is-flex-desktop is-flex-tablet">
                    <div class="profile-img">
                      <img [src]="image" class="image">
                    </div> 
                    <!-- <div>
                      <img *ngIf="estimateItem?.media.length > 0" src="../../../assets/svg/photo-video.svg" alt="" (click)="garageGallery()" class="media-img is-clickable">
                    </div> -->
                    <div class="pl-3">
                      <div class="font-18 has-text-weight-semibold">{{estimateItem.name}}</div>
                    <div class="rating-buttons pb-1 pt-2">
                      <div class="button is-small is-primary" style="cursor: default;">
                          <div class="star-rating is-flex is-align-items-center"><i class="fas fa-star is-size-7 mr-1"></i> <span class="font-14 pt-1">{{ estimateItem?.rating?.cum_rating }}</span></div>
                      </div>
                      <span class="pl-4 font-14">{{estimateItem.openTime}} - {{estimateItem.closeTime}}</span>
                    </div>
                    </div>
                  </div>
                  
                  
                </div>
                <div class="pt-2">     
                  <div class="font-14 pt-1 pb-1" style="color: #4a4a4a;"><span> <strong>{{estimateItem.distance | number :
                        '1.2-2'}} km</strong></span> - {{estimateItem.city}} / {{estimateItem.state}}
                  </div>
                </div>  
                <div *ngIf="estimateItem?.specialization.length > 0" class="features-wrapper columns mb-0 pb-1 is-multiline mx-0 pt-2">
                  <div class="features-inner pt-2" *ngFor="let item of estimateItem?.specialization?.slice(0, specializationLength) index as i;">
                    <div [ngClass]="i !=0 ? ' ' : ''" class="d-flex is-align-items-center specialization-data">
                      <span class="font-14 px-3 py-1 m-0 pt-1" style="line-height: 1.2;">{{ item.name }}</span>
                    </div>
                  </div>
                  <div class="is-clickable more pt-3" *ngIf="estimateItem?.specialization?.length > defaultSpecializationCount && specializationLength !== estimateItem?.specialization?.length" (click)="specializationLength = estimateItem?.specialization?.length">+{{estimateItem?.specialization?.length - defaultSpecializationCount}} {{'DASHBOARD.MORE' | translate}}</div>
                  <div class="is-clickable more pt-3" *ngIf="estimateItem?.specialization?.length === specializationLength && estimateItem?.specialization?.length !== defaultSpecializationCount" (click)="specializationLength = defaultSpecializationCount">{{'DASHBOARD.LESS' | translate}}</div>
                </div>
                <div *ngIf="estimateItem?.amenities.length > 0" class="features-wrapper columns is-multiline mx-0 pt-1 mt-1 my-0">
                  <div class="features-inner pb-1"
                    *ngFor="let item of estimateItem?.amenities?.slice(0, amenitiesLength) index as i;">
                    <div [ngClass]="i !=0 ? '' : ''" class="d-flex is-align-items-center">
                        <img class="image is-24x24" src="{{item.logo_url}}" />
                      <span class="font-14 pl-1 pt-1">{{ item.name }}</span>
                    </div>
                  </div>
                  <div class="is-clickable more pt-1" *ngIf="estimateItem?.amenities?.length > defaultAmenitiesCount && amenitiesLength !== estimateItem?.amenities?.length" (click)="amenitiesLength = estimateItem?.amenities?.length">+{{estimateItem?.amenities?.length - defaultAmenitiesCount}} {{'DASHBOARD.MORE' | translate}}</div>
                  <div class="is-clickable more pt-1" *ngIf="estimateItem?.amenities?.length === amenitiesLength && estimateItem?.amenities?.length !== defaultAmenitiesCount" (click)="amenitiesLength = defaultAmenitiesCount">{{'DASHBOARD.LESS' | translate}}</div>
                </div>
                <div class="columns is-flex m-0 pt-1">
                  <div *ngIf="estimateItem.network_name === 'Bosch Car Service'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
                    <img class="mr-1 my-1" src="../../../assets/img/bosch_car_service.jpg" alt="" style="width: 30px; height: 30px;">          
                  </div>
                  <div *ngIf="estimateItem.tse_quality_checked === '1'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
                    <img class="mr-1 my-1" src="../../../assets/img/tse.png" alt="" style="width: 45px; height: 30px;">
                  </div>
                  <div *ngIf="estimateItem.exhaust_emission_service === 'yes'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
                    <img class="mr-1 my-1" src="../../../assets/img/exhaust_emission.png" alt="" style="min-width: 55px; width: 55px; height: 30px;">
                  </div>
                </div>
                <div *ngIf="estimateItem.about" class="font-14 pt-4">
                  <p class="has-text-weight-semibold font-14 mb-0">About garage</p>
                  <div *ngIf="estimateItem.about?.length < 50" class="comments">
                    {{estimateItem.about}}
                </div>
                <div *ngIf="estimateItem.about?.length > 50 && estimateItem.showMore" class="comments">
                    {{estimateItem.about?.slice(0, 50)}} <span class="is-clickable more" (click)="estimateItem.showMore = false">{{'DASHBOARD.MORE' | translate}}</span>
                </div>
                <div *ngIf="estimateItem.about?.length > 50 && !estimateItem.showMore" class="comments">
                    {{estimateItem.about}} <span class="is-clickable more" (click)="estimateItem.showMore = true">{{'DASHBOARD.LESS' | translate}}</span>
                </div>
                </div>
                <div class="more-data px-1 mt-3 is-clickable" (click)="garageGallery()">
                  <p class="font-15 px-2 py-1 has-text-weight-semibold">More information</p>
                </div>
              </div>
            </div>
            <div *ngIf="showGallery" class="has-text-centered pt-5">
              <div class="pt-4 is-flex is-justify-content-center">
                <div class="garage-img">
                  <img [src]="image" class="image">
                </div> 
              </div>
             
              <div class="rating-buttons py-1 pt-3">
                <div class="button is-small is-primary" style="cursor: default;">
                    <div class="star-rating is-flex is-align-items-center"><i class="fas fa-star is-size-7 mr-1"></i> <span class="font-14 pt-1">{{ estimateItem?.rating?.cum_rating }}</span></div>
                </div>
                <p class="font-14 pt-3 has-text-weight-semibold">{{estimateItem.openTime}} - {{estimateItem.closeTime}}</p>
              </div>
              <div class="pt-3">
                <p class="has-text-weight-semibold font-14 opening-hrs pb-2">Working hours</p> 
              <ul *ngFor="let item of estimateItem.timings; index as i" class="pb-2">
                <div class="columns m-0 is-flex is-justify-content-center"> 
                    <p class=" has-text-weight-semibold day-color has-text-left">{{item.day}}</p>  
                    <p class="has-text-weight-bold time-color has-text-left">{{item.time}}</p>
                </div> 
              </ul>
            </div>
            </div>
          </div>
          <div class=" column is-12-mobile is-7-desktop" style="background-color: #ffffff; border-top-right-radius: 10px; border-bottom-right-radius: 10px;">
            <app-estimate-tab [estimate_item]="estimateItem" [quotation]="quotation" [showImage]="showGallery" (closeImage)="showGallery = false"></app-estimate-tab>
          </div>
      </div>
</div>