import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Constants } from 'src/app/Constants/constants';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/utils';
import { AddReviewsComponent } from 'src/app/dialog/add-reviews/add-reviews.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-service-history',
  templateUrl: './service-history.component.html',
  styleUrls: ['./service-history.component.scss']
})
export class ServiceHistoryComponent implements OnInit {

  currentStep: number = 0
  selectedValue: any = 'all'
  selectedValue1: any = 'all'
  serviced_details: any = []
  serviced_details_bk: any = []
  vehicle_years: any = ['Hepsi']
  vehicle_name: any = [{name: 'Hepsi'}]
  serviced_details_count: any;
  changeYear: boolean = false
  changeVehiclename: boolean = false
  year: any
  vehicleName: any
  isDataLoading: boolean = false
  isMobile: boolean = false
  selectedVehicle: any;
  selectedYear: any;
  showVehicleOptions: boolean = false
  showYearOptions: boolean = false
  filteredServices: any;
  constructor(private BookingService: BookingServiceService,private elementRef: ElementRef, private route: Router, private UtilsClass: Utils, private dialog: MatDialog) { }

  userData: any = {}

  ngOnInit(): void {
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    if (userdata != undefined) {
      this.userData = JSON.parse(userdata)
    }
    this.selectedVehicle = this.vehicle_name[0]
    this.selectedYear = this.vehicle_years[0]
    this.fetchAllAppointment()
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768;
    });
    
  }
  vehicleChange(data: any){
    this.showVehicleOptions = false
    this.selectedVehicle = data
    this.filterServices()
  }
  yearChange(data: any){
    this.showYearOptions = false
    this.selectedYear = data
    this.filterServices()
  }
  filterServices(){
    if(!this.selectedVehicle.id){
      this.filteredServices = this.serviced_details
    } else {
      this.filteredServices = this.serviced_details.filter((item: any) => item.vechicle_id === this.selectedVehicle.id)
    }
    if(this.selectedYear !== 'Hepsi'){
      this.filteredServices = this.filteredServices.filter((item: any) => item.service_year == this.selectedYear)
    }
  }
  fetchAllAppointment() {
    this.serviced_details=[]
    this.isDataLoading = true
    this.BookingService.fetchAppointment(this.userData.user_id).subscribe({
      error: (error) => {
        this.isDataLoading = false
      },
      next: (res) => {
        if (res.statusCode == 1) {
          let data = res.data
          if(data){
          data.forEach((element: any) => {
            if (element.status === 'completed' || element.status === 'Completed & Reviewed') {
              let service_date = moment(element.date_update).locale('tr').format('MMMM DD, YYYY')
              let service_year = moment(element.date_update).locale('tr').format('YYYY')
              let service_duration = moment(element.date_update).locale('tr').fromNow()
              let part = service_duration.split(' ')[1];
              let capitalizedPart = part.charAt(0).toLocaleUpperCase('tr-TR') + part.slice(1);
              let changedServiceDuration = service_duration.split(' ')
              changedServiceDuration[1] = capitalizedPart
              element.service_date = service_date
              element.service_duration = changedServiceDuration.join(' ')
              element.service_year = service_year
              let vehicle_name = element.vehicle_details[0].make_name + " " + element.vehicle_details[0].model_name + ""
              element.vehicle_name = vehicle_name
              element.amount_to_pay = Number(element.amount_to_pay).toLocaleString('tr',{style: 'currency', currency: 'TRY'}).replace('₺', '₺ ')
              element.invoice_items.total = Number(element.invoice_items.total).toLocaleString('tr',{style: 'currency', currency: 'TRY'}).replace('₺', '₺ ')
              element.total_amount = Number(element?.total_amount).toLocaleString('tr',{style: 'currency', currency: 'TRY'}).replace('₺', '₺ ')
              if(element.completed_price !== null && element.completed_price !== '' && element.completed_price !== 'null'){
                element.completed_price = Number(element?.completed_price).toLocaleString('tr',{style: 'currency', currency: 'TRY'}).replace('₺', '₺ ')
              }
              else{
                element.completed_price = null
              }
              if(element.reviews[0]?.comment?.length > 25){
                element.showMore = true
              }else{
                element.showMore = false
              }      
              this.serviced_details.push(element);
              if (!this.vehicle_years.includes(element.service_year)) {
                this.vehicle_years.push(element.service_year);
              }
              if (!this.vehicle_name.find((v: any) => v.name === vehicle_name)) {
                this.vehicle_name.push({ name: element.vehicle_name, id: element.vechicle_id });
              }
            }
          })
        }
          this.filteredServices = this.serviced_details
        
          this.isDataLoading = false
        }
      }
    })
  }

  setStep(step: any) {
    this.currentStep = step;
  }

  viewQuotation(data: any) {
    this.route.navigate(['/service/estimates'], { state: { estimateViewItem: data, estimateQuotationItem: "Quotation" }, queryParams: {from: 'service-history'} })
  }

  starValue(data: any, ev: any) {
    this.openViewDirection(data, ev)
  }

  jobReview(ev: any) {
    this.openViewDirection({}, ev)
  }


  openViewDirection(data: any, ev: any) {
    let width = '50%'
    let heigth = ''
    let maxWidth = '80vw'
    if (this.UtilsClass.isMobile()) {
      width = '90%'
      heigth = ''
      maxWidth = '100vw'
    }
    const viewDialogConfirm = this.dialog.open(AddReviewsComponent, {
      height: heigth,
      width: width,
      maxWidth: maxWidth,
      panelClass: ['directionCustom'],
      data: {
        rating: data,
        garage_id: ev.garage_id,
        job_id: ev.id
      }
    })
    viewDialogConfirm.afterClosed().subscribe((result: any) => {
      if (result?.data == 'reviewAdd') {
        this.fetchAllAppointment()
      }
      else{
        this.fetchAllAppointment()
      }
    });
  }
  onBackPressed(){
    this.route.navigate(['/dashboard'])
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const ClickedElement = event.target as HTMLElement
    const optionContainer = ClickedElement.closest('.options');
    const inputContainer = ClickedElement.closest('.input-box');
    if (!optionContainer && !inputContainer) {
      this.showVehicleOptions = false;
      this.showYearOptions = false
    }
  }
}


