<nav class="navbar" role="navigation" aria-label="main navigation" >
  <div
    class="navbar-brand is-pa-l-10" 
  >
    <img
      routerLink="/home"
      class="is-clickable"
      src="assets/img/arabama.png"
      style="display: block"/>
  </div>
</nav>
<div class="about-header columns m-0 is-flex is-justify-content-center">
  <div class="column is-10">
      <p class="font-24 has-text-weight-semibold pt-5">{{headerText}}</p>
      <p class="font-14 pt-5">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione iusto sequi numquam? Dolore, excepturi iste qui quos deleniti iusto dolorum nesciunt accusantium hic consequatur modi corrupti optio officiis ducimus in.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione iusto sequi numquam? Dolore, excepturi iste qui quos deleniti iusto dolorum nesciunt accusantium hic consequatur modi corrupti optio officiis ducimus in.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione iusto sequi numquam? Dolore, excepturi iste qui quos deleniti iusto dolorum nesciunt accusantium hic consequatur modi corrupti optio officiis ducimus in.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione iusto sequi numquam? Dolore, excepturi iste qui quos deleniti iusto dolorum nesciunt accusantium hic consequatur modi corrupti optio officiis ducimus in.
      </p>
      <!-- <p class="font-18 has-text-weight-semibold pt-3">Who we are?</p> -->
    
     
      <div class="mt-5 is-flex is-justify-content-center">
          <div class="about-img1">
              <img src="../../../assets/img/banner2.png" alt="">
          </div>
      </div>
      <!-- <p class="font-18 has-text-weight-semibold pt-5">Our scommitment</p> -->
      <p class="font-14 pt-5">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione iusto sequi numquam? Dolore, excepturi iste qui quos deleniti iusto dolorum nesciunt accusantium hic consequatur modi corrupti optio officiis ducimus in.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione iusto sequi numquam? Dolore, excepturi iste qui quos deleniti iusto dolorum nesciunt accusantium hic consequatur modi corrupti optio officiis ducimus in.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione iusto sequi numquam? Dolore, excepturi iste qui quos deleniti iusto dolorum nesciunt accusantium hic consequatur modi corrupti optio officiis ducimus in.
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione iusto sequi numquam? Dolore, excepturi iste qui quos deleniti iusto dolorum nesciunt accusantium hic consequatur modi corrupti optio officiis ducimus in.
      </p>
      <p class="font-18 has-text-weight-semibold pt-5">
          Araç sahipleri bizi neden tercih etmeli? </p>
      <div class="columns m-0 pt-2">
          <div class="column is-5 px-0">
              <div class="about-img2">
                  <img src="../../../assets/img/choose-us.png" alt="">
              </div>
          </div>
          <div class="column is-7">
              <p class="font-16 has-text-weight-semibold">Araç sahiplerinin güvenilir uzman servislere, 
                  rekabetçi fiyatlarla ulaşmaları için yardımcı oluruz.</p>
               <p class="font-14 pt-1"><span class="has-text-weight-bold">arabamaservis.com </span>araç sahiplerine bakım onarım işlerini yönetecekden Ücretsiz ve kolay kullanımli online platform sunar.</p>
               <p class="font-14 pt-4">Sisteme Uye, tarnamen bağımsız servisler araç sahiplerine en iyi teklifier ve en lyi hizmeti sunmak için birtairleriyle yarışırlar. Amacıme, aracıngın markasi ve aradiginiz in detayı ne olursa olsun en doğru servisi en doğru fiyat ile bulmanıza yardim etmelitir.
              </p>
              <p class="font-14 pt-4">Arabanın bakım onanm ihtiyacını sizin için dert olmaktan çıkarız </p>
              <div class="is-flex is-align-items-center pt-2">
                  <img src="../../../assets/img/points.png" alt="">
                  <p class="font-14 pl-2">En iyi servislerden rekabetçi fiyatlar</p>
              </div>
              <div class="is-flex is-align-items-center pt-2">
                  <img src="../../../assets/img/points.png" alt="">
                  <p class="font-14 pl-2">Her aşamada profesyonel hizmet</p>
              </div>
              <div class="is-flex is-align-items-center pt-2">
                  <img src="../../../assets/img/points.png" alt="">
                  <p class="font-14 pl-2">arabamaservis-com Garantili Servis</p>
              </div>
              <div class="is-flex is-align-items-center pt-2">
                  <img src="../../../assets/img/points.png" alt="">
                  <p class="font-14 pl-2">Online fiyat karşılaştıma ve randevu oluşturma </p>
              </div>
              <div class="is-flex is-align-items-center pt-2">
                  <img src="../../../assets/img/points.png" alt="">
                  <p class="font-14 pl-2">Turm servis bilgilenız kayıt altında </p>
              </div>
              <div class="is-flex is-align-items-center pt-2">
                  <img src="../../../assets/img/points.png" alt="">
                  <p class="font-14 pl-2">Arabam Gilvende Sigortası</p>
              </div>
          </div>
      </div>
  </div>
</div>