

   <div class="service-option" >
    <!-- <div *ngIf="showGallery" class="back-field">
      <button class="button back_button mb-2 ml-2" (click)="backPressed()">
        <span class="icon is-small is-left" style="height: 60px; color: #fff; ">
          <i class="fas fa-arrow-left"></i>
        </span>
    </button>
    </div> -->
    <div class="close-field">
      <button  class="pop-close is-clickable" (click)="clickClose()" >
          <i class="fas fa-close" style="font-size: 18px; color: white !important;"></i>
      </button>
    </div>
    <!-- <div class="columns ps-relative is-multiline  is-flex is-justify-content-center  mt-2 mx-0 mb-0 view-garage-header">
      <div *ngIf="!showGallery" class="column back-color py-0 is-12-mobile is-12-tablet is-13-desktop">
        <div class="">
          <div class="content py-3 px-1">
            <div class="is-flex-mobile">
            <div class="is-flex-desktop is-flex-tablet">
              <div class="profile-img">
                <img [src]="image" class="image">
              </div> 
              <div class="pl-3">
                <div class="has-text-weight-semibold font-17">{{estimateItem.name}}</div>
                <div class="rating-buttons pb-1 pt-2">
                  <div class="button is-small is-primary" style="cursor: default;">
                      <div class="star-rating is-flex is-align-items-center"><i class="fas fa-star is-size-7 mr-1"></i> <span class="pt-1 font-14">{{ estimateItem?.rating?.cum_rating }}</span></div>
                  </div>
                  <span class="pl-4 font-14">{{estimateItem.openTime}} - {{estimateItem.closeTime}}</span>
                </div>
            </div>
            </div>
              </div>
              <div class="pt-2 pb-1 distance font-14" style="color: #4a4a4a;"><span> <strong >{{estimateItem.distance | number :
                '1.2-2'}} km</strong></span> - {{estimateItem.city}} / {{estimateItem.state}}
          </div>
            <div class="features-wrapper columns mb-0 pb-1 is-multiline pl-3 pt-3">
              <div class="features-inner pt-2" *ngFor="let item of estimateItem?.specialization?.slice(0, specializationLength) index as i;">
                <div [ngClass]="i !=0 ? ' ' : ''" class="d-flex is-align-items-center specialization-data">
                  <span class="font-14 px-3 py-1 m-0 pt-1" style="line-height: 1.2;">{{ item.name }}</span>
                </div>
              </div>
              <div class="is-clickable more pt-3" *ngIf="estimateItem?.specialization?.length > defaultSpecializationCount && specializationLength !== estimateItem?.specialization?.length" (click)="specializationLength = estimateItem?.specialization?.length">+{{estimateItem?.specialization?.length - defaultSpecializationCount}} {{'DASHBOARD.MORE' | translate}}</div>
              <div class="is-clickable more pt-3" *ngIf="estimateItem?.specialization?.length === specializationLength && estimateItem?.specialization?.length !== defaultSpecializationCount" (click)="specializationLength = defaultSpecializationCount">{{'DASHBOARD.LESS' | translate}}</div>
            </div>
            <div class="features-wrapper columns is-multiline pl-3 pt-1 my-0">
              <div class="features-inner pb-1"
                *ngFor="let item of estimateItem?.amenities?.slice(0, amenitiesLength) index as i;">
                <div [ngClass]="i !=0 ? '' : ''" class="d-flex is-align-items-center">
                    <img class="image is-24x24" src="{{item.logo_url}}" />
                  <span class="text-size-14 pl-1 pt-1">{{ item.name }}</span>
                </div>
              </div>
              <div class="is-clickable more pt-1" *ngIf="estimateItem?.amenities?.length > defaultAmenitiesCount && amenitiesLength !== estimateItem?.amenities?.length" (click)="amenitiesLength = estimateItem?.amenities?.length">+{{estimateItem?.amenities?.length - defaultAmenitiesCount}} {{'DASHBOARD.MORE' | translate}}</div>
              <div class="is-clickable more pt-1" *ngIf="estimateItem?.amenities?.length === amenitiesLength && estimateItem?.amenities?.length !== defaultAmenitiesCount" (click)="amenitiesLength = defaultAmenitiesCount">{{'DASHBOARD.LESS' | translate}}</div>
            </div>
            <div class="columns is-flex m-0 pt-2">
              <div *ngIf="estimateItem.network_name === 'Bosch Car Service'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
                <img class="ml-1 my-1" src="../../../assets/img/bosch_car_service.jpg" alt="" style="width: 30px; height: 30px;">          
              </div>
              <div *ngIf="estimateItem.tse_quality_checked === '1'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
                <img class="ml-1 my-1" src="../../../assets/img/tse.png" alt="" style="width: 45px; height: 30px;">
              </div>
              <div *ngIf="estimateItem.exhaust_emission_service === 'yes'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
                <img class="ml-1 my-1" src="../../../assets/img/exhaust_emission.png" alt="" style="min-width: 55px; width: 55px; height: 30px;">
      
              </div>
            </div>
            <div *ngIf="estimateItem.about" class="font-14 pt-4">
              <p class="has-text-weight-semibold font-14 mb-0">About garage</p>
              <div *ngIf="estimateItem.about?.length < 50" class="comments">
                {{estimateItem.about}}
            </div>
            <div *ngIf="estimateItem.about?.length > 50 && estimateItem.showMore" class="comments">
                {{estimateItem.about?.slice(0, 50)}} <span class="is-clickable more" (click)="estimateItem.showMore = false">{{'DASHBOARD.MORE' | translate}}</span>
            </div>
            <div *ngIf="estimateItem.about?.length > 50 && !estimateItem.showMore" class="comments">
                {{estimateItem.about}} <span class="is-clickable more" (click)="estimateItem.showMore = true">{{'DASHBOARD.LESS' | translate}}</span>
            </div>
            </div>
            <div class="more-data px-1 pt-1 mt-3 is-clickable" (click)="garageGallery()">
              <p class="font-16 px-2 has-text-weight-semibold">More information</p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showGallery" class="has-text-centered">
        <div class="is-flex is-justify-content-center">
          <div class="garage-img">
            <img [src]="image" class="image">
          </div> 
        </div>
        <div class="rating-buttons pb-1 pt-4">
          <div class="button is-small is-primary" style="cursor: default;">
              <div class="star-rating is-flex is-align-items-center"><i class="fas fa-star is-size-7 mr-1"></i> <span class="pt-1 font-14">{{ estimateItem?.rating?.cum_rating }}</span></div>
          </div>
          <p class="has-text-weight-semibold pt-3 font-14">{{estimateItem.openTime}} - {{estimateItem.closeTime}}</p>
        </div>
      </div>
    </div> -->

    <div class="ml-4" >  
      <mat-tab-group>
        <mat-tab label="{{'COMMON.GARAGE_DETAILS' | translate}}">
          <app-location-tab [estimate_item]="estimateItem"></app-location-tab>
        </mat-tab>
        <mat-tab label="{{'COMMON.REVIEWS' | translate}}">
          <app-reviews-tab style="overflow: scroll;" [reviews]="estimateItem.reviews"></app-reviews-tab>
        </mat-tab>
      </mat-tab-group>
    </div>
    <!-- <div *ngIf="showGallery" class="my-4 tab-box">
      <div>
        <div class="font-18 has-text-weight-semibold">{{estimateItem.name}}</div>
        <div class="pt-2 pb-1 distance font-14" style="color: #4a4a4a;"><span> <strong >{{estimateItem.distance | number :
          '1.2-2'}} km</strong></span> - {{estimateItem.city}} / {{estimateItem.state}}
        </div>
        <div class="features-wrapper columns m-0 pb-1 is-multiline">
          <div class="features-inner pt-2" *ngFor="let item of estimateItem?.specialization index as i;">
            <div [ngClass]="i !=0 ? ' ' : ''" class="d-flex is-align-items-center specialization-data">
              <span class="font-14 px-3 py-1 m-0 pt-1" style="line-height: 1.2;">{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="features-wrapper columns is-multiline ml-1 pt-2 my-0">
          <div class="features-inner pb-1"
            *ngFor="let item of estimateItem?.amenities index as i;">
            <div [ngClass]="i !=0 ? '' : ''" class="d-flex is-align-items-center">
                <img class="image is-24x24" src="{{item.logo_url}}" />
              <span class="text-size-14 pl-1 pt-1">{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="columns is-flex m-0 pt-2">
          <div *ngIf="estimateItem.network_name === 'Bosch Car Service'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
            <img class="ml-1 my-1" src="../../../assets/img/bosch_car_service.jpg" alt="" style="width: 30px; height: 30px;">          
          </div>
          <div *ngIf="estimateItem.tse_quality_checked === '1'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
            <img class="ml-1 my-1" src="../../../assets/img/tse.png" alt="" style="width: 45px; height: 30px;">
          </div>
          <div *ngIf="estimateItem.exhaust_emission_service === 'yes'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
            <img class="ml-1 my-1" src="../../../assets/img/exhaust_emission.png" alt="" style="min-width: 55px; width: 55px; height: 30px;">
  
          </div>
        </div>
        <div *ngIf="estimateItem.about" class="font-14 pt-4 pl-1">
          <p class="has-text-weight-semibold font-14 mb-0">About garage</p>
          <div class="comments pt-1">
            {{estimateItem.about}}
        </div>
        </div>
      </div>
     
    <div class="image-gallery pl-1 pt-3">
        <div *ngFor="let media of estimateItem?.media;" class="image-item">
            <img [src]="media.img_url" alt="" class="photo-image">
        </div>
    </div>
    </div> -->
  </div>