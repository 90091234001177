import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/Constants/constants';
import { BookingServiceService } from 'src/app/services/booking-service.service';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit {

  constructor(private BookingService: BookingServiceService, private route: Router) { }
  userData: any = {}
  public showAddVechile: boolean = false
  isDataLoading: boolean = false

  vehcileList: any = []
  ngOnInit(): void {
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    if (userdata != undefined) {
      this.userData = JSON.parse(userdata)
    }
    this.fetchVehcile()
  }

  clickAddVechile() {
    this.showAddVechile = this.showAddVechile ? false : true
  }
  fetchVehcile() {
    this.isDataLoading = true

    this.BookingService.fetchVehicleByUser(this.userData.user_id).subscribe({
      error: (error) => {
        this.isDataLoading = false
        this.vehcileList = []
      }, 
      next: (res) => {
        this.vehcileList = res.vechicle
        this.isDataLoading = false
      },
    });
  }
  getStatusFromProfile(status: any) {
    if (status) {
      this.showAddVechile = false
      this.fetchVehcile()
    }
  }
  receiveFetchVehcile() {
    this.fetchVehcile()
  }
  onBackPressed(){
    this.route.navigate(['/dashboard'])
  }
}
