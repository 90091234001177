<div *ngIf="isLoading" class="spinner-wrapper" style="height: 300px;"><mat-spinner [diameter]="50"></mat-spinner></div>
<div *ngIf="!isLoading">
  <div class="buttons is-centered" *ngFor="let item of vechileName">
    <div *ngIf="!Mobileview" (click)="onClickCar(item)" class="vehicle is-flex is-align-items-center is-justify-content-space-between is-clickable">
      <div class="is-flex is-align-items-center">
        <div>
          <figure *ngIf ="item.img != ''" class="image is-48x48 ml-3 my-1 mr-2">
            <img [src]="item.img">
          </figure>
        </div>   
        <div class="ml-2 veh-font">{{item.vehicleFullName}}
        </div>
      </div>
      <div *ngIf="(selectedVehicle?.vechile_engine && (item.vechile_engine === selectedVehicle?.vechile_engine)) && (!userDetails.user_id)">
        <img class="tick-img mx-2" src="assets/svg/tick-green.svg" alt="">
      </div>
      <div *ngIf="(selectedVehicle?.vechicle_id && item.vechicle_id === selectedVehicle?.vechicle_id)">
        <img class="tick-img mx-2" src="assets/svg/tick-green.svg" alt="">
      </div>
    </div>
    <div *ngIf="Mobileview" (click)="onClickCar(item)" class="vehicle is-fullwidth is-flex is-flex-direction-column is-align-items-center is-clickable">
      <div *ngIf="(selectedVehicle?.vechicle_id && item.vechicle_id === selectedVehicle?.vechicle_id)">
        <img class="tick-img mx-2" src="assets/svg/tick-green.svg" alt="">
      </div>
      <div *ngIf="(selectedVehicle?.vechile_engine && (item.vechile_engine === selectedVehicle?.vechile_engine)) && (!userDetails.user_id)">
        <img class="tick-img mx-2" src="assets/svg/tick-green.svg" alt="">
      </div>
      <figure *ngIf ="item.img != ''" class="image is-48x48">
        <img [src]="item.img">
      </figure>
      <span class="mt-2 has-text-centered mob-veh-font" >{{item.vehicleFullName}}</span>
    </div>
</div> 
<div class="is-flex is-flex-wrap-wrap button-field">
  <button  class="button has-text-weight-semibold add-vehicle is-flex is-justify-content-center" (click)="onClickAdd()"> <i class="fas fa-plus"></i> &nbsp; {{'SERVICE.TEXT_38' | translate }}
  </button>
  <button [disabled]="!selectedVehicle" [ngClass]="selectedVehicle ? 'is-success' : 'button-disabled'" class="button has-text-weight-semibold continue-btn is-flex is-justify-content-center" (click)="clickContinue()"> {{'COMMON.CONTINUE' | translate}}
  </button>  
</div> 

</div>

