import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @Input() activeStepper: any ;
  mobileview: boolean =false
  constructor(private router: Router) { }

  ngOnInit(): void {
    // document.body.classList.add('inner-stepper');
    this.mobileview = window.innerWidth < 768;
    window.addEventListener('resize', () => {
      this.mobileview = window.innerWidth < 768;
    });
  }
  routePage(pageRoute:number){
    if(pageRoute < parseInt(this.activeStepper)){
      if(pageRoute == 1){
        this.router.navigate(['/service/services'])
        this.activeStepper = '1'
      }else if(pageRoute == 2){
        this.router.navigate(['/service/vehicle']);
        this.activeStepper = '2'
      }else if(pageRoute == 3){
        this.router.navigate(['/service/estimates'])
        this.activeStepper = '3'
      }else if(pageRoute == 4){
        this.router.navigate(['/service/book'])
        this.activeStepper = '4'
      }
    }
  }
}
