<div class="columns m-0" *ngIf="!quotation">
    <div class="column p-0 is-flex is-flex-wrap-wrap is-justify-content-end">            
        <div class="button-field is-flex is-flex-wrap-wrap" *ngIf="!fromMap">
            <button class="button has-text-weight-semibold ask-btn" (click)="messagePopup()">
                <img class="garage-facility pr-2" src="assets/svg/chat.svg" />
                {{"COMMON.ASK_QUESTION" | translate }}
            </button>
            <button class="button is-success  has-text-weight-semibold ask-btn" (click)="nextStepper(estimationList)">
                {{"COMMON.CREATE_AN_APPOINTMENT" | translate }}
             </button>
        </div>
    </div>          
</div>
<div class="columns m-0 itemized_estimate bg-topfield my-2">
    <div class="column is-6 pt-1 pb-0" *ngIf="!fromMap">
        <p class="color-arabama-green font-18 has-text-weight-semibold">Services</p>
        <div class="columns m-0 itemized_estimate active pt-2 is-hidden-mobile" >
            <p *ngFor="let item of itemList; let i = index; last as isLast" class="font-14">{{item.service_name}}<span *ngIf="!isLast">,&nbsp;</span></p>
        </div>
    </div>
    <div  class="column is-6 py-0 is-flex is-align-items-center is-justify-content-end">
        <div class="">
            <p *ngIf="total_discount != 0" class="has-text-weight-bold dis-field">Discount</p>
            <p *ngIf="estimate_item.original_price !== total" class="has-text-weight-bold dis-field">Original&nbsp;Price</p>
            <p class="has-text-weight-bold is-size-5-tablet has-text-right color-arabama-green">{{"COMMON.TOTAL_TXT" | translate }}&nbsp;{{"COMMON.PRICE_TXT" | translate }}</p>
        </div>
        <div class="has-text-right pl-5">
            <p *ngIf="total_discount != 0" class="has-text-weight-bold dis-field">₺&nbsp;{{ total_discount | number:'1.2-2':'tr'}}</p>
            <p class="has-text-weight-medium text-size-14 diagonal-strikethrough" *ngIf="estimate_item.original_price !== total">{{estimate_item.original_price}}</p>

            <p class="has-text-weight-bold is-size-5-tablet has-text-right color-arabama-green">₺&nbsp;{{total_price | number:'1.2-2':'tr' }}</p>
        </div>
    </div>
</div>
<div *ngIf="isQuoteDetailsVisible" class="columns m-0 itemized_estimate is-hidden" style="border-top: 3px solid #BBBBBB;">
    <div class="column is-6 is-flex is-align-items-center" *ngIf="!fromMap">
        <p class="price-text">{{"COMMON.PRICE_TEXT" | translate }}
        </p>
    </div>
    <div class="column is-flex is-align-items-center is-justify-content-end">
        <div class="">
            <p *ngIf="total_discount != 0" class="has-text-weight-bold dis-field">Discount</p>
            <p *ngIf="estimate_item.original_price !== total" class="has-text-weight-bold dis-field">Original&nbsp;Price</p>
            <p class="has-text-weight-bold is-size-5-tablet has-text-right color-arabama-green">{{"COMMON.TOTAL_TXT" | translate }}&nbsp;{{"COMMON.PRICE_TXT" | translate }}</p>
        </div>
        <div class="has-text-right pl-5">
            <p *ngIf="total_discount != 0" class="has-text-weight-bold dis-field">₺&nbsp;{{ total_discount | number:'1.2-2':'tr'}}</p>
            <p class="has-text-weight-medium text-size-14 diagonal-strikethrough" *ngIf="estimate_item.original_price !== total">{{estimate_item.original_price}}</p>

            <p class="has-text-weight-bold is-size-5-tablet has-text-right color-arabama-green">₺&nbsp;{{total_price | number:'1.2-2':'tr' }}</p>
        </div>
    </div>
</div>
<!-- is-flex is-flex-direction-column is-justify-content-space-between -->
<div class="pb-3 is-flex is-flex-direction-column is-justify-content-space-between" style="background-color: #ffffff; border-radius: 10px;" [ngClass]="fromMap ? '' : 'fromMap'">
    <div>
        <div class="column py-0 is-flex is-justify-content-end" *ngIf="!fromMap">
            <p
            class="font-14 has-text-weight-semibold less-details color-arabama-green is-clickable"
            (click)="toggleQuoteDetails()">{{ isQuoteDetailsVisible ? 'Less Details' : 'View Details' }}
          </p>
        </div>
        <div *ngIf="isQuoteDetailsVisible" class="quote_details">
            <div class="columns m-0" *ngIf="!fromMap" >
                <div class="column pb-1 is-6">
                 <p class="font-14 has-text-weight-semibold color-arabama-green">{{'COMMON.ITEMIZED_ESTIMATE' | translate}}
                 </p>
                </div>
                <div class="column pb-1 is-2 has-text-centered">
                 <p class="font-14 has-text-weight-semibold">Quantity
                 </p>
                </div>
                <div class="column pb-1 is-2 has-text-centered">
                 <p class="font-14 has-text-weight-semibold">Unit Price (₺)
                 </p>
                </div>
                <div class="column pb-1 is-2 has-text-centered">
                 <p class="font-14 has-text-weight-semibold">Total Price (₺)
                 </p>
                </div>
             </div>
             <div class="itemized_estimate active py-0 is-hidden-mobile" *ngFor="let item of itemList; let i = index"  style="border-top: 2px solid #BBBBBB;">
                 <div class="py-1 is-flex is-justify-content-space-between is-align-items-center" style="background-color: #f6f6f6;">
                    <p class="font-14 px-3 has-text-weight-semibold">{{item.service_name}}</p>
                 <p class="pr-3 is-clickable" (click)="toggleItemDetails(i)">
                    <img *ngIf="itemDetailsVisible[i]" src="../../../assets/svg/up-arrow-green.svg" width="20px" alt="">
                    <img *ngIf="!itemDetailsVisible[i]" src="../../../assets/svg/down-arrow-green.svg" width="20px" alt="">
                </p>
                 </div>
                 <div *ngIf="itemDetailsVisible[i]" class="item_details">
                     <div  class="columns m-0" *ngFor="let add_item of item.offer_item">
                         <div class="column is-6 pt-2 pb-0">
                             <p class="font-14"><span *ngIf="add_item.item_code">{{add_item.item_code}} -</span>{{add_item.item_name}} - {{add_item.item_brand}}</p>
                            </div>
                            <div class="column is-2 pt-2 pb-0 has-text-centered">
                             <p class="font-14 has-text-weight-semibold">{{add_item.item_quantity}}
                             </p>
                            </div>
                            <div class="column is-2 pt-2 pb-0">
                             <p class="font-14 has-text-weight-semibold has-text-right">{{add_item.item_price | number:'1.2-2':'tr'}}
                             </p>
                            </div>
                            <div class="column is-2 pt-2 pb-0">
                             <p class="font-14 has-text-weight-semibold has-text-right">{{add_item.item_totalprice | number:'1.2-2':'tr'}}
                             </p>
                            </div>
                     </div>
                     <div class="columns m-0" *ngIf="item.labour_price">
                         <div class="column is-6 pt-2 pb-0">
                             <p class="font-14"><span *ngIf="item.labour_code">{{item.labour_code}} -</span>Iscilik</p>
                            </div>
                            <div class="column is-2 pt-2 pb-0 has-text-centered">
                             <p class="font-14 has-text-weight-semibold">{{item.labour_quantity}}
                             </p>
                            </div>
                            <div class="column is-2 pt-2 pb-0">
                             <p class="font-14 has-text-weight-semibold has-text-right">{{item.labour_price | number:'1.2-2':'tr'}}
                             </p>
                            </div>
                            <div class="column is-2 pt-2 pb-0">
                             <p class="font-14 has-text-weight-semibold has-text-right">{{item.labour_totalprice | number:'1.2-2':'tr'}}
                             </p>
                            </div>
                     </div>
                     <div *ngIf="item.service_comment" class="column is-6 py-1 is-flex is-align-items-flex-start" >
                        <img class="mr-2 is-clickable notes-icon" src="../../../assets/svg/notes.svg" alt="">
                        <div *ngIf="item.service_comment.length < 35" class="comments font-14">
                            {{item.service_comment}}
                        </div>
                        <div *ngIf="item.service_comment.length > 35 && item.showMore" class="comments font-14">
                            {{item.service_comment.slice(0, 35)}} <span class="is-clickable more font-14" (click)="item.showMore = false">{{'DASHBOARD.MORE' | translate}}</span>
                        </div>
                        <div *ngIf="item.service_comment.length > 35 && !item.showMore" class="comments font-14">
                            {{item.service_comment}} <span class="is-clickable more font-14" (click)="item.showMore = true">{{'DASHBOARD.LESS' | translate}}</span>
                        </div>
                    </div>
                 </div>
             </div>
        </div>
    </div>

    



        <!-- Mobile layout --> 
        <div class="is-hidden-desktop is-hidden-tablet" >
        <div *ngIf="estimate_item.invoice_quote.estimation_type == 'Premium'">
            <div class="columns m-0 pb-5 itemized_estimate active pt-0 estimate-quote-mobile" *ngFor="let item of itemList; let i = index">
                <mat-expansion-panel [expanded]="currentStep == i" (opened)="setStep(i)" (closed)="currentStep != i" class="width-95">
                    <mat-expansion-panel-header class="pb-0">
                        <mat-panel-title class="is-justify-content-space-between">
                            <div class="columns is-flex width-95 is-align-items-center mobile-panel-head">
                                <div class="column min-width-50">
                                    <p class="service-name service-name"><span class="pr-2">{{i+1}}.</span>{{item.service_name}}</p>
                                </div>
                                <div class="column min-width-50"> 
                                    <div [ngClass]="item.premium_checked?'catagory-price is-flex is-justify-content-end is-align-items-center active':'catagory-price is-flex is-justify-content-end is-align-items-center'">
                                        <!-- <p class="has-text-weight-medium text-size-12 mr-3 diagonal-strikethrough" *ngIf="item.meta.pre_base_price_currency !== item.original_price">₺&nbsp;{{item.original_price |  number:'1.2-2':'tr'}}</p> -->
                                        <p class="has-text-weight-bold text-size-20 mr-3">₺&nbsp;{{item.original_price |  number:'1.2-2':'tr'}}</p>

                                    </div> 
                                </div>
                            
                            </div> 
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="columns is-flex">
                        <div class="column">
                            <div class="description-wrapper">
                                <table class="table is-narrow w-100">
                                    <tbody>
                                        <tr *ngIf="item.spare_price">
                                            <td>{{'COMMON.SPARE_PARTS' | translate}}</td>
                                        </tr>
                                        <tr *ngIf="item.lubricant_price">
                                            <td>{{'COMMON.OILS_AND_LIQUIDS' | translate}}</td>
                                        </tr>
                                        <tr *ngFor="let add_item of item.offer_item">
                                            <td *ngIf="add_item">{{add_item.item_name}}</td>
                                        </tr>
                                        <tr *ngIf="item.labour_price">
                                            <td>{{'COMMON.WORKMANSHIP' | translate}}</td>
                                        </tr>
                                      
                                    </tbody>
                                </table>
                            </div>
                        </div> 
                       
                        <div class="column" (click)=" !quotation? priceChange('premium',item) : '' " style="padding: 0.75rem 0px;">
                            <div [ngClass]="item.premium_checked?'catagory-price-wrapper active':'catagory-price-wrapper'">
                                <table class="table is-narrow w-100">
                        
                                    <tbody>
                                        <tr *ngIf="item.spare_price">
                                            <td>{{item.spare_brand | titlecase}}</td>
                                            <td class="has-text-right has-text-weight-bold">{{item.spare_price}}</td>
                                        </tr>
                                        <tr *ngIf="item.lubricant_price">
                                            <td>{{item.lubricant_brand | titlecase}}</td>
                                            <td class="has-text-right has-text-weight-bold">{{item.lubricant_price}}</td>
                                        </tr>
                                        <tr *ngFor="let add_item of item.offer_item">
                                            <td>{{add_item.item_brand}} </td>
                                            <td class="has-text-right has-text-weight-bold">{{add_item.item_price | turkeyCurrency}}</td>
                                        </tr>
                                        <tr *ngIf="item.labour_price">
                                            <td> </td>
                                            <td class="has-text-right has-text-weight-bold">{{item.labour_price}}</td>
                                        </tr>     
                                        <!-- <tr>
                                            <td> </td>
                                            <td *ngIf="item.discount_unit == null" class="has-text-right has-text-weight-bold">
                                                <span *ngIf="item.discounts && item.discounts[0]?.unit === 'amount'">-&nbsp;{{(item.discounts[0] ? item.discounts[0]?.discount_value : 0) | turkeyCurrency}} </span>                                              
                                               <span *ngIf="item.discounts && item.discounts[0]?.unit === 'percentage'">{{item.discounts[0] ? item.discounts[0]?.discount_value : 0}}&nbsp;%</span> 
                                               <span *ngIf="!item.discounts[0]?.unit">-</span>
                                            </td>
                                            <td *ngIf="item.discount_unit != null" class="has-text-right has-text-weight-bold">
                                                <span *ngIf="item.discount_unit === 'amount'">-&nbsp;{{(item.discount_value ? item.discount_value : 0) | turkeyCurrency}}</span> 
                                                <span *ngIf="item.discount_unit === 'percentage'">{{item.discount_value ? item.discount_value : 0}}&nbsp;%</span> 
                                                <span *ngIf="!item.discounts[0]?.unit && !item.discount_unit">-</span>
                                            </td>
                                        </tr>                               -->
                                    </tbody>
                                </table>
                            </div>
                        </div> 
                    </div> 
                </mat-expansion-panel> 
            </div>
        </div>
        </div> 
    <!-- Mobile layout -->

        <div *ngIf="isQuoteDetailsVisible" class="columns m-0 itemized_estimate" style="border-top: 3px solid #BBBBBB;">
            <div class="column is-6 is-flex is-align-items-center" *ngIf="!fromMap">
                <p class="price-text">{{"COMMON.PRICE_TEXT" | translate }}
                </p>
            </div>
            <div class="column is-flex is-align-items-center is-justify-content-end">
                <div class="">
                    <p *ngIf="total_discount != 0" class="has-text-weight-bold dis-field">Discount</p>
                    <p *ngIf="estimate_item.original_price !== total" class="has-text-weight-bold dis-field">Original&nbsp;Price</p>
                    <p class="has-text-weight-bold is-size-5-tablet has-text-right color-arabama-green">{{"COMMON.TOTAL_TXT" | translate }}&nbsp;{{"COMMON.PRICE_TXT" | translate }}</p>
                </div>
                <div class="pl-5 has-text-right">
                    <p *ngIf="total_discount != 0" class="has-text-weight-bold dis-field">₺&nbsp;{{ total_discount | number:'1.2-2':'tr'}}</p>
                    <p class="has-text-weight-medium text-size-14 diagonal-strikethrough" *ngIf="estimate_item.original_price !== total">{{estimate_item.original_price}}</p>

                    <p class="has-text-weight-bold is-size-5-tablet has-text-right color-arabama-green">₺&nbsp;{{total_price | number:'1.2-2':'tr' }}</p>
                </div>
            </div>
        </div>
</div>
<app-message *ngIf="show_message" [estimation]="estimate_item" [quoteId]="quoteId" (hideMessage)="hideMessagePopup($event)"></app-message>
<div *ngIf="show_popup" class="modal fade is-active">
    <div class="modal-card">
        <div class="modal-card-body py-5">
          <div class="has-text-centered pb-4 has-text-weight-semibold">
            {{'DASHBOARD.WOULD_YOU_LIKE_TO_CHAT_WITH_GARAGE' | translate}} <br> {{'DASHBOARD.PLEASE_SAVE_THE_ESTIMATION' | translate}}
          </div>
          <div class="is-flex is-justify-content-center">
            <div class="button btn_cancel has-text-weight-semibold m-2" (click)="show_popup = false">
              {{'DASHBOARD.CANCEL' | translate}}
            </div>
            <div class="button btn_save_estimate has-text-weight-semibold m-2" (click)="saveEstimate()">
              {{'DASHBOARD.SAVE' | translate}}
            </div>
          </div>
        </div>
    </div>
  </div>