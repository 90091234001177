import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Constants } from 'src/app/Constants/constants';
import { ViewDetailsComponent } from '../view-details/view-details.component';
import { Utils } from 'src/app/common/utils';

@Component({
  selector: 'app-view-garage',
  templateUrl: './view-garage.component.html',
  styleUrls: ['./view-garage.component.scss']
})
export class ViewGarageComponent implements OnInit {

  image: any = ''
  estimateItem: any ;
  quotation: any;
  amenitiesLength: number = 3;
  defaultAmenitiesCount: number = 3;  
  specializationLength: number = 3;
  defaultSpecializationCount: number = 3; 

  constructor(public dialogRef: MatDialogRef<ViewDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private route: Router, private utils:Utils,) { }

  ngOnInit(): void {
    if(this.utils.isMobile()){
      this.defaultAmenitiesCount = 2
      this.amenitiesLength = 2 
      this.defaultSpecializationCount = 2
      this.specializationLength = 2 
    }
    if(this.data != null){
      this.estimateItem = this.data.dataItem
      this.quotation = this.data.quotation
      if(this.data.dataItem.logo_url != null){
        this.image = this.data.dataItem.logo_url
      }else{
        this.image = 'assets/img/no-image.png';
      }
    }
  }

  clickClose(){
    this.dialogRef.close()
    if(this.quotation){
      sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
              sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
              sessionStorage.removeItem(Constants.APP.QUOTE_ID)
              localStorage.removeItem(Constants.APP.SERVICE_LIST)
      this.route.navigate(['/dashboard'])

    }
  }

}
