<div *ngIf="isDataLoading" class="spinner-wrapper" style="height: 300px;"><mat-spinner [diameter]="50"></mat-spinner></div>
<div *ngIf="!isDataLoading" class="content-inner colums is-flex is-justify-content-center">
  <div class="column is-12-mobile is-9-desktop pr-0">
    <div>
      <h4 class="has-text-weight-bold is-hidden"> {{ 'SERVICE.TEXT_19' | translate}}
        <img class="is-hidden" (click)="previousStep()" src="assets/svg/arrow-left-circle.svg" style="float: right;" />
      </h4>
      <div *ngIf="!vehicleData" class="pt-2" style="overflow: hidden ;">
        <div class="is-flex">
        <div class="column p-0 field" id="vehicleId">
          <div class="control">
            <app-filter-dropdown [editVehicle]="vehEdit" [placeholder]="'SERVICE.TEXT_33' | translate" [optionList]="vehicleModelYears" [defaultValue]="vehicleYearControl.value" [disable]="disableField.year" (optionChanged)="modelYearChanged($event)"></app-filter-dropdown>
          </div>
        </div>
         <div class="icon star-icon is-size-4 has-text-danger"
          >*</div>
        </div>

      <div class="is-flex">
        <div class="column p-0 field" id="vehicleBrandId">
          <div class="control">
            <app-filter-dropdown [editVehicle]="vehEdit" [placeholder]="'SERVICE.TEXT_13' | translate" [optionList]="vehicleBrandName" [defaultValue]="vehicleBrandControl.value?.make ? vehicleBrandControl.value?.make : ''" [disable]="disableField.make" (optionChanged)="makeChanged($event)"></app-filter-dropdown>
          </div>
        </div>
        <div class="icon star-icon is-size-4 has-text-danger"
              >*</div>
      </div>
      <div class="is-flex">
        <div class="column p-0 field" id="vehicleModelId">
          <div class="control">
            <app-filter-dropdown [editVehicle]="vehEdit" [placeholder]="'SERVICE.TEXT_14' | translate" [optionList]="vehicleModelName" [defaultValue]="vehicleModelControl.value?.name ? vehicleModelControl.value?.name : ''" [disable]="disableField.model" (optionChanged)="modelChanged($event)"></app-filter-dropdown>
          </div>
        </div>
        <div class="icon star-icon is-size-4 has-text-danger"
              >*</div>
      </div>
      <div class="is-flex">
        <div class="column p-0 field" id="vehicleEngineId">
          <div class="control">
            <app-filter-dropdown [editVehicle]="vehEdit" [placeholder]="'SERVICE.TEXT_15' | translate" [optionList]="vehicleEngineName" [defaultValue]="vehicleEngineControl.value?.name ? vehicleEngineControl.value?.name : ''" [disable]="disableField.engine" (optionChanged)="engineChanged($event)"></app-filter-dropdown>
          </div>
        </div>
        <div class="icon star-icon is-size-4 has-text-danger"
              >*</div>
      </div>
      <div class="is-flex">
        <div class="column p-0 field" id="vehicleTransmissionId">
          <div class="control">
            <app-filter-dropdown [editVehicle]="vehEdit" [placeholder]="'SERVICE.TEXT_16' | translate" [optionList]="vehicleTransmissionName" [defaultValue]="vehicleTransmissionControl.value" [disable]="disableField.transmission" (optionChanged)="transmissionChanged($event)"></app-filter-dropdown>
          </div>
        </div>
        <div class="icon star-icon is-size-4 has-text-danger"
              >*</div>
      </div>

      <div class="is-flex">
        <div class="column p-0 field" id="fuelTypeId">
          <div class="control">
            <app-filter-dropdown [editVehicle]="vehEdit" [placeholder]="'SERVICE.FUEL_TYPE' | translate" [optionList]="vehicleFuelName" [defaultValue]="fuelTypeControl.value" [disable]="disableField.fuelType" (optionChanged)="fuelTypeChanged($event)"></app-filter-dropdown>
          </div>
        </div>
        <div class="icon star-icon is-size-4 has-text-danger"
              >*</div>
      </div>

      <div class="is-flex">
        <div class="column p-0 field" id="vehicleMileageId">
          <div class="control has-icons-right">
            <input id="mileage" class="input pl-3" type="text" [formControl]="mileageControl" (focus)="onFocus('mileage')" (blur)="onBlur('mileage')"
                (ngModelChange)="onInputMileageChange($event)"
              (input)="numberOnly($event)" min=100 maxlength="7" [ngClass]="disableField.mileage? 'disable':''" autocomplete="off">
              <label class="floating-label" [class.active]="isMileageActive" for="mileage">{{ 'SERVICE.TEXT_17' | translate}}</label>
          </div>
        </div>
        <div class="icon star-icon is-size-4 has-text-danger"
              >*</div>
      </div>
      <div class="is-flex">
        <div class="column p-0 field" id="vehicleCarPlateId">
          <div class="control">
            <input id="plate" class="input pl-3" type="text" [formControl]="carPlateControl" (focus)="onFocus('plate')" (blur)="onBlur('plate')"
               min=100 maxlength="20" autocomplete="off">
              <label class="floating-label" [class.active]="isPlateActive" for="plate">{{ 'SERVICE.TEXT_43' | translate}}</label>
          </div>
        </div>
        <div class="icon star-icon is-size-4 has-text-danger"
              ></div>
      </div>
      <div class="is-flex">
        <div class="column p-0 field" id="vehicleChasisNoId">
          <div class="control">
            <input id="chasis" class="input pl-3" type="text" [formControl]="chasisNoControl" (focus)="onFocus('chasis')" (blur)="onBlur('chasis')"
               min=100 maxlength="20" autocomplete="off">
              <label class="floating-label" [class.active]="isChasisActive" for="chasis">{{ 'SERVICE.TEXT_44' | translate}}</label>
          </div>
        </div>
        <div class="icon star-icon is-size-4 has-text-danger"
              ></div>
      </div>

        <div class="field is-flex continue-btn" *ngIf="!showButton">
          <button [disabled]="!btnDisabled" [ngClass]="btnDisabled ? 'is-success' : 'button-disabled'" class="button cont-btn has-text-weight-semibold" (click)="nextStepper()">{{ 'COMMON.CONTINUE' | translate}}</button>
        </div>
        <div  class="field is-flex continue-btn" *ngIf="showButton">
          <button [disabled]="!btnDisabled" [ngClass]="btnDisabled ? 'is-success' : 'button-disabled'" class="button cont-btn has-text-weight-semibold" (click)="nextStepper()">{{ 'COMMON.CONTINUE' | translate}}</button>
        </div>
      </div>
      <div class="mx-2" *ngIf="vehicleData">
        <app-extisting-vehicle (onClickAddVehcile)="onClickAddVehcile($event)"></app-extisting-vehicle>
      </div>
    </div>
  </div>
</div>