<div>
    <agm-map  
             [latitude]="lat" [longitude]="lng" 
             [zoom]="zoom" [disableDefaultUI]="false"
        [zoomControl]="true">
        <agm-direction 
        [origin]="origin" 
        [destination]="destination"
      >
      </agm-direction>
    </agm-map>
</div>