import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/Constants/constants';
import { Utils } from 'src/app/common/utils';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { PassDataService } from 'src/app/services/pass-data.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit,AfterViewInit {

  constructor(private BookingService:BookingServiceService, private passService : PassDataService, private Utils: Utils, private socketService: SocketService, public sharedService: SharedServiceService, private route: ActivatedRoute) { }
  userDetails:any = {}
  estimateList: any = []
  bookedEstimateList:any = []
  vehicleList: any = []
  expiredBookedList: any = []
  cancelledEstimateList: any = []
  expcancellist:any=[]
  topPosToStartShowing = 100;
  showButton: boolean =true;
  estimatecurrIndexAppoinment:any = 0;
  estimatecurrIndexEstimation:any = 0;
  estimatecurrIndexExpired:any = 0;
  isLoading: boolean = false
  scrollPositionJobId: number | undefined;
  estimate: boolean = false
  appointment: boolean = false
  expired: boolean = false
  isSearchField: boolean = false
  dropdown_list: any =[];
  selected_option: any;
  dropList: boolean = true;
  isMobile: boolean = false
  showEst: boolean = false;
  showApp: boolean = false;

  private positionSubscription!: Subscription;
 
  ngOnInit(): void {
    sessionStorage.removeItem(Constants.APP.QUOTE_ID)
    document.body.classList.add('inner-class');
    document.body.classList.remove('sign-in');
    document.body.classList.remove('inner-stepper');
    this.isLoading = true
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    if (userdata != undefined) {
      this.userDetails = JSON.parse(userdata)
      this.fetchVehcile()
    }
    if(history.state?.notification_data){
      this.socketService.emit('adminNotification',history.state.notification_data)
    }
    this.positionSubscription = this.sharedService.position$.subscribe(position => {
      if(position.notification_title === 'Job Confirmed'){        
        this.scrollPositionJobId = position.job_id
        this.fetchVehcile()
      }
    });
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768;
    });
  }

  ngAfterViewInit(){
      localStorage.removeItem(Constants.APP.APPLIED_GARAGES_ID)
      localStorage.removeItem(Constants.APP.SERVICE_LIST)
      localStorage.removeItem("address")
      localStorage.removeItem("zipcode")
      // sessionStorage.removeItem(Constants.APP.SESSION_VECHICLE_LIST)
      sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
      sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
      sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
      sessionStorage.removeItem(Constants.APP.QUOTE_ID)
      this.sharedService.clearData()
    
  }
 
  fetchAllAppointment(){
    this.BookingService.fetchAppointment(this.userDetails.user_id).subscribe({
      error: (error) => {
        this.isLoading = false
      },
      next: (res) => {
        if(res.statusCode == 1){
          let data = res.data 
          this.estimateList = []
          this.bookedEstimateList = []
          this.expiredBookedList = []
          this.cancelledEstimateList = []  
          this.expcancellist = []
          if(data){
          data?.forEach((element:any) => {
            if(element.status === 'estimate' || element.status === 'Awaiting'){
              this.vehicleList.forEach((vehcileElement: any) => {
                if(element.vechicle_id == vehcileElement.vechicle_id){
                  let fullName = vehcileElement.start_production_year + " "+ vehcileElement.make_name+" "+ vehcileElement.model_name
                  element.vehcileName = fullName
                  let diffDate = moment(moment()).diff(element.date_update,'days')
                  if(diffDate < 30){
                    this.estimateList.push(element)      
                  }
                }
              });
            }else if(element.status === 'booked' || element.status === 'confirmed'){
              let isFoundVehcile = false

              element.original_price = 0;
              element?.invoice_items?.items?.forEach((item: any) => {     
                element.original_price += item?.base_price
        
                if(item.discount_value != null){
                  element.discountedValue = item?.discount_value
                  element.discountedType = item?.discount_type
                }
                else if(item.discount_value == null){
                  element.discountedValue = element.invoice_quote?.bestDiscountPrice
                  element.discountedType = element.invoice_quote?.bestDiscountType
                }
              })  
              element.total = element.original_price;   
              if(element?.my_dis_status == 1){                
                element.total = element.total - element.discountedValue           
              }
              if(element?.custom_dis_status == 1){                
                element.total = element.total - element.custom_dis_value            
              }
              
              let appDateApp1 = '',appDateApp2 ='', isApp2Reached : boolean= false;
              if(element.appointment2 != null && element.appointment2 != ''){
                appDateApp1 = element.appointment2
              }else{
                appDateApp1 = element.appointment1
              }
              let todayDate = moment()
              let isApp1Reached = moment(appDateApp1, 'DD/MM/YYYY HH:mm').isAfter(todayDate);
              isApp2Reached = moment(appDateApp2, 'DD/MM/YYYY HH:mm').isAfter(todayDate);
              this.vehicleList.forEach((vehcileElement: any) => {
                if(element.vechicle_id == vehcileElement.vechicle_id){
                  let fullName = vehcileElement.start_production_year + " "+ vehcileElement.make_name+" "+ vehcileElement.model_name
                  element.vehcileName = fullName
                  isFoundVehcile = true
                  if(isApp1Reached){
                    this.bookedEstimateList.push(element)
                  }else if(element.status == 'confirmed'){
                    this.bookedEstimateList.push(element)
                  }
                  else{
                    element.status = 'expired'
                    let diffDate = moment(moment()).diff(element.date_update,'days')
                    if(diffDate <= 30){
                      this.expiredBookedList.push(element)
                    }
                  }
                }
              });
              if(!isFoundVehcile){
                if(!isApp1Reached){
                  this.bookedEstimateList.push(element)
                }
             
              }
            }
            else if(element.status === 'expired'){
              let isFoundVehcile = false
              element.original_price = 0;
              element?.invoice_items?.items?.forEach((item: any) => {     
                element.original_price += item?.base_price
        
                if(item.discount_value != null){
                  element.discountedValue = item.discount_value
                  element.discountedType = item.discount_type
                }
                else if(item.discount_value == null){
                  element.discountedValue = element?.invoice_quote?.bestDiscountPrice
                  element.discountedType = element?.invoice_quote?.bestDiscountType
                }
              })  
              element.total = element.original_price;   
              if(element?.my_dis_status == 1){                
                element.total = element.total - element.discountedValue           
              }
              if(element?.custom_dis_status == 1){                
                element.total = element.total - element.custom_dis_value            
              }
              this.vehicleList?.forEach((vehcileElement: any) => {
                if(element.vechicle_id == vehcileElement.vechicle_id){
                  let fullName = vehcileElement.start_production_year + " "+ vehcileElement.make_name+" "+ vehcileElement.model_name
                  element.vehcileName = fullName
                  isFoundVehcile = true
                  let diffDate = moment(moment()).diff(element.date_update,'days')
                    if(diffDate <= 30){
                      this.expiredBookedList.push(element)
                    }
                }
              });
              if(!isFoundVehcile){
                this.expiredBookedList.push(element)
              }
            }
            //
            else if(element.status === 'cancelled'){
              let isFoundVehcile = false
              element.original_price = 0;
              element?.invoice_items?.items?.forEach((item: any) => {     
                element.original_price += item?.base_price
        
                if(item.discount_value != null){
                  element.discountedValue = item.discount_value
                  element.discountedType = item.discount_type
                }
                else if(item.discount_value == null){
                  element.discountedValue = element?.invoice_quote?.bestDiscountPrice
                  element.discountedType = element?.invoice_quote?.bestDiscountType
                }
              })  
              element.total = element.original_price;   
              if(element?.my_dis_status == 1){                
                element.total = element.total - element.discountedValue           
              }
              if(element?.custom_dis_status == 1){                
                element.total = element.total - element.custom_dis_value            
              }
              this.vehicleList?.forEach((vehcileElement: any) => {
                if(element.vechicle_id == vehcileElement.vechicle_id){
                  let fullName = vehcileElement.start_production_year + " "+ vehcileElement.make_name+" "+ vehcileElement.model_name
                  element.vehcileName = fullName
                  isFoundVehcile = true
                  this.cancelledEstimateList.push(element)
                }
              });
              if(!isFoundVehcile){
                this.cancelledEstimateList.push(element)
              }
            }
          });
        }
          this.expcancellist.push(this.expiredBookedList)
          this.expcancellist.push(this.cancelledEstimateList)
          this.expcancellist = this.expcancellist.flat()
         
        }
        this.dropdown_list = []
        if (this.bookedEstimateList.length > 0) {
          this.dropdown_list.push({ name: "DASHBOARD.MY_APPOINTMENTS", id: "appointment" })
         let data  = this.bookedEstimateList.slice().sort((a:any,b:any)=>{
            const dateA:any = moment(a.appointment1, 'DD/MM/YYYY HH:mm');
            const dateB:any = moment(b.appointment1, 'DD/MM/YYYY HH:mm');
            return dateA.diff(dateB);
          })
          this.bookedEstimateList = data
       
        } if (this.estimateList.length > 0) {
          this.dropdown_list.push({ name: "DASHBOARD.OFFERS", id: "estimation" })

        } if (this.expcancellist.length > 0) {
          this.dropdown_list.push({ name: "DASHBOARD.OLD_REQUESTS", id: "expired" })

        }       
        this.selected_option = this.dropdown_list[0]?.name
        if(this.dropdown_list?.length == 0){
          this.dropList =false
        }
        this.isLoading = false
      },
    });
  }

  fetchVehcile(){
    this.BookingService.fetchVehicleByUser(this.userDetails.user_id).subscribe({
      error: (error) => {
        this.fetchAllAppointment()
      },
      next: (res) => {
        if(res.statusCode == 1){
          this.vehicleList = res.vechicle
          this.fetchAllAppointment()

        }
      },
    });
  }

  onClickOutFunction(){
      this.passService.setOutsideClickListener();
  }
  updateFromChild(event:any){
    if(event === '0'){
      this.isLoading = true
    }else if(event == 1){
      this.isLoading = false
      this.fetchVehcile()      
    }else{
      this.isLoading = false
    }
  }
 @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const ClickedElement = event.target as HTMLElement
    const modalContainer = ClickedElement.closest('.modal-card');
    if (!modalContainer) {
      this.closeBookedPopup()
    } 
  }
@HostListener('window:scroll')
checkScroll(){
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if(scrollPosition >= this.topPosToStartShowing){
      this.showButton=false;
    }else{
      this.showButton=true;
    }
}


    goToTop(){
          window.scroll({
            top:0,
            left:0,
            behavior:'smooth'
          });
        }
  
      scrollToElementById(id: string) {
        if(id == 'estimation'){
          this.estimate= true
          this.appointment=false
          this.expired=false
        }else if( id == 'appointment'){
          this.appointment=true
          this.estimate= false
          this.expired=false
        }
        else{
          this.expired=true
          this.estimate= false
          this.appointment=false
        }
        const element = this.__getElementById(id); 
        const element1=this.scrollToElement(element);
        return element1  
      }

      scrollError(id:any){
        if (id=='appointment'){
          this.Utils.openErrorSnackBar('Randevu yok');
        }else if(id == 'estimation'){
          this.Utils.openErrorSnackBar('tahmin yok');
        }else{
          this.Utils.openErrorSnackBar('süresi dolmamış');
        }
      }
    
      private __getElementById(id: string): HTMLElement  {
        const element = <HTMLElement>document.querySelector(`#${id}`);
        return element;
      }
    
      scrollToElement(element: HTMLElement) {
        
        if (element) {
          const offset = 175; // Adjust this value as needed
          const rect = element.getBoundingClientRect();
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const top = rect.top + scrollTop - offset;
          window.scrollTo({ top, behavior: 'smooth' });
          return true;
        }
        return false;
      }
   
      closeBookedPopup(){
        this.sharedService.setBookedPopup(false)
      }

   serviceChanged(event:any){    
       if(event.id == 'appointment'){
        if (this.bookedEstimateList.length > 0) {
          this.scrollToElementById('appointment');
        } else {
          this.scrollError('appointment');
        }
      }
      else if(event.id == 'estimation'){
        if (this.estimateList.length > 0) {
          this.scrollToElementById('estimation');
        } else {
          this.scrollError('estimation');
        }
      }
      else if (event.id == 'expired'){
        if (this.expcancellist.length > 0) {
          this.scrollToElementById('expired');
        } else {
          this.scrollError('expired');
        }
      }

  }

  searchField(event: any){
    this.isSearchField = event;
    
  }
  ngOnDestroy() {
    this.positionSubscription.unsubscribe();
  }

    
}



