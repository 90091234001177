<div class="columns m-0 sec-title-wrapper">
    <div class="column is-8 px-0" style="align-items: center;display: flex;">
        <button class="button back_button" (click)="onBackPressed()">
          <span class="icon is-small is-left" style="height: 60px; color: #fff;">
            <i class="fas fa-arrow-left"></i>
          </span>
        </button>
        <span class="has-text-weight-bold ml-4 is-size-6-mobile	" style="font-size: 1.3rem;">
            {{'PROFILE.REVIEWS' | translate}}
        </span>
      </div>
</div>
<div class="container-wrapper px-0 pb-4">
   <div class="review-box mt-0 w-bg-with-border-radius is-justify-content-space-around pb-0">
    <div *ngIf="!reviews" class="pt-4">
        <div class="colums m-5">
            <div class="column is-12 has-text-centered p-0">
                <div class="no-envents is-flex is-flex-direction-column is-justify-content-center">
                    <div class="mb-2 pt-2 is-flex-tablet is-justify-content-start is-align-items-center px-3">
                        <div class="appoiment-img is-flex is-justify-content-center">
                            <img class="image" src="../../../assets/svg/reviews.svg">
                        </div>
                        <p class="text-size-20 my-3 mx-6 content" style="color:#a3a3a3">{{'COMMON.NO_REVIEWS' | translate}}</p>

                    </div>
                   
                </div>
            </div>
        </div>
    </div>
        <div *ngIf="isDataLoading" class="spinner-wrapper" style="height: 435px;">
            <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div *ngIf="reviews" class="ml-3">
            <div *ngFor="let item of reviews;index as i">
                <app-reviews-line-item [reviewItem]="item"></app-reviews-line-item>
            </div>
        </div>
   </div>
</div>
