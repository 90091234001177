<script> bulmaSteps.attach(); </script>
<div class="columns m-0 has-text-centered">
  <ul *ngIf="!mobileview"  class="progressbar w-100 d-center">
    <li [ngClass]="activeStepper >= 1? 'active' : '' "> <span>{{'STEEPER.STEP_1' | translate}}</span> </li>
    <li [ngClass]="activeStepper >= 2? 'active' : '' "><span>{{'STEEPER.STEP_2' | translate}}</span></li>
    <li [ngClass]="activeStepper >= 3? 'active' : '' "><span>{{'STEEPER.STEP_3' | translate}}</span></li>
    <li [ngClass]="activeStepper >= 4? 'active' : '' "><span>{{'STEEPER.STEP_4' | translate}}</span></li>
    <li [ngClass]="activeStepper >= 5? 'active' : '' "><span>{{'STEEPER.STEP_5' | translate}}</span></li>
  </ul>
  <ul *ngIf="mobileview"  class="progressbar w-100 d-center">
    <li [ngClass]="activeStepper >= 1? 'active' : '' "> <span>{{'STEEPER.STEP_1_1' | translate}}</span> </li>
    <li [ngClass]="activeStepper >= 2? 'active' : '' "><span>{{'STEEPER.STEP_2_1' | translate}}</span></li>
    <li [ngClass]="activeStepper >= 3? 'active' : '' "><span>{{'STEEPER.STEP_3_1' | translate}}</span></li>
    <li [ngClass]="activeStepper >= 4? 'active' : '' "><span>{{'STEEPER.STEP_4_1' | translate}}</span></li>
    <li [ngClass]="activeStepper >= 5? 'active' : '' "><span>{{'STEEPER.STEP_5' | translate}}</span></li>
  </ul>
</div>

