import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { Utils } from 'src/app/common/utils';
import { Constants } from 'src/app/Constants/constants';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import {TranslateService} from '@ngx-translate/core';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-new-login-ui',
  templateUrl: './new-login-ui.component.html',
  styleUrls: ['./new-login-ui.component.scss']
})
export class NewLoginUiComponent implements OnInit {
  @ViewChild('otp1', { static: false }) otp1: any = ElementRef;;
  @ViewChild('otp2', { static: false }) otp2: any = ElementRef;;
  @ViewChild('otp3', { static: false }) otp3: any = ElementRef;;
  @ViewChild('otp4', { static: false }) otp4: any = ElementRef;;


  passwordFormControl = new FormControl()
  userNameFormcontrol = new FormControl("",Validators.email)
  isOtpSend: boolean = false
  userDetails: any = {}
  userLogin: any = []
  tempServiceList: any = []
  isFromEstimate: boolean = false
  isFromEstimateBook: boolean = false
  isFromVehicle: boolean = false
  btnDisabled: boolean = false
  isLoading: boolean = false;
  isChecked: any;
  inputText: string = '';
  isFromMessage : boolean = false;


  expire: string = '03:00';
  startTime: number = 3 * 60 * 1000; // 3 minutes in milliseconds
  endTime!: number;
  intervalId: any;
  isEmailActive:boolean = false;
  isEmailInvalid:boolean = false


  constructor(private BookingService: BookingServiceService,
    private Utils: Utils,
    private route: Router,
    private translate : TranslateService,
    private sharedService: SharedServiceService,
    private gmailService: SocialAuthService) {
    this.otpDetails = {} as OTP
    if (this.route.getCurrentNavigation()?.extras.state != undefined) {
      let estimateVaue = this.route.getCurrentNavigation()?.extras.state;
      if (estimateVaue != undefined) {
        if (estimateVaue['isFromEstimateValue'] != undefined) {
          this.isFromEstimate = estimateVaue['isFromEstimateValue']
        } else if (estimateVaue['isFromEstimateBooked'] != undefined) {
          this.isFromEstimateBook = true
        }
      }
    }
  }

  ngOnInit(): void {
    document.body.classList.remove('inner-stepper');
    document.body.classList.add('sign-in');
    if(localStorage.getItem('user_login')){
    let userLogin = JSON.parse(localStorage.getItem('user_login') || '{}');
    userLogin.forEach((element: any) => {
      this.userLogin.push(element.email);
    })
    this.userLogin = [...new Set(this.userLogin)];
  }


    if (history.state != undefined) {
      if (history.state.isFromEstimateBooked) {
        this.isFromEstimateBook = true
      }
      if (history.state.isFromVechile) {
        this.isFromVehicle = true
      }
      if(history.state.message){
        this.isFromMessage = true
      }
    }
  }

  otpDetails: OTP;
  onInputPasswordChange(event: any) {

  }
  onFocus() {  
    this.isEmailActive = true;
  }
  onBlur() {  
    if(!this.userNameFormcontrol.value){
      this.isEmailActive = false;    }
      
  }
  onInputChange(value: any) {
    this.isOtpSend = false
    this.otpDetails.otp1 = ''
    this.otpDetails.otp2 = ''
    this.otpDetails.otp3 = ''
    this.otpDetails.otp4 = ''
    if (this.userNameFormcontrol.value != null && this.userNameFormcontrol.value != '' && this.userNameFormcontrol.valid) {
      this.btnDisabled = true;
    }
    else {
      this.btnDisabled = false;
    }

    this.tempServiceList = this.userLogin.filter((data: any) => {
     if(data == null) return this.userLogin
      return data.toLowerCase().includes(value.toLowerCase());
    })
  }


  checkValue(event: any) {
  }



  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  backspace(event: any, otpTab: number) {
    if (event.key == "Backspace") {
      if (otpTab === 4) {
        if (this.otpDetails.otp4 == '') {
          this.otp3.nativeElement.focus()
        }
      } else if (otpTab === 3) {
        if (this.otpDetails.otp3 == '') {
          this.otp2.nativeElement.focus()
        }
      } else if (otpTab === 2) {
        if (this.otpDetails.otp2 == '') {
          this.otp1.nativeElement.focus()
        }
      }
    }
  }

  tabChange(val: number) {
    let current_id = 'otp' + val
    val = val + 1
    let id = 'otp' + val
    let ele = document.getElementById(id) as HTMLInputElement;
    let current_ele = document.getElementById(current_id) as HTMLInputElement;
    if (current_ele.value != '') {
      ele?.focus()
    } else {
      current_ele?.focus()
    }
  }

  sendOtp(a: string) {
    if (a === 'Resend') {
      // this.isLoading = true;
    }

    if(this.userNameFormcontrol.valid){
    document.getElementById('btnLogin')?.classList.add('is-loading')

    let payload = {
      login_id: this.userNameFormcontrol.value
    }
    this.BookingService.requestOTP(payload).subscribe({
      error: (error) => {
        this.Utils.openErrorSnackBar(this.translate.instant(error.error.message))
        document.getElementById('btnLogin')?.classList.remove('is-loading')
        this.isLoading = false;
        this.isOtpSend = false
      }, // errorHandler
      next: (res) => {
        this.Utils.openSuccessSnackBar(this.translate.instant(res.message))
        this.isLoading = false;
        document.getElementById('btnLogin')?.classList.remove('is-loading')
        if (res.statusCode == 1) {
          this.isOtpSend = true
          this.otpDetails.otp1 = '';
          this.otpDetails.otp2 = '';
          this.otpDetails.otp3 = '';
          this.otpDetails.otp4 = '';
          this.userDetails = res.data
          this.startOtpTimer()
        }
      }, // nextHandler
    });
  }
  }



  verifyOTP() {
    if(this.otpDetails.otp1 && this.otpDetails.otp2 && this.otpDetails.otp3 && this.otpDetails.otp4){
      document.getElementById('btnVerify')?.classList.add('is-loading')
    if (this.isChecked == true) {
      localStorage.setItem(Constants.APP.USER_LOGIN, JSON.stringify([...JSON.parse(localStorage.getItem("user_login") || "[]"), { email: this.userNameFormcontrol.value }]));
    }

    let userOTP = this.otpDetails.otp1 + this.otpDetails.otp2 + this.otpDetails.otp3 + this.otpDetails.otp4
    let payload = {
      user_id: this.userDetails.user_id,
      session_id: this.userDetails.session_id,
      otp: userOTP,
    }
    this.BookingService.verifyOTP(payload).subscribe({
      error: (error) => {
        this.Utils.openErrorSnackBar(this.translate.instant(error.error.message))
        document.getElementById('btnVerify')?.classList.remove('is-loading')
      }, // errorHandler
      next: (res) => {

        document.getElementById('btnVerify')?.classList.remove('is-loading')
        if (res.statusCode == 1) {
          this.Utils.openSuccessSnackBar(this.translate.instant(res.message))
          localStorage.setItem(Constants.APP.SESSION_USER_DATA, JSON.stringify(res.data))
          localStorage.setItem(Constants.APP.SESSION_ID, JSON.stringify(res.data.session_id))
          if (!this.isFromEstimate && !this.isFromEstimateBook && !this.isFromVehicle) {
            this.route.navigate(['/dashboard'])
          } else if (this.isFromEstimateBook) {
            this.fetchVehcile()
          }else if(this.isFromVehicle){
            this.fetchVehcile()
          }else if(this.isFromMessage){
            this.route.navigate(['/service/estimates'],{state:{isFromLoginEstimate : true,message : true}} )
          }
           else {
            this.route.navigate(['/service/estimates'], { state: { isFromLoginEstimate: true } })
          }
        }
      }, // nextHandler
    });
    }
  }
  loginWithGmail() {
    this.gmailService.signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((res) => {
        let payload = {
          "provider": res.provider,
          "first_name": res.firstName,
          "last_name": res.lastName,
          "email": res.email,
          "access_token": res.authToken
        }
        this.clickSocialLogin(payload)
      })
  }

  loginWithFaceBook() {
    this.gmailService.signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((res) => {
      })
  }

  clickSocialLogin(payload: any) {
    this.BookingService.socialLogin(payload).subscribe((res: any) => {
      if (res.statusCode === 1) {
        this.Utils.openSuccessSnackBar(this.translate.instant(res.message))
        localStorage.setItem(Constants.APP.SESSION_USER_DATA, JSON.stringify(res.data))
        localStorage.setItem(Constants.APP.SESSION_ID, JSON.stringify(res.data.session_id))
        this.userNameFormcontrol.setValue('')
        this.passwordFormControl.setValue('')
        if (!this.isFromEstimate && !this.isFromEstimateBook) {
          this.route.navigate(['/dashboard'])
        } else if (this.isFromEstimateBook) {
          this.fetchVehcile()
        } else {
          this.route.navigate(['/service/estimates'], { state: { isFromLoginEstimate: true } })
        }
      } else {
      }
    })
  }


  saveVehcile() {
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    let userDetails;
    if (userdata != undefined) {
      userDetails = JSON.parse(userdata)
    }
    let vehicleData = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE)
    if (vehicleData != null) {
      let vehicleDataDetails =   JSON.parse(vehicleData)
      let payload = {
        "user_id": userDetails.user_id,
        "make_id": vehicleDataDetails.vechile_make,
        "model_id": vehicleDataDetails.vechile_model,
        "series_id": vehicleDataDetails.series,
        "generation_id": vehicleDataDetails.generation,
        "trim_id": vehicleDataDetails.vechile_engine,
        "year": vehicleDataDetails.model_year,
        "transmission_type": vehicleDataDetails.transmission_type,
        "fuel_type" : vehicleDataDetails.fuel_type,
        "mileage": Number(vehicleDataDetails.current_mileage),
        "car_plate":vehicleDataDetails.car_plate,
        "chasis_no":vehicleDataDetails.chasis_no
      }
      this.BookingService.addVehicleByUser(payload).subscribe({
        error: (error) => {
        },
        next: (res) => {
          if (res.statusCode == 1) {
            let vehicleData = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE)
            if (vehicleData != null) {
              let vehicleDataDetails = JSON.parse(vehicleData)
              vehicleDataDetails.vechicle_id = res.vechicle_id
              sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(vehicleDataDetails))
              if(!this.isFromVehicle){
                this.bookAppointment()
              }
              if(this.isFromVehicle){
                this.route.navigate(['/service/vehicle'])
              }
            }
          }
        },
      });
    }
  }

  fetchVehcile() {
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    let userDetails;
    if (userdata != undefined) {
      userDetails = JSON.parse(userdata)
    }
    this.BookingService.fetchVehicleByUser(userDetails.user_id).subscribe({
      error: (error) => {
        if (error.error.statusCode === 0) {
          this.saveVehcile()
        }
      }, // errorHandler
      next: (res) => {
        if (res.statusCode == 1) {
          let userVehcileData = res.vechicle
          let vehicleData = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE)
          if (vehicleData != null) {
            let vehicleDataDetails = JSON.parse(vehicleData)
            let isExists = false
            userVehcileData?.forEach((element: any) => {
              let modelYear = vehicleDataDetails.model_year || '';
              if (element.start_production_year === modelYear && element.make_id == vehicleDataDetails.vechile_make && element.vechile_model == vehicleDataDetails.model_id && element.trim_id == vehicleDataDetails.vechile_engine) {
                isExists = true
                element.vechicle_id = element.vechicle_id
                element.model_year = element.start_production_year
                element.vechile_make_name = element.make_name
                element.vechile_model_name = element.model_name
                element.vechile_make = element.make_id
                element.vechile_model = element.model_id
                element.generation = element.generation_id
                element.series = element.series_id
                element.img = element.logo
                element.garage_count = vehicleDataDetails.garage_count
                sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(element))
              }
            });
            if (!isExists) {
              this.saveVehcile()
            } else {
              this.bookAppointment()
            }
          }else{
            this.route.navigate(['/service/vehicle'])
          }
        }
      }, // nextHandler
    });
  }


  getServiceListPayload() {
    let serviceList = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE);
    let serviceDetailsList: any[] = [];
    if (serviceList != null) {
      serviceList = JSON.parse(serviceList)
      let serviceDetails: any = localStorage.getItem(Constants.APP.SERVICE_LIST)
      serviceDetails = JSON.parse(serviceDetails)
      serviceDetails.forEach((element: any) => {
        if(!element.question.fromMaster){
        let service = {
          id: element.answer.id,
          comment: element.comment,
          is_from_all_service: element.is_from_all_service,
          service_id : element.question.service_id,
          selected_answer : element.answer.selected_answer
        }
        serviceDetailsList.push(service)
      }
      });
    }
    return serviceDetailsList
  }

  bookAppointment() {
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    let estimateData = sessionStorage.getItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
    let serviceData = this.getServiceListPayload()
    let serviceTime = sessionStorage.getItem(Constants.APP.SESSION_ESTIMATE_TIME)
    let applied_garage_details = localStorage.getItem(Constants.APP.APPLIED_GARAGES_ID)
    if (userdata != undefined) {
      let userDetails = JSON.parse(userdata)
      let serviceList = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE);
      let quote_id = sessionStorage.getItem(Constants.APP.QUOTE_ID);
      if (serviceList != null && estimateData != null && serviceTime != null) {
        let serviceListDetails = JSON.parse(serviceList)
        let estimate_Details = JSON.parse(estimateData)
        let selectedTimeDate = JSON.parse(serviceTime)
        let payload: any = {
          vechicle_id: serviceListDetails.vechicle_id,
          service: serviceData,
          user_id: userDetails.user_id,
          garage_id: estimate_Details.id,
          status: "booked",
          estimation_type: estimate_Details.quote.estimation_type,
          estimation: estimate_Details.quote.items,
          amount_to_pay: estimate_Details.quote.finalPrice,
          total_amount: estimate_Details.quote.finalPrice,
          appointments: {
            1: selectedTimeDate[0].exact_date + " " + selectedTimeDate[0].selectedTime,
            2: (selectedTimeDate[1] != undefined) ? selectedTimeDate[1].exact_date + " " + selectedTimeDate[1].selectedTime : ''
          }
        }
        if(applied_garage_details != undefined){
          let applied_garage_data = JSON.parse(applied_garage_details)
          applied_garage_data.forEach((item:any)=>{
            if(item.garage_id == estimate_Details.id ){
              item.status = 'booked'
            }else{
              item.status = 'not booked'
            }
          });
          payload.applied_garage_details = applied_garage_data
         
        }
        if (quote_id != undefined) {
          payload.quote_id = Number(quote_id)
        } else {
          payload.zip_code = localStorage.getItem("address")
        }
        this.BookingService.saveEstimate(payload).subscribe({
          next: (res) => {
            if (res.statusCode == 1) {
              sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
              sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
              sessionStorage.removeItem(Constants.APP.QUOTE_ID)
              localStorage.removeItem(Constants.APP.SERVICE_LIST)
              localStorage.removeItem(Constants.APP.APPLIED_GARAGES_ID)
              this.sharedService.setBookedPopup(true)
              this.route.navigate(['/dashboard'])
            }
          },
          error: (err) => {
            // console.log(err)
          }
        })
      }
    }
  }

  startOtpTimer(): void {
    const currentTime = new Date().getTime();
    this.endTime = currentTime + this.startTime;
    this.updateTimer();
  }

  updateTimer(): void {
    this.intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeRemaining = this.endTime - currentTime;

      if (timeRemaining <= 0) {
        clearInterval(this.intervalId);
        this.expire = '00:00'
      } else {
        const minutes = Math.floor(timeRemaining / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
        this.expire = this.formatTime(minutes) + ':' + this.formatTime(seconds);
      }
    }, 1000);
  }

  formatTime(time: number): string {
    return time < 10 ? '0' + time : '' + time;
  }

  
}

interface OTP {
  otp1: string,
  otp2: string,
  otp3: string,
  otp4: string
}


