
    <app-signup-header></app-signup-header>

<div class="login-card column is-10-tablet is-8-desktop is-6-widescreen is-4-fullhd p-5">
        <div class="has-text-weight-bold  signin-title mb-4">{{ 'DASHBOARD.HELP' | translate }}</div>
        <form [formGroup]="help" (ngSubmit)="helpFunction()">
            <div class="columns my-0 ml-0 control is-flex mb-5">
                <div class="w-100">
                    <input id="email" class="input" type="email" formControlName="email" (focus)="onFocus('email')" (blur)="onBlur('email')">
                    <label [ngClass]="help.controls['email'].invalid && (help.controls['email'].dirty || submitted)? 'floating-label-new' : 'floating-label'" [class.active]="isEmailActive" for="email">{{ 'COMMON.EMAIL_ID' | translate }}</label>
                    <span *ngIf="help.controls['email'].invalid && (help.controls['email'].dirty || submitted)"
                        class="help is-danger"> 
                        <span *ngIf="help.controls['email'].errors?.['required']">
                            {{ 'ERROR_MESSAGE.error_email_required' | translate }}
                        </span>
                        <span *ngIf="help.controls['email'].errors?.['pattern']">
                            {{ 'ERROR_MESSAGE.error_valid_email' | translate }}
                        </span>
                        </span>
                </div>
                <div class="icon star-icon is-size-4 has-text-danger"
                >*</div>
            </div>
        
            <div class="columns my-0 ml-0 control is-flex mb-5">
                <div class="w-100">
                    <textarea id="remarks" class="textarea has-fixed-size" [rows]="4" (focus)="onFocus('remarks')" (blur)="onBlur('remarks')"
                    formControlName="remarks"></textarea>
                    <label class="floating-text is-hidden-mobile" [class.active]="isRemarksActive" for="remarks">{{ 'COMMON.REMARKS' | translate }}</label>
                    <label class="floating-text is-hidden-tablet" [class.active]="isRemarksActive" for="remarks">{{ 'COMMON.REMARKS_MOB' | translate }}</label>
                <span *ngIf="help.controls['remarks'].invalid && (help.controls['remarks'].dirty || submitted)"
                    class="help is-danger">{{'COMMON.REMARKS_REQUIRED' | translate}}</span>
                </div>
                <div class="icon star-icon is-size-4 has-text-danger"
                >*</div>
            </div>
            <div class="control mr-2">
                <button [disabled]="!help.valid || (help.get('remarks')?.value.trim().length ==0)" [ngClass]="(help.valid) && (help.get('remarks')?.value.trim().length !=0) ? 'is-success' : 'button-disabled'" id="btnVerify" class="button button-cta has-text-weight-bold is-fullwidth submit-btn"> {{'COMMON.SUBMIT' | translate}}</button>
            </div>
        </form>   
        <p class="faq-text pt-3 has-text-right"><span class=" is-clickable" (click)="faq()">{{ 'COMMON.FAQ' | translate }}</span></p> 
</div>




