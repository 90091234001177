<div>
  <div *ngIf="!userData"
    class="announcement is-full column is-vcentered has-text-right is-hidden-mobile"
  >
    <span class="has-text-weight-semibold top-text">{{"HOME.TEXT_60" | translate }}<span class="signup-link" (click)="signup()">{{"HOME.TEXT_60.1" | translate }}</span>{{"HOME.TEXT_60.2" | translate }}</span>
  </div>
  <div *ngIf="!userData"
    class="is-hidden-desktop is-hidden-tablet announcement is-full column is-vcentered"
  >
    <span class="has-text-weight-semibold is-size-7">{{
      "HOME.TEXT_66" | translate
    }}</span>
  </div>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div
      class="navbar-brand is-pa-t-10 is-pa-l-10 is-pa-b-10" 
      [class.is-active]="showBurgerMenu" >
      <img class="is-clickable" src="assets/img/arabama.png" routerLink="/home"
      style="display: block;"/>
      <a
      (click)="showBurgerMenu = !showBurgerMenu"
      role="button"
      class="navbar-burger nav-toggle is-hidden-desktop ham-menu-icon"
      aria-label="menu"
      aria-expanded="false"
      data-target="myNavBar"
    >
    <img class="is-hidden-desktop ham-active" src="../../../assets/img/ham-menu.png"/>
    <img class="is-hidden-desktop close-ham" src="../../../assets/img/ham-close.png"/>
    </a>
    </div>

    <div id="myNavBar" class="navbar-menu" [class.is-active]="showBurgerMenu" (click)="showBurgerMenu = !showBurgerMenu">
      <div *ngIf="!userData" class="navbar-start">
        <a (click)="howWorks()" class="navbar-item">
          <span class="has-text-weight-bold has-text-left pl-4 is-flex py-2">
            {{ "HOME.TEXT_5" | translate }}</span
          >
        </a>
        <a (click)="guarantee()" class="navbar-item">
          <span class="has-text-weight-bold has-text-left pl-4 is-flex py-2">
            {{ "HOME.TEXT_73" | translate }}</span
          >
        </a>
      </div>
      <div *ngIf="userData" class="navbar-start pl-3">
        <a (click)="howWorks()" class="navbar-item is-flex">
          <img class="ham-dropdown" src="../../../assets/svg/how_it_works.svg" width="28px" height="30px" >
          <span class="has-text-left pl-1 is-flex pt-1">
            {{ "HOME.TEXT_5" | translate }}</span
          >
        </a>
        <a (click)="guarantee()" class="navbar-item is-flex">
          <img class="ham-dropdown" src="../../../assets/svg/how_i_trust.svg" width="28px" height="30px">
          <span class="has-text-left pl-1 is-flex pt-1">
            {{ "HOME.TEXT_73" | translate }}</span
          >
        </a>
        <div class="ham-dropdown">
          <a routerLink="/dashboard" class="navbar-item is-flex">
            <img src="../../../assets/svg/dashboard.svg" alt="" width="23px" height="23px">
            <span class="has-text-left pl-2 is-flex pt-1">
              {{'DASHBOARD.MY_DASHBOARD' | translate}}</span
            >
          </a>
        </div>
        <div class="ham-dropdown">
          <a class="navbar-item is-flex" (click)="help()">
            <img src="../../../assets/svg/help.svg" alt="">
            <span class="has-text-left pl-2 is-flex pt-1">
              {{ "DASHBOARD.HELP" | translate }}</span
            >
          </a>
        </div>
        <div class="ham-dropdown">
          <a class="navbar-item is-flex" (click)="logout()">
            <img src="../../../assets/svg/exit.svg" alt="" width="22px" height="22px">
            <span class="has-text-left pl-2 is-flex pt-1">
              {{ "DASHBOARD.LOGOUT" | translate }}</span
            >
          </a>
        </div>
       
      </div>
      <div *ngIf="!userData" class="navbar-end">
        <div class="navbar-item">
          <a routerLink="/signup" class="navbar-item">
            <span class="has-text-weight-bold">{{
              "HOME.TEXT_62" | translate
            }}</span>
          </a>
        </div>
        <div class="navbar-item">
          <a routerLink="/auth" class="navbar-item">
            <span class="has-text-weight-bold">{{
              "HOME.TEXT_55" | translate
            }}</span>
          </a>
        </div>
        <div class="navbar-item">
          <a (click)="goToServices()" class="navbar-item">
            <span class="has-text-weight-bold">
              {{ "HOME.TEXT_61" | translate }}
            </span>
          </a>
        </div>
      </div>
      <div  *ngIf="userData" class="navbar-end">
        <div class="navbar-item name-container is-clickable desk-menu" (mouseenter)="nameHover = true" (mouseleave)="nameHover = false">
          <div class="username">
              {{userData.first_name}} {{userData.last_name}} <span><img width="14px" src="../../../assets/svg/down-arrow-green.svg" alt=""></span>
          </div>
          <div class="menu-options py-2" *ngIf="nameHover">
              <div class="option" (click)="homePage()">
                  <div class="option-img">
                      <img src="https://asdev.nisissolutions.com/dashboard.svg" alt="">
                  </div>
                  <a class="navbar-item home-page" >
                      {{'DASHBOARD.MY_DASHBOARD' | translate}}
                  </a>
              </div>
             
              <div class="option" (click)="help()">
                  <div class="option-img">
                      <img src="https://asdev.nisissolutions.com/help.svg" alt="">
                  </div>
                  <a class="navbar-item help-menu">
                      {{'DASHBOARD.HELP' | translate}}
                  </a>
              </div>
              <div class="option" (click)="logout()">
                  <div class="option-img">
                      <img src="https://asdev.nisissolutions.com/exit.svg" alt="">
                  </div>
                  <a class="navbar-item exit">
                      {{'DASHBOARD.LOGOUT' | translate}}
                  </a>
              </div>
          </div>
      </div>
        
      </div>
      <div class="navbar-item is-hidden">
        <a routerLink="/auth" class="navbar-item">
          <span class="has-text-weight-bold">{{
            "HOME.TEXT_55" | translate
          }}</span>
        </a>
      </div>
    </div>
  </nav>
</div>
