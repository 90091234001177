<div class=" ma-5 pa-2 block-padding" style="background-color: #EDEDED">
  <div class="content">
    <h3 class="is-size-3-desktop has-text-centered is-size-4-mobile is-size-4-tablet">
      {{ "HOME.TEXT_25" | translate }}
    </h3>
    <div class="columns services-wrapper">
      <div class="column is-half-desktop pb-0">
        <img src="assets/img/service-1.jpg" />
        <p class="text_30 details mt-3" id="home_car">
          <span>{{ "HOME.TEXT_27" | translate }}</span><span style="font-weight:bold">6 Ay</span><span> veya</span><span style="font-weight:bold"> 5.000 Km arabamaservis.com </span><span>garantisindedir.</span>
        </p>
        
      </div>
      <div class="column is-half-desktop pb-0">
        <img src="assets/img/service-2.jpg" />
        <p class="text_30 details mt-3">
          <span  style="font-weight:bold">arabamaservis.com</span> {{ "HOME.TEXT_65" | translate }}<span style="font-weight:bold">Arabam güvende sigorta&ensp;</span><span>koruması altındadır.</span>
        </p>
    
      </div>
    </div>
  </div>
  <div></div>
</div>
