<div class="pb-2 estimate-box is-flex is-flex-direction-column is-justify-content-space-between">
  <div class="columns m-0">
    <div class="column py-0">
      <div class="columns m-0 is-mobile">
        <div class="column is-narrow px-0">
          <div class="profile-field is-clickable pb-1 pr-3" (click)="openGarageDetails()">
            <div class="profile-img">
              <img src="{{estimate_item.logo_url}}">
            </div> 
          </div>
          <div class="rating-buttons py-1 pl-3">
            <div class="button is-small is-primary" style="cursor: default;">
                <div class="star-rating is-flex is-align-items-center"><i class="fas fa-star is-size-7 mr-1"></i> <span class="pt-1 font-14">{{ estimate_item?.rating?.cum_rating }}</span></div>
            </div>
          </div>
        </div>
        <div class="column is-fluid px-0 pb-2">
          <div class="columns m-0">
            <div class="column is-fluid py-0 pl-0">    
                <div class="is-flex">
                  <p class="text-size-17 has-text-weight-bold is-clickable garage-name" (click)="openGarageDetails()">{{ estimate_item.name }} </p>
                  <div class="msg-icon is-flex">
                    <img class="ml-3 is-clickable" width="20px" src="../../../assets/svg/msg.svg" alt="" (click)="messagePopup()">
                    <div *ngIf="estimate_item.msg_notification_count > 0" class="field-button is-flex">
                      <div class="notification py-1 px-0 is-flex is-justify-content-center is-align-items-center">
                        <div class="has-text-weight-bold is-size-7">{{estimate_item.msg_notification_count < 100 ? estimate_item.msg_notification_count : 99}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>   
                <p class="text-size-14 pt-2"><span class="brand-color has-text-weight-bold"> {{estimate_item.distance | number :
                  '1.2-2'}} Km</span> - {{estimate_item.city}} / {{estimate_item.state}}</p>
                  <div class="features-wrapper columns m-0 pb-1 pt-2 is-multiline">
                    <div class="features-inner pt-1" *ngFor="let item of estimate_item?.specialization.slice(0, specializationLength) index as i;">
                      <div [ngClass]="i !=0 ? ' ' : ''" class="d-flex is-align-items-center specialization-data">
                      
                        <span class="text-size-13 px-3 py-1 m-0 pt-1" style="line-height: 1.2;">{{ item.name }}</span>
                      </div>
                    </div>
                    <div class="is-clickable more pt-2" *ngIf="estimate_item?.specialization.length > defaultSpecializationCount && specializationLength !== estimate_item?.specialization?.length" (click)="specializationLength = estimate_item?.specialization?.length">+{{estimate_item?.specialization?.length - defaultSpecializationCount}} {{'DASHBOARD.MORE' | translate}}</div>
                    <div class="is-clickable more pt-2" *ngIf="estimate_item?.specialization?.length === specializationLength && estimate_item?.specialization?.length !== defaultSpecializationCount" (click)="specializationLength = defaultSpecializationCount">{{'DASHBOARD.LESS' | translate}}</div>
                  </div> 
            </div>
            <div class="column is-narrow py-0 px-0">
              <div class="total-price">
                <p class="text-size-18 has-text-weight-bold color-arabama-green">{{"COMMON.TOTAL_PRICE" | translate }}</p>
                <p class="has-text-weight-medium text-size-14 diagonal-strikethrough" *ngIf="total !== estimate_item.original_price && !isMobile"> {{estimate_item.original_price}}</p>
                <p *ngIf="!isMobile" class="text-size-18 has-text-weight-bold">{{total}}</p>
                <div *ngIf="isMobile"  class="is-flex-desktop is-align-items-center">
                  <p class="has-text-weight-medium text-size-14 diagonal-strikethrough pr-2" *ngIf="total !== estimate_item.original_price && isMobile"> {{estimate_item.original_price}}</p>
                  <p *ngIf="isMobile" class="text-size-18 has-text-weight-bold">{{total}}</p>
                </div>
              </div>             
            </div>
          </div>       
        </div>    
      </div>
    </div> 
  </div>

    <div class="is-flex is-flex-wrap-wrap is-justify-content-space-between">
      <div class="columns is-flex m-0 pl-2">
        <div *ngIf="estimate_item.network_name === 'Bosch Car Service'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
          <img class="ml-2 mt-1" src="../../../assets/img/bosch_car_service.jpg" alt="" style="width: 30px; height: 30px;">          
        </div>
        <div *ngIf="estimate_item.tse_quality_checked === '1'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
          <img class="ml-2 mt-1" src="../../../assets/img/tse.png" alt="" style="width: 45px; height: 30px;">
        </div>
        <div *ngIf="estimate_item.exhaust_emission_service === 'yes'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
          <img class="ml-2 mt-1" src="../../../assets/img/exhaust_emission.png" alt="" style="min-width: 55px; width: 55px; height: 30px;">
        </div>
      </div>
      <div class="buttons pr-1 pb-1">
          <button class="button has-text-weight-semibold my-2 mx-1 px-2" (click)="openViewDetails()">{{ 'COMMON.VIEW_DETAILS' | translate
            }}</button>
          <button class="button is-success px-2 my-2 ml-1 mr-2 has-text-weight-semibold" (click)="nextStepper(estimate_item)">{{
            'COMMON.BOOK_APPOINTMENT' | translate }}</button>   
      </div>
  </div>
</div>



