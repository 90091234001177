import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Constants } from 'src/app/Constants/constants';
import { FormControl } from '@angular/forms'
import { USER } from 'src/app/services/booking-service.service';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { Utils } from 'src/app/common/utils';
import { TranslateService } from '@ngx-translate/core';
import { GoogleLoginProvider } from 'angularx-social-login';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { SocketService } from 'src/app/services/socket.service';
@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {

  ph: any;
  newMobile: any;
  profile_img: any;
  selected_img: any;
  uploadData: any;
  genderOptions = ['Erkek','Kadın']
  occupationOptions = ['Akademisyen', 'Asker / Polis', 'Avukat', 'Banka / Finans', 'Doktor', 'Medya iletişim', 'Memur', 'Mühendis / Mimar', 'Öğretmen', 'Sağlık hizmetleri', 'Satış / Pazarlama', 'Sanat', 'Diğer']
  cityList:any;
  districtList:any;
  zip_code:any


  optionCity: string [] = []
  optionDistrict: string [] = []
  disableDistrict: boolean = true
  confirmDeletePopup: boolean = false
  deleteMsgPopup: boolean = false;
  isFirstnameActive:boolean = false;
  isLastnameActive:boolean = false;
  isEmailActive:boolean = false;
  isPhoneActive:boolean = false;
  isGenderActive:boolean = false;
  isAddressActive:boolean = false;
  isdobActive:boolean = false;
  isDataLoading = false;

  constructor(private bookingService: BookingServiceService,
    private translate: TranslateService,
    private route: Router,
    private Utils: Utils, private sharedServices :SharedServiceService, private socketService : SocketService ) {
    this.user = {} as USER
  }
  user: USER;
  @Output() updateProfileInfo = new EventEmitter<string>();
  @ViewChildren(MatAutocompleteTrigger) autocompleteTriggers!: QueryList<MatAutocompleteTrigger>;



  firstNameFormControl = new FormControl();
  lastNameFormControl = new FormControl();
  emailIdFormControl = new FormControl();
  phoFormControl = new FormControl();
  dobFormControl = new FormControl();
  occupationFormControl = new FormControl();
  genderFormControl = new FormControl();
  addressFormControl = new FormControl();
  cityFormControl = new FormControl();
  districtFormControl = new FormControl();
  notificationFormControl = new FormControl()
  memberApprovalFormControl = new FormControl()
  commercialMsgFormControl = new FormControl()
  commercialUpdatedAt: any;
  newVal: boolean = false;
  isFirstNameValid: boolean = true
  isLastNameValid: boolean = true
  isEmailValid: boolean = true
  isPhnValid: boolean = true
  btnDisabled: boolean = false
  isCityValid: boolean = true
  isProvinceValid: boolean = true


  ngOnInit(): void {
    this.fetchDriverInfo()
    this.fetchCity()
    
  }
  onFocus(field: string) {  
    if(field === 'gender'){
      this.isGenderActive = true;
    }
    else if(field === 'address'){
      this.isAddressActive = true;
    }
    else if(field === 'dob'){
      this.isdobActive = true;
    }
     else if(field === 'firstname'){
      this.isFirstnameActive = true;
    }
    else if(field === 'lastname'){
      this.isLastnameActive = true;
    }
    else if(field === 'email'){
      this.isEmailActive = true;
    }
    else if(field === 'phone'){
      this.isPhoneActive = true;
    }

  }
  
  onBlur(field: string) {  

    if(field === 'gender' && !this.genderFormControl.value){      
      this.isGenderActive = false;
    }
    else if(field === 'address' && !this.addressFormControl.value){
      this.isAddressActive = false;
    }
    else if(field === 'dob' && !this.dobFormControl.value){
      this.isdobActive = false;
    }
    else if(field === 'firstname' && !this.firstNameFormControl.value){
      this.isFirstnameActive = false;
    }
    else if(field === 'lastname' && !this.lastNameFormControl.value){
      this.isLastnameActive = false;
    }
    else if(field === 'email' && !this.emailIdFormControl.value){
      this.isEmailActive = false;
    }
    else if(field === 'phone' && !this.phoFormControl.value){
      this.isPhoneActive = false;
    }

  }
  onInputPhChange(event: any) {
    var newVal = event.replace(/\D/g, '');
    if (newVal.length == 0) {
      this.isPhnValid = false
    } else{
      this.isPhnValid = true
      if (newVal.length == 0) {
        newVal = '';
      }else if (newVal.length <= 1) {
        if(newVal !=0){
          newVal = '0' + newVal
        }
        newVal =newVal.replace(/^(\d{0,1})/, '$1');
     } 
      else if (newVal.length <= 4) {
         newVal =newVal.replace(/^(\d{0,1})(\d{0,3})/, '$1 ($2)');
      } else if (newVal.length <= 7) {
        newVal =newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})/, '$1 ($2) $3');
   
      } else if (newVal.length <= 11) {
        newVal =newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, '$1 ($2) $3 $4 $5');
      } else {
        newVal =newVal.substring(0, 11);
        newVal =newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4 $5');
        
      }
        this.ph = newVal   
    }

    this.btnValidate()
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onInputFirstNameChange(value: any) {
    if (value?.length === 0) {
      this.isFirstNameValid = false
    } else {
      this.isFirstNameValid = true
    }
    this.btnValidate()
  }

  onInputLastNameChange(value: any) {
    if (value?.length === 0) {
      this.isLastNameValid = false
    } else {
      this.isLastNameValid = true
    }
    this.btnValidate()
  }

  onInputEmailChange(value: string) {
    this.isEmailValid = this.Utils.validateEmail(value)
    this.btnValidate()
  }

  btnValidate() {
    if (this.firstNameFormControl.value != null && this.firstNameFormControl.value != '' && this.lastNameFormControl.value != null
      && this.lastNameFormControl.value != '' && this.isEmailValid && this.phoFormControl.value != null && this.phoFormControl.value != ""
      && this.phoFormControl.value.length > 16 && this.cityFormControl.value != null && this.cityFormControl.value != ''
      && this.districtFormControl.value != null && this.districtFormControl.value != '') {
      this.btnDisabled = false
    } else {
      this.btnDisabled = true
    }
  }
  fetchDriverInfo(){
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    if (userdata != undefined) {
      let userDetails = JSON.parse(userdata)
      let data =  {
        user_id : userDetails.user_id
      }
    this.isDataLoading = true;
    this.bookingService.fetchDriver(data).subscribe({
      next: (res) => {
        if(res.statusCode == 1){
          this.initializeUserDetails(res.data[0]);
        }

      },
      error: (err) => {
        console.log(err)
        this.isDataLoading = false;

      }
    })
    }
  }

  initializeUserDetails(userDetails: any) {
    let sessionId = localStorage.getItem(Constants.APP.SESSION_ID)

    this.user.userId = userDetails.id || '0';
    this.user.session_id = sessionId || '';
    this.user.firstName = userDetails.first_name;
    this.user.lastName = userDetails.last_name;
    this.user.email = userDetails.email;
    this.newMobile = userDetails.phone_no.substring(0, 17);
    this.newMobile = this.newMobile.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, '$1 ($2) $3 $4 $5');
    this.user.mobile = this.newMobile;
    this.user.dob = userDetails.dob;
    this.user.gender = userDetails.gender;
    this.user.occupation = userDetails.occupation;
    this.user.address = userDetails.address;
    this.profile_img = userDetails.display_picture;
    this.genderFormControl.setValue(userDetails.gender? userDetails.gender : null);
    this.occupationFormControl.setValue(userDetails.occupation? userDetails.occupation : null);
    this.cityFormControl.setValue(userDetails.city);
    this.districtFormControl.setValue(userDetails.province);
    this.notificationFormControl.setValue(userDetails.notification);
    this.memberApprovalFormControl.setValue(userDetails.member_approval);
    this.commercialMsgFormControl.setValue(userDetails.commercial_message);
    this.user.date_create = userDetails.date_create;
    this.user.commercial_message = userDetails.commercial_message;
    this.user.commercial_updated_at = userDetails.commercial_updated_at;

    this.commercialUpdatedAt = moment(this.user.commercial_updated_at).format('YYYY-MM-DD HH:mm:ss');
    
    if(this.districtFormControl.value){
      this.disableDistrict = false
    }
    if(this.user.firstName){
      this.isFirstnameActive = true
    }
    if(this.user.lastName){
      this.isLastnameActive = true
    }
    if(this.user.email){
      this.isEmailActive = true
    }
    if(this.user.mobile){
      this.isPhoneActive = true
    }
    if(this.user.address){
      this.isAddressActive = true
    }
    if(this.user.gender){
      this.isGenderActive = true
    }
    if(this.user.dob){
      this.isdobActive = true
    }
    this.isDataLoading = false;

  }

  onclickUpdateProfile() {
    document.getElementById('btnSignUp')?.classList.add('is-loading')
    if(this.user.commercial_message != (this.commercialMsgFormControl.value == true ? 1 : 0)){
      this.commercialUpdatedAt =  moment().format('YYYY-MM-DD HH:mm:ss');
      this.user.commercial_message = (this.commercialMsgFormControl.value == true ? 1 : 0)
    }
    let  payload:any = {
      "user_id": this.user.userId,
      "session_id": this.user.session_id,
      "first_name": this.user.firstName,
      "last_name": this.user.lastName,
      "email": this.user.email,
      "phone_no": this.user.mobile.replace(/[(\s)]/gi, ''),
      "dob": this.user.dob? this.user.dob : '',
      "gender": this.genderFormControl.value? this.genderFormControl.value : '',
      "occupation": this.occupationFormControl.value? this.occupationFormControl.value : '',
      "address": this.user.address? this.user.address : '',
      "city": (this.cityFormControl.value) ? this.cityFormControl.value : '',
      "province":(this.districtFormControl.value)? this.districtFormControl.value : '',
      "profile_img" : this.profile_img? this.profile_img : '',
      "notification" : this.notificationFormControl.value == true? 1 : 0,
      "member_approval" : this.memberApprovalFormControl.value == true? 1 : 0,
      "commercial_message" : this.commercialMsgFormControl.value == true? 1 : 0,
      "commercial_updated_at": this.commercialUpdatedAt 
    }   
      this.uploadData = new FormData();
      this.selected_img?this.uploadData.append('file', this.selected_img):'';
      for ( var key in payload ) {

      if (key== 'dob' && payload[key] != '' ) {
          const formattedDate = moment(payload[key]).format('YYYY-MM-DD');    
          this.uploadData.append(key, formattedDate);                         
      }   
      else{
        this.uploadData.append(key, payload[key]);
      }     
    }
  
    this.bookingService.updateProfile(this.uploadData).subscribe((res: any) => {
      if (res.statusCode == 1) {
        this.Utils.openSuccessSnackBar(this.translate.instant(res.message))
        this.cityFormControl.setValue(payload.city)
        this.districtFormControl.setValue(payload.province)
        this.user.commercial_updated_at = payload.commercial_updated_at
        document.getElementById('btnSignUp')?.classList.remove('is-loading')
        let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
        if (userdata != undefined) {
          let userDetails = JSON.parse(userdata)
          userDetails.first_name = this.user.firstName;
          userDetails.last_name = this.user.lastName;
          userDetails.email = this.user.email
          userDetails.phone_no = this.user.mobile.replace(/[(\s)]/gi, '')
          userDetails.dob = this.user.dob;
          userDetails.gender = this.user.gender;
          userDetails.occupation = this.occupationFormControl.value;
          userDetails.address = this.user.address;
          userDetails.city =  this.cityFormControl.value;
          userDetails.province = this.districtFormControl.value;
          userDetails.profile_picture = res.profile_img? res.profile_img : this.profile_img;
          userDetails.notification = this.notificationFormControl.value == true? 1 : 0
          userDetails.member_approval = this.memberApprovalFormControl.value == true? 1 : 0
          userDetails.commercial_message = this.commercialMsgFormControl.value == true? 1 : 0
          userDetails.commercial_updated_at = this.commercialUpdatedAt
          localStorage.setItem(Constants.APP.SESSION_USER_DATA, JSON.stringify(userDetails))
        }
        this.updateProfileInfo.emit()
        this.sharedServices.updateUserProfile(true)
      } else {
        this.Utils.openErrorSnackBar(this.translate.instant(res.message))
        document.getElementById('btnSignUp')?.classList.remove('is-loading')
      }
    }, (err) => {
      this.Utils.openErrorSnackBar(this.translate.instant(err.error.message));
      document.getElementById('btnSignUp')?.classList.remove('is-loading')
    })
  }

  get_profile_img(data: any) {
    this.selected_img = data.profile_image
  }
  onBackPressed(){
    this.route.navigate(['/dashboard'])
  }
  myFilter = (d: Date | null): boolean => {    
    const day = (d || new Date());
    const today = new Date()
    today.setDate(today.getDate()-1);
    return day < today;    
  };

  fetchCity(){
    this.isDataLoading = true;
    this.bookingService.fetchCity().subscribe({
      next: (res) => {
        if(res.statusCode == 1){
          this.cityList = res.data
          this.optionCity = res.data.map((item: any) => item.city_name).sort((a: string, b:string) => 
            a.localeCompare(b, 'tr', { sensitivity: 'variant' })
          );
          if(this.cityFormControl.value){
            let city = this.cityList?.find((item: any) => item.city_name?.toLowerCase() == this.cityFormControl.value?.toLowerCase())
            this.fetchDistrict(city.city_id)
            this.disableDistrict = false
          }
        }
        this.isDataLoading = false;

      },
      error: (err) => {
        console.log(err)
        this.isDataLoading = false;

      }
    })
  }

  fetchDistrict(cityId: number){
    let data = { cityId }
    this.bookingService.fetchDistrict(data).subscribe({
      next: (res) => {
        if(res.statusCode == 1){
          this.optionDistrict = res.data.map((item: any) => item.district_name).sort((a: string, b:string) => 
            a.localeCompare(b, 'tr', { sensitivity: 'variant' })
          );
        }
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  selectCity(data:string){
    this.cityFormControl.setValue(data)
    this.districtFormControl.setValue('')
    if(data == '' || data == undefined){
      this.disableDistrict = true
      this.optionDistrict = []
      this.isCityValid = false
    } else{
      let city = this.cityList.find((item: any) => item.city_name?.toLowerCase() == data?.toLowerCase())
      this.fetchDistrict(city.city_id)
      this.disableDistrict = false
      this.isCityValid = true

    }
    this.btnValidate()
  }
  selectProvince(data:any){
    if(data){
      this.districtFormControl.setValue(data)
      this.isProvinceValid = true
    }else{
      this.districtFormControl.setValue('')
      this.isProvinceValid = false
    }
    this.btnValidate()
  }
  selectOccupation(data: any){
    if(data){
      this.occupationFormControl.setValue(data)
    }
    else{
        this.occupationFormControl.setValue('')
    }
  }
  selectGender(data: any){
    if(data){
      this.genderFormControl.setValue(data)
    }
    else{
        this.genderFormControl.setValue('')
    }
  }
  openAutocomplete(index: number) {
    this.autocompleteTriggers.toArray().forEach((trigger, i) => {
      if (i !== index) {
          trigger.closePanel();
      }
    });
    const trigger = this.autocompleteTriggers.toArray()[index];
    if (trigger) {
        trigger.openPanel();
    }
  }

  deleteAccount(){
    this.confirmDeletePopup = false
    
    let payload = {
      user_id : this.user.userId
    }
    this.bookingService.deleteAccount(payload).subscribe({
        next:(res)=>{
          if(res.statusCode == 1){
            localStorage.removeItem(Constants.APP.SERVICE_LIST)
            localStorage.removeItem("address")
            localStorage.removeItem("zipcode")
            sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
            sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
            sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
            localStorage.removeItem(Constants.APP.LOCATION_ARRAY)
            localStorage.removeItem(Constants.APP.SESSION_ID)
            localStorage.removeItem(Constants.APP.SESSION_USER_DATA)
            sessionStorage.removeItem(Constants.APP.SESSION_VECHICLE_LIST)
            sessionStorage.removeItem(Constants.APP.QUOTE_ID)
            localStorage.removeItem(Constants.APP.APPLIED_GARAGES_ID)
            this.sharedServices.clearData()
            document.body.classList.remove('inner-stepper');
            this.socketService.disconnect()
            this.deleteMsgPopup = true
            this.Utils.openSuccessSnackBar(this.translate.instant(res.message))
          }
        },error : (err)=>{
          console.log(err)
        }
    })
  }
  goToDashboard(){
    this.deleteMsgPopup = false
    this.route.navigate(['/home'])
  }
}
