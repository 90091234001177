<div *ngIf="!jobDetails" class="pb-1 pl-3 has-text-weight-semibold is-flex">
    <img class="cart-img mr-2" src="../../../assets/svg/cart_new.svg" alt="">
    <p class="cart-text">{{"SERVICE.TEXT_45" | translate}}
    </p>
</div>
<div class="box">
    <div class="services-aside">
        <div class="header is-flex is-justify-content-space-between">
            <div class="is-flex">
                <img style="margin-left: -2px;" src="assets/svg/location-new.svg" />
                <span class="pt-1 is-size-6  has-text-weight-bold side-menu-custom">{{ 'HOME.TEXT_70' | translate}}</span>
            </div>
           
        </div>
        <div class="content"> 
                <p class="text-size-14 subheading-color-2" style="word-break: break-all;"> {{address}} </p> 
        </div>
    </div>
</div>
<div class="box" *ngIf="serviceCount > 0">
    <div class="services-aside">
        <div class="header is-flex is-justify-content-space-between">
            <div class="is-flex">
                <img src="assets/svg/service-detail.svg" />
                <span class="is-size-6 pt-1 has-text-weight-bold side-menu-custom">{{ 'COMMON.SERVICES' | translate}} ({{servicesList.length}})</span>
            </div>
            <div class="is-flex is-align-items-center" *ngIf="showAddService && stepperCount === 2">
                <img src="../../../assets/svg//add-btn.svg" alt=""  class="is-clickable" style="margin-right: 0px;" (click)="addService()">
            </div>
        </div>
        <div class="ser-content px-0">
            <ul *ngFor="let item of servicesList; index as i;last as isLast" [ngStyle]="!isLast ? {'border-bottom': '1px solid #CBCBCB', 'margin-bottom':'10px'} : {}">
                <div class="columns is-mobile hoverService" *ngIf="!item.question.fromMaster">
                    <div class="column">
                            <div class="is-flex is-justify-content-space-between">
                                <div class="subheading-color-2 is-flex">
                                    <p class="has-text-weight-semibold mb-0">{{i+1}}.</p>
                                    <p class="pl-2 has-text-weight-semibold">{{item.question.answer}} </p>
                                </div>
                                <div class="icon is-small deleteService ml-1 mr-2" style="color: #B0B0B0;" *ngIf="(item.question.answer === item.answer.answer && !item.comment) && (stepperCount === 1 || stepperCount === 2)">
                                    <div *ngIf="stepperCount === 1 || stepperCount === 2">
                                        <div *ngIf="trashShowingCtrl" class="is-flex">
                                            <img class="mr-2 is-clickable notes-icon" src="../../../assets/svg/edit.svg" alt="" (click)="editService(item)">
                                            <img class ="is-clickable notes-icon" src="../../../assets/svg/delete.svg" alt="" style="height: 16px;"  (click)="deleteService(item)">
                                        </div>
                                    </div>                               
                                </div>
                                <div class="icon is-clickable" *ngIf="item.question.answer !== item.answer.answer || item.comment" (click)="item.open = !item.open">
                                    <span class="pb-1">
                                        <img *ngIf="item.open" src="../../../assets/svg/Vectordown.svg" alt="" width="12px" height="12px">
                                        <img *ngIf="!item.open" src="../../../assets/svg/VectorUp.svg" alt="" width="12px" height="12px">
                                    </span>
                                </div>
                            </div>
                            
                            <div class="pl-4" [ngStyle]="{'display': item.open ? 'block' : 'none'}">
                                <div class="is-flex is-justify-content-space-between">
                                    <div *ngIf="item.question.answer !== item.answer.answer" style="width: 80%;"> 
                                   
                                        <div *ngFor="let ans of item?.selected_answer" class="is-flex">
                                            <i class="fa fa-solid fa-circle custom-icon"></i>
                                            <div class="pl-2">{{ans.relevant_answer}} <span *ngIf="ans.relevant_answer">-</span> {{ans.selected_answer}}</div>
                                        </div>
                                    </div>
                                    <div *ngIf="(item.question.answer !== item.answer.answer) && (stepperCount === 1 || stepperCount === 2)" class="deleteService" style="min-width: 15px;">
                                        <div class="" style="color: #B0B0B0; min-width: 15px;" >
                                        <div *ngIf="stepperCount === 1 || stepperCount === 2">
                                            <div *ngIf="trashShowingCtrl" class="is-flex del-align">
                                                <img class="mr-2 is-clickable notes-icon" src="../../../assets/svg/edit.svg" alt="" (click)="editService(item)">
                                                <img class ="is-clickable notes-icon" src="../../../assets/svg/delete.svg" alt="" (click)="deleteService(item)" style="height: 16px;">
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="is-flex is-justify-content-space-between">
                                    <div *ngIf="item.comment" class="comments-container" [ngClass]="(item.question.answer === item.answer.answer) && item.comment && trashShowingCtrl ? 'change': ''" >
                                        <div *ngIf="item.comment.length < 50" class="comments">
                                            {{item.comment}}
                                        </div>
                                        <div *ngIf="item.comment.length > 50 && item.showMore" class="comments">
                                            {{item.comment.slice(0, 50)}} <span class="is-clickable more" (click)="item.showMore = false">{{'DASHBOARD.MORE' | translate}}</span>
                                        </div>
                                        <div *ngIf="item.comment.length > 50 && !item.showMore" class="comments">
                                            {{item.comment}} <span class="is-clickable more" (click)="item.showMore = true">{{'DASHBOARD.LESS' | translate}}</span>
                                        </div>
                                    </div>
                                    <div *ngIf="(item.question.answer === item.answer.answer) && (stepperCount === 1 || stepperCount === 2)" class="deleteService" style="min-width: 15px;">
                                        <div style="color: #B0B0B0; min-width: 15px;">
                                            <div *ngIf="stepperCount === 1 || stepperCount === 2">
                                                <div *ngIf="trashShowingCtrl" class="is-flex mx-2">
                                                    <img class="mr-2 is-clickable notes-icon" src="../../../assets/svg/edit.svg" alt="" (click)="editService(item)">
                                                    <img class ="is-clickable notes-icon" src="../../../assets/svg/delete.svg" alt="" (click)="deleteService(item)" style="height: 16px;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </ul>
        </div>
    </div>
</div>
<div class="mt-3 box" *ngIf="vechileName != ''">
    <div class="services-aside">
        <div class="header">
            <img src="../../../assets/svg/vehicle-car.svg"/>
            <span class="is-size-6 pt-1 has-text-weight-bold side-menu-custom">{{ 'COMMON.VEHICLE' | translate}}</span>
        </div>
        <div class="content">
            <ul>
                <span *ngIf="vechileName != ''" class="text-size-14 subheading-color-2">{{vechileName}} </span>
                <span *ngIf="vechileName == ''" class="text-size-14 subheading-color-2">{{'SERVICE.TEXT_34' |
                    translate}} </span>
            </ul>
        </div>
    </div>
</div>
<div *ngIf="estimationDetails != null && estimateShowing" class="mt-3 box">
    <div class="services-aside">
        <div class="header">
            <img src="assets/svg/total-price.svg" style="margin-right: 0;" />
            <span class="is-size-6 pl-2 pt-1 has-text-weight-bold side-menu-custom">{{ 'COMMON.ESTIMATION' |
                translate}}</span>
        </div>
        <div class="content">
            <ul class="total-text" style="color: #AAD043;">
                {{estimationDetails.total | number:'1.2-2':'tr'}}
            </ul>
            <ul class="" style="color: #565f60; font-weight: bold;">
                {{estimationDetails.name}}
            </ul>
            <ul>
                {{estimationDetails.state}}
            </ul>
            
        </div>
    </div>
</div>
<div *ngIf="estimateTimeShowing && estimateTimeList.length != 0" class="mt-3 mb-0 box">
    <div class="services-aside">
        <div class="header">
            <img src="assets/svg/appoint-date.svg" />
            <span class="is-size-6 pt-1 has-text-weight-bold side-menu-custom">{{ 'COMMON.APPOINTMENT_PREFERENCES' | translate}}</span>
        </div>
        <div class="content">
           
            <ul class="" *ngFor="let item of estimateTimeList; index as i" style="font-weight: 400;">
                <div *ngIf="i==1" class="has-text-weight-bold pt-3">{{ 'SERVICE.TEXT_40' | translate}}</div>
                <div class="is-flex is-justify-content-space-between app-box">
                    <p>{{item.estimateTime }}</p>
                    <img *ngIf="i==1" class ="is-clickable app-del" src="../../../assets/svg/delete.svg" alt="" (click)="deleteAppointment(item)" style="height: 16px;"> 
                </div>
            </ul>
        </div>
    </div>
</div>
