import {Component, OnInit,NgZone,ViewChild, ElementRef} from '@angular/core';
import {FormControl} from '@angular/forms';
import {observable, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { Router,NavigationExtras} from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { Utils } from 'src/app/common/utils';
import { Constants } from 'src/app/Constants/constants';

@Component({
  selector: 'app-home-banner-one',
  templateUrl: './home-banner-one.component.html',
  styleUrls: ['./home-banner-one.component.scss',]
})
export class HomeBannerOneComponent implements OnInit {
  servicesControl = new FormControl();
  pincodeControl = new FormControl();

  servicesList: any[] = [];
  pincodes: string[] = [];
  tempServiceList:any

  servicesOptions: any = new Observable<string[]>();
  pincodeOptions: any = new Observable<String[]>();
  searchService: string = ''
  searchProvince: string = ''
  isGoEnabled = false;

  //current location
  private geoCoder: any;
  latitude: number = 0.0;
  longitude: number = 0.0;
  pincode: string = '';
  address: string = '';

  //populate text 
  index: number = 0;
  changingText: string= "iyi"
  changeTextList : Array<string> = [
  "iyi",
  "garantili", 
  "güvenilir",
  "ekonomik",
  "hızlı",
  "yakın",
  "etkili"
]

  @ViewChild('search')
  searchAddress: ElementRef | undefined;
  @ViewChild('searchMobile')
  searchMobileAddress: ElementRef | undefined;

  constructor(public translate: TranslateService,
              private sharedServices:SharedServiceService,
              private bookService: BookingServiceService,
              private route: Router,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private UtilsCalss: Utils) {
    // translate.setDefaultLang('en')
   }

  ngOnInit() {
    this.initMap();
    this.changingText = this.changeTextList[this.index];
    this.clearAddressData()
    this.changeText()
    this.fetchServicesAndZipCode();
  }

  private _servicesFilter(value: any): string[] {
    value = value == null ? '':value
    if(typeof value === 'object' && value != undefined){
      value = Object.keys(value).length != 0 ? value.name : '';
    }
    let tempServiceListWithStart = this.servicesList.filter((option: any) => option.name.toLowerCase().startsWith(value?.trim().toLowerCase()));
    let tempServiceListIncludes = this.servicesList.filter((option: any) =>{
      if(option.name.toLowerCase().includes(value?.trim().toLowerCase())){
        if(tempServiceListWithStart.some((item: { name: string; }) => item.name.toLowerCase() === option.name.toLowerCase())){
          return false
        }
        return true
      }
      return false
    });
    return [...tempServiceListWithStart, ...tempServiceListIncludes]
  }
  displayServices(value: any) {
    return value ? value.name : undefined;
  }

  private _pincodeFilter(value: any){
    localStorage.removeItem("address");
   value = value == null ? '':value
    if(typeof value === 'object' && value != undefined){
      value = Object.keys(value).length != 0 ? value.place : '';
    }
    let placeFilter = this.pincodes.filter((option: any) => {
      const searchTerm = value != undefined ? value.trim().toLowerCase() : '';
      const placeLowerCase = option.place.toLowerCase();
      if (placeLowerCase.startsWith(searchTerm)) {
        return true;
      }
      return false;
    });
    let provinceFilter = this.pincodes.filter((option: any) => {
      const searchTerm = value != undefined ? value.trim().toLowerCase() : '';
      const provinceLowerCase = option.province.toLowerCase();
      if (provinceLowerCase.startsWith(searchTerm)) {
        if(placeFilter.some((item: any) => item.place.toLowerCase() === option.place.toLowerCase())){
          return false
        }
        return true;
      }
      return false;
    });
   
    return [...placeFilter, ...provinceFilter]
  }

  formatService(input: string): string {
    if (!this.searchService || !input) {
      return input;
    }
    const regEx = new RegExp(this.searchService, 'gi');
    return input.replace(regEx, '<strong>$&</strong>');
  }
  formatProvince(input: string): string {
    if (!this.searchProvince || !input) {
      return input;
    }
    const regEx = new RegExp(this.searchProvince, 'gi');
    return input.replace(regEx, '<strong>$&</strong>');
  }
             
  displayProvince(value: any) {
    return value ? value.place? value.place+", "+value.province :value : undefined;
  }

  onInputPinChange(value: string){
    let address = localStorage.getItem("address")
    if(value == ''){
      this.isGoEnabled = false
      this.pincode = '';
      return
    }else{
      this.isGoEnabled = true 
    }
  }


  initMap(){
    this.mapsAPILoader.load().then(()=>{
      this.geoCoder = new google.maps.Geocoder;
      var southWest = new google.maps.LatLng(40.811404,28.595554);
      var northEast = new google.maps.LatLng(41.199239,29.428805);
      var istanbulBounds = new google.maps.LatLngBounds(southWest, northEast);

      var options = {      
        strictBounds: true,
        bounds: istanbulBounds,
        componentRestrictions: { country: "TR"},
        types: ['administrative_area_level_4']
      };
      let autoComplete = new google.maps.places.Autocomplete(this.searchAddress?.nativeElement,options);
      autoComplete.addListener("place_changed",() =>{
        this.ngZone.run(()=>{
          let place: google.maps.places.PlaceResult = autoComplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          if(place.address_components != undefined){
            let addressDetails = place.address_components;
            if(addressDetails.length > 0){
              addressDetails.forEach(element => {
                if(element.types[0].endsWith('postal_code')){
                  this.pincode = element.long_name;
                }
                if(element.types[0].endsWith('administrative_area_level_4')){
                  this.address = element.long_name
                }
                if(element.types[0].endsWith('administrative_area_level_2')){
                  this.address =this.address +" "+ element.long_name
                }
                if(element.types[0].endsWith('administrative_area_level_1')){
                  this.address =this.address+' - '+element.long_name
                }                
                localStorage.setItem("address",this.address);
              });
            }
          }
        })
      });
    })
  }

  setCurrentLocation(){
    let _this = this;
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        _this.latitude = position.coords.latitude;
        _this.longitude = position.coords.longitude;
        _this.getAddress(position.coords.latitude,position.coords.longitude)
      },function(err){
        alert("Location not enabled");  
      },{enableHighAccuracy: true,timeout : 5000});
    }else{
      alert("Location not enabled");  
    }
  }

  getAddress(latitude : any, longitude : any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results : any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.pincodeControl.setValue([results[0].formatted_address])
          if(results[0].address_components != undefined){
            let addressDetails = results[0].address_components;
            if(addressDetails.length > 0){
              addressDetails.forEach((element: any) => {
                if(element.types[0].endsWith('postal_code')){
                  this.pincode = element.long_name;
                }
                if(element.types[0].endsWith('street_number')){
                  this.address = element.long_name
                }
                if(element.types[0].endsWith('route')){
                  this.address = this.address+","+element.long_name
                }
                if(element.types[0].endsWith('sublocality_level_2')){
                  this.address = this.address+","+element.long_name
                }
                if(element.types[0].endsWith('political')){
                  this.address = this.address+","+element.long_name
                }
                if(element.types[0].endsWith('locality')){
                  this.address = this.address+","+element.long_name
                }
                if(element.types[0].endsWith('administrative_area_level_1')){
                  this.address =this.address+' - '+element.long_name
                }
              });
            }
          }
          localStorage.setItem("address",this.address);
        } 
      }
    
    });
  }

  setLocation(){
    if(localStorage.getItem('address') == undefined){
      if(this.pincodeControl.value != undefined && this.pincodeControl.value != '' && this.pincodeControl.value != null){
        let pincode = this.pincodeControl.value.place+", "+this.pincodeControl.value.province
        localStorage.setItem("address",pincode);
        }
    }
  }


  onClickGo(){
     if (this.servicesControl.value?.name != undefined && this.servicesControl.value?.name != '' && this.servicesControl.value?.name != null){
        let data = {
          service_id: this.servicesControl.value.id
        }
        this.bookService.getServiceList(data).subscribe({
          next: (res) => {
            this.sharedServices.setEndServiceData(res.data[0])

            if(localStorage.getItem('address') == undefined){
              if(this.pincodeControl.value != undefined && this.pincodeControl.value != '' && this.pincodeControl.value != null){
                let pincode = this.pincodeControl.value.place+", "+this.pincodeControl.value.province
                localStorage.setItem("address",pincode);
                }
            }
            this.pincode = localStorage.getItem('address') || '';
            if (this.pincode.length == 0 || this.pincode.includes("undefined")) {
              this.route.navigate(['/location'])
            } else if ((this.servicesControl.value !== null || this.servicesControl.value !== undefined || this.servicesControl.value !== '')) {
              // this.sharedServices.setPopularServiceData(this.servicesControl.value) 
              this.route.navigate(['/service'])
            } else {
              this.UtilsCalss.openErrorSnackBar(this.translate.instant('COMMON.PLEASE_SELECT_LOCATION'))
            }
  
          },
          error: (err) => {
          }
        })
      } else {
        if(localStorage.getItem('address') == undefined){
          if(this.pincodeControl.value != undefined && this.pincodeControl.value != '' && this.pincodeControl.value != null){
            let pincode = this.pincodeControl.value.place+", "+this.pincodeControl.value.province
            localStorage.setItem("address",pincode);
            }
        }
        this.pincode = localStorage.getItem('address') || '';
        if (this.pincode.length == 0 || this.pincode.includes("undefined")) {
          this.route.navigate(['/location'])
        } else if ((this.servicesControl.value !== null || this.servicesControl.value !== undefined || this.servicesControl.value !== '')) {
          // this.sharedServices.setPopularServiceData(this.servicesControl.value) 
          this.route.navigate(['/service'])
        } else {
          this.UtilsCalss.openErrorSnackBar(this.translate.instant('COMMON.PLEASE_SELECT_LOCATION'))
        }
      }
  }

  fetchServicesAndZipCode(){
    this.bookService.getServicesAndPincode().subscribe({
      next: (res)=> {
          if(res.statusCode == 1){  
             this.servicesList = res.service_list;
             this.pincodes = res.zipcode;
             this.servicesOptions = this.servicesControl.valueChanges.pipe(startWith(''),map(value => this._servicesFilter(value)),);
             this.pincodeOptions = this.pincodeControl.valueChanges.pipe(startWith(''),map(value => this._pincodeFilter(value)),);
          }
        },
      error: (err)=>{
      }
    })
  }


  changeText(){
    setTimeout(()=>{
      if(this.index < this.changeTextList.length){
        this.index++; 
        if(this.index === this.changeTextList.length -1){
          this.index = 0;
        }                
      this.changingText = this.changeTextList[this.index];
      }
      this.changeText()               
    },1500)
  }
  clearProvince(){
    this.searchProvince = ''
    localStorage.removeItem('address')
  }
  clearService(){
    this.searchService = ''
  }
  clearAddressData(){
    localStorage.removeItem(Constants.APP.SERVICE_LIST)
    localStorage.removeItem("address")
    localStorage.removeItem("zipcode")
    sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
    sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
    sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
    sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
    this.sharedServices.clearData()
    document.body.classList.remove('inner-stepper');
  }

}
