<app-signup-header></app-signup-header>

<div class="login-card column p-5">
    <h2 class="signin-title has-text-weight-bold">FAQ</h2>
    <div class="columns m-0">
        <div class="column p-0 is-3 sidebar-area">
            <nav class="">
                <ul class="pt-4">
                  <li *ngFor="let category of faqCategories" class="pb-4">
                    <a (click)="categoryChange(category)"  class="font-16 category-text" [ngClass]="selectedCategory?.id == category.id? 'is-active': ''">{{category.category_name}}</a>
                  </li>
                </ul>
            </nav>
        </div>
        <div class="column" style="background-color: #fafafa;">
            <div class="columns mb-0 px-5 pt-5 is-flex is-justify-content-space-between">
                <div class="column is-narrow">
                    <h2 class="has-text-weight-bold" style="font-size: 18px;">
                      {{selectedCategory?.category_name}}
                    </h2>
                    
                </div>
            </div>
            <div *ngIf="driverFaqDetails" class="columns m-0 is-flex is-flex-direction-column is-align-items-center px-5">

            <div *ngFor="let item of driverFaqDetails; let i = index" class="faq column w-100 py-1">
                <mat-expansion-panel [expanded]="currentStep == i" (opened)="setStep(i)" (closed)="currentStep != i" class="w-100">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="mr-0">
                           <p class="question has-text-weight-semibold">{{item.question}}</p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="columns m-0">
                      <p class="answer">{{item.answer}}</p>
                    </div>
                </mat-expansion-panel>
            </div>  
        </div>       
        </div>
    </div>
</div>
