import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { Utils } from 'src/app/common/utils';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Constants } from 'src/app/Constants/constants';
import { PassDataService } from 'src/app/services/pass-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, takeWhile } from 'rxjs';
import { Router,ActivatedRoute } from '@angular/router';
import { ViewDetailsComponent } from 'src/app/dialog/view-details/view-details.component';
import { SocketService } from 'src/app/services/socket.service';
import { FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
@Component({
  selector: 'app-service-request-estimates',
  templateUrl: './service-request-estimates.component.html',
  styleUrls: ['./service-request-estimates.component.scss']
})
export class ServiceRequestEstimatesComponent implements OnInit, OnDestroy {
  @ViewChild('otp1', { static: false }) otp1: any = ElementRef;
  @ViewChild('otp2', { static: false }) otp2: any = ElementRef;
  @ViewChild('otp3', { static: false }) otp3: any = ElementRef;
  @ViewChild('otp4', { static: false }) otp4: any = ElementRef;
  @Output() onClickShowMapButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() isEstimateRoute = new EventEmitter<boolean>();
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  private serviceData: any = {}
  vechicleEstimateDetails: any = []
  serviceList: any = []
  isDataLoading = false;
  displayMatches: string = "";
  isAlive: boolean = true;
  isShowingMap: boolean = false
  showMapText: string = '';
  locationArray: any = []
  estimate_item: any = {};
  isFromLoginSaveEstimate: boolean = false
  isFromDashBoardViewDetails: boolean = false
  viewDetailsEstimate: any = {}
  quotation : any
  address : any
  applied_garage_details:any = []
  quoteId:any;
  userData: any;
  userId: any;
  checkStatus: boolean = false
  ph: any;
  isFirstNameValid: boolean = true
  isLastNameValid: boolean = true
  isEmailValid: boolean = true
  isPhnValid: boolean = true
  btnDisabled: boolean = false
  messagePopup: boolean = false
  showMore: boolean = false
  isOtpSend: boolean = false
  expire!: any;
  otpDetails: OTP;
  isLoading: boolean = false
  isMobile: boolean = false
  newUserNotification: any;
  showMember_approval: boolean = false
  showContact_confirm: boolean = false
  showCommercial_msg: boolean = false
  checkMember_approval : boolean = false
  checkCommercial_msg : boolean = false
  newRequest:any;
  mapOpen: boolean = false;
  isScroll: boolean = false;


  private clearSearchSubscription!: Subscription;

  constructor(private sharedService: SharedServiceService,
    private BookService: BookingServiceService,
    private _snackBar: MatSnackBar,
    private utils:Utils,
    private utilsClass: Utils,
    private Translate: TranslateService,
    private PassService: PassDataService,
    public dialog: MatDialog,
    private Router: Router,
    private socketService: SocketService,
    private mapApiLoader : MapsAPILoader,
    private route : ActivatedRoute
    ) {
      this.otpDetails = {} as OTP
  }
  firstNameFormControl = new FormControl();
  lastNameFormControl = new FormControl();
  emailIdFormControl = new FormControl();
  phoFormControl = new FormControl();
  memberApprovalFormControl = new FormControl();
  contactConfirmFormControl = new FormControl();
  commercialMsgFormControl = new FormControl();

  ngOnInit(): void {
    this.isEstimateRoute.emit(true);
    this.address = localStorage.getItem('address');
    this.userData = JSON.parse(localStorage.getItem(Constants.APP.SESSION_USER_DATA) || '{}')
    if (this.userData !== undefined) {
      this.userId=this.userData.user_id
    }
    
    if (history.state != undefined) {
      if (history.state.estimateViewItem) {
        this.viewDetailsEstimate = history.state.estimateViewItem
        this.quoteId = this.viewDetailsEstimate.id
        sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(this.viewDetailsEstimate.vehicle_details[0]))
        if(history.state.estimateQuotationItem){
        this.quotation = this.viewDetailsEstimate;
        }
        this.isFromDashBoardViewDetails = true
        this.fetchAddressFromZipcode()
        this.route.queryParams.subscribe(params =>{
          this.newRequest = params['garage_id']
        })
      }else if(history.state.isFromLoginEstimate){
        this.isFromLoginSaveEstimate = true   
        this.fetchVehcile()
        if(history.state.message){
          this.messagePopup = true
        }
      }
      else if(history.state.isFromSignupSaveEstimate){
        this.isFromLoginSaveEstimate =true
        this.fetchVehcile()
      }
    }
     this.quoteId = sessionStorage.getItem(Constants.APP.QUOTE_ID) || this.viewDetailsEstimate.id

    this.Translate.get("COMMON.SHOW_MAP").subscribe((data) => {
      this.showMapText = data
    })
    if(!this.isFromLoginSaveEstimate){
      this.getEstimateData('')
    }
    this.PassService.getBackPressed().pipe(takeWhile(() => this.isAlive)).subscribe((res: any) => {
      this.previousStep()
    })
    this.PassService.getChangeMapText().pipe(takeWhile(() => this.isAlive)).subscribe((data: any) => {
      this.onClickShowMap()
    })
    this.sharedService.estimateScroll$.pipe(takeWhile(() => this.isAlive)).subscribe((data: any) => {
      this.isScroll = data
    })
    this.clearSearchSubscription = this.PassService.getGarageValue().subscribe((data: any) => {
      this.vechicleEstimateDetails.forEach((element: any) => {
        if (element.id == data.garage_id) {
          this.estimate_item = element          
        }
      });
      this.openViewDetails()
    })

    this.PassService.getSaveEstimateListener().pipe(takeWhile(() => this.isAlive)).subscribe((data: any) => {
      this.saveEstimateData(data)
    })
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 768;
    });    

  }

  ngAfterViewInit() { setTimeout(() => { 
    this.sharedService.updateData('3');
  }, 500) }

  previousStep() {
    this.sharedService.updateData('2');
    this.Router.navigate(['/service/vehicle'])
  }

  onInputFirstNameChange(value: string) {
    if (value.length == 0) {
      this.isFirstNameValid = false
    } else {
      this.isFirstNameValid = true
    }
    this.btnValidate()
  }
  onInputLastNameChange(value: string) {
    if (value.length == 0) {
      this.isLastNameValid = false
    } else {
      this.isLastNameValid = true
    }
    this.btnValidate()
  }
  onInputEmailChange(value: string) {
    this.isEmailValid = this.utilsClass.validateEmail(value)
    this.btnValidate()
  }

  onInputPhChange(event:any) {
    var newVal = event.replace(/\D/g, '');
  if(newVal.length === 0){
      this.isPhnValid = false
    } else{
      this.isPhnValid = true
      if (newVal.length == 0) {
        newVal = '';
      }else if (newVal.length <= 1) {
        if(newVal !=0){
          newVal = '0' + newVal
        }
        newVal =newVal.replace(/^(\d{0,1})/, '$1');
     } 
      else if (newVal.length <= 4) {
         newVal =newVal.replace(/^(\d{0,1})(\d{0,3})/, '$1 ($2)');
      } else if (newVal.length <= 7) {
        newVal =newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})/, '$1 ($2) $3');
   
      } else if (newVal.length <= 11) {
        newVal =newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, '$1 ($2) $3 $4 $5');
      } else {
        newVal =newVal.substring(0, 11);
        newVal =newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4 $5');   
      }
        this.ph = newVal   
    }
    this.btnValidate()
  }

  btnValidate() {
    if (this.firstNameFormControl.value != null && this.firstNameFormControl.value != '' && this.lastNameFormControl.value != null && this.lastNameFormControl.value != '' && this.emailIdFormControl.value != null && this.emailIdFormControl.value != '' && this.phoFormControl.value != null && this.phoFormControl.value != '' &&  this.phoFormControl.value.length > 16
    && this.memberApprovalFormControl.value == true && this.contactConfirmFormControl.value == true) 
    {
      this.btnDisabled = true
    } else {
      this.btnDisabled = false
    }
  }

  getEstimateData(data:any) {
    this.applied_garage_details = []
    this.isDataLoading = true;
    if (!this.isFromDashBoardViewDetails) {
      this.loggedInUserPayload()
    } else {
      this.viewDetailsPayloadEstimate()
    }
    if(this.newRequest){
      delete this.serviceData.quote_id
    }
    this.BookService.getDirectQuotes(this.serviceData).subscribe({
      next: (res) => {
        this.isDataLoading = false
        if (res.statusCode == 1) {
          this.vechicleEstimateDetails = res.garages
          let serviceList:any = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE);
          if (serviceList != null) {
            let serviceListDetails = JSON.parse(serviceList)
            let garage_count =  this.vechicleEstimateDetails.filter((item:any) => {
            return item.invoice_quote.items.every((subItem:any) => subItem.base_price !== 0)})
            serviceListDetails.garage_count = garage_count.length
            sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(serviceListDetails))
          }

          this.vechicleEstimateDetails = this.vechicleEstimateDetails.filter((item:any) => {
            return item.invoice_quote.items.every((subItem:any) => subItem.base_price !== 0);
          });
          
          this.vechicleEstimateDetails.forEach((item: any) => {
            if (item.invoice_quote == null) {
              item.quote = {
                total: 0,
                subtotal: 0
              }
            } else if (item.invoice_quote != null) {
              if(item.invoice_quote.total > 0){
                item.invoice_quote.total = Number(item.invoice_quote.total)                
              }
              else{
                item.invoice_quote.total = 0
              }
            }
            this.locationArray.push({
              lat: item.latitude,
              lng: item.longitude,
              amount: item.invoice_quote,
              garage_name: item.name,
              garage_id: item.id,
              distance: item.distance
            })

            if(item.reviews != null){
              item.rating = {}
              let totalRating = 0;
              item.reviews.forEach((element:any) => {
                 totalRating += element.ratings;
              });
              item.rating.cum_rating = totalRating / item.reviews.length
              item.rating.totalRating = item.reviews.length
            }
              if(item.id != null){
                this.applied_garage_details.push({
                  garage_id : item.id,
                  status : 'applied',
                  service_details : this.serviceData.service
                })
              }
              item.completely = true
              if(item.invoice_quote.items){
                item.invoice_quote.items.forEach((element:any) => {
                  if(element.base_price == 0)
                  item.completely = false
                });
              }
          })
          localStorage.setItem(Constants.APP.APPLIED_GARAGES_ID, JSON.stringify(this.applied_garage_details))
          if(this.userData.user_id && (this.viewDetailsEstimate.status == 'estimate' || this.viewDetailsEstimate.status == 'Awaiting' || !this.viewDetailsEstimate?.status || this.viewDetailsEstimate.status == 'cancelled')){
            if(data != 'message')
            this.PassService.setSaveEstimateListener('')
          }

          this.Translate.get('COMMON.QUOTE_ESTIMATE_TEXT').subscribe((res: string) => {
            this.displayMatches = res
          });
          if(history.state.from == 'map'){
            this.onClickShowMap()
          }
          this.ratingSort()
        }
      },
      error: (err) => {
        this.isDataLoading = false
      }
    })
  }

  ratingSort() {
    this.addClass('btnRating')
    this.removeclicked('btnDistance')
    this.removeclicked('btnPrice')
    this.vechicleEstimateDetails.sort((a: any, b: any) => {
      return parseFloat(a.rating.cum_rating) > parseFloat(b.rating.cum_rating) ? -1 : 1
    });
  }
  distanceSort() {
    this.removeclicked('btnRating')
    this.addClass('btnDistance')
    this.removeclicked('btnPrice')
    this.vechicleEstimateDetails.sort((a: any, b: any) => {
      return parseFloat(a.distance) - parseFloat(b.distance)
    });
  }
  priceSort() {
    this.removeclicked('btnRating')
    this.removeclicked('btnDistance')   
    this.addClass('btnPrice')
    this.vechicleEstimateDetails.sort((a: any, b: any) => {
      a.quote.pre_subtotal = a.quote.pre_subtotal === undefined ? 0 : a.quote.pre_subtotal
      b.quote.pre_subtotal = b.quote.pre_subtotal === undefined ? 0 : b.quote.pre_subtotal
      return parseFloat(a.quote.pre_subtotal) > parseFloat(b.quote.pre_subtotal) ? 1 : -1
    });  
  }  

  addClass(id: string) {
    let btnRating = document.getElementById(id);
    if (btnRating != null) {
      btnRating.classList.remove('is-light')
      btnRating.classList.add('is-success')
    }
  }
  removeclicked(id: string) {
    let btnRating = document.getElementById(id);
    if (btnRating != null) {
      btnRating.classList.remove('is-success')
      btnRating.classList.add('is-light')
    }
  }
  onClickShowMap() {
    if (!this.isShowingMap && this.locationArray.length !=0) {
      console.log(this.locationArray);
      
      this.PassService.setShowMapView(true)
      this.isShowingMap = true
      sessionStorage.setItem(Constants.APP.LOCATION_ARRAY, JSON.stringify(this.locationArray))
      this.Translate.get("COMMON.HIDE_MAP").subscribe((data) => {
        this.showMapText = data
      })
    } else {
      this.PassService.setShowMapView(false)
      this.isShowingMap = false
      if(this.locationArray.length === 0){
        this.utilsClass.openErrorSnackBar(this.Translate.instant('ERROR_MESSAGE.error_no_data_found'))
      }
      this.Translate.get("COMMON.SHOW_MAP").subscribe((data) => {
        this.showMapText = data
      })
    }
  }

  openViewDetails() {
    this.estimate_item.quote_id = this.quoteId  
    this.Router.navigate(['view-quote'], {state: { data: { dataItem: this.estimate_item, quotation: this.quotation}}, queryParams: {from: 'map'}})
  }

  saveEstimateData(data: string) {
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    if (userdata == undefined) {
      this.Router.navigate(['/auth'], { state: { isFromEstimateValue: true } })
    }else{
      if(data){
        this.saveEstimateDataFromLogin(data)
      }else{
        this.saveEstimateDataFromLogin('')
      }
    }
  }

  getServiceListPayload() {
    this.serviceList = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE);
    this.serviceList = JSON.parse(this.serviceList)
    let serviceDetails: any = localStorage.getItem(Constants.APP.SERVICE_LIST)
    serviceDetails = JSON.parse(serviceDetails)
    let serviceDetailsList: any[] = [];
    serviceDetails?.forEach((element: any) => {
      if(!element.question.fromMaster){
      let service = {
        id: element.answer.id,
        comment: element.comment,
        is_from_all_service: element.is_from_all_service,
        service_id : element.answer.service_id,
        selected_answer : element.selected_answer
      }
      serviceDetailsList.push(service)
    }
    });
    return serviceDetailsList
  }

  loggedInUserPayload() {
    let serviceData = this.getServiceListPayload()
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    this.serviceData = {
      zip_code: this.address,
      vechicle: {
        "make_id": this.serviceList.vechile_make,
        "model_id": this.serviceList.vechile_model,
        "trim_id" : this.serviceList.vechile_engine,
        "generation_id": this.serviceList.generation,
        "series_id": this.serviceList.series,
        "fuel_type": this.serviceList.fuel_type,
        "year": Number(this.serviceList.model_year)
      },
      service: serviceData
    }
    if (userdata != undefined) {
      let userDetails = JSON.parse(userdata)
      let serviceList = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE);
      if (serviceList != null) {
        let serviceListDetails = JSON.parse(serviceList)
        this.serviceData = {
          zip_code: this.address,
          vechicle_id: serviceListDetails.vechicle_id,
          service: serviceData,
          user_id: userDetails.user_id
        }
        if(this.quoteId){
          this.serviceData.quote_id = this.quoteId
        }
        
      }
    }
  }

  viewDetailsPayloadEstimate() {
    let serviceList: any = []
    this.viewDetailsEstimate.items.forEach((element: any) => {
      serviceList.push({
        "id": element.service_id,
        "comments": element.notes,
        "selected_answer":element.selected_answer,
        "is_from_all_service": element.is_service_from_master ? true : false,
        "service_id" : element.service_master_id
      })
    });
    this.serviceData = {
      "zip_code": this.viewDetailsEstimate.zip_code,
      "user_id": this.viewDetailsEstimate.user_id,
      "vechicle_id": this.viewDetailsEstimate.vechicle_id,
      "service": serviceList
    }

    if(this.quoteId){
      this.serviceData.quote_id = this.quoteId
    }
   
    sessionStorage.setItem(Constants.APP.QUOTE_ID,this.viewDetailsEstimate.quote_id);
    this.PassService.setUpdateFromEstimate(false)
  }

  fetchAddressFromZipcode() {
    
    this.mapApiLoader.load().then(()=>{
    var geocoder = new google.maps.Geocoder();
    var southWest = new google.maps.LatLng(40.811404,28.595554);
    var northEast = new google.maps.LatLng(41.199239,29.428805);
    var istanbulBounds = new google.maps.LatLngBounds(southWest, northEast);
  
    let _this = this
    let address = ''
    let pincode = ''
    geocoder.geocode({ address: this.viewDetailsEstimate.zip_code, componentRestrictions: { country: "TR" }, bounds: istanbulBounds },  (result, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        let addressDetails = result[0].address_components;
        
        if (addressDetails.length > 0) {
          addressDetails.forEach(element => {
            if (element.types[0].endsWith('postal_code')) {
              pincode = element.long_name;
            }
            if (element.types[0].endsWith('administrative_area_level_4')) {
              address = element.long_name
            }
            if (element.types[0].endsWith('administrative_area_level_2')) {
              address = address + " " + element.long_name
            }
            if (element.types[0].endsWith('administrative_area_level_1')) {
              address = address + ' - ' + element.long_name
            }
            localStorage.setItem("zipcode", pincode);
            localStorage.setItem("address", address);
          });
           
          _this.sharedService.setAddress(address)
          _this.fetchVehcileByID(_this.viewDetailsEstimate.vechicle_id)
        }
      }
      if(address.length == 0){
        address = this.viewDetailsEstimate.zip_code
       localStorage.setItem("address", address)
       _this.sharedService.setAddress(address)
     }
    });
  }).catch((err)=>{
    console.log(err)
  })
    let serviceList:any = []
    this.viewDetailsEstimate.items.forEach((element:any) => {
      let service={
        question:{
          "id":element.service_id,
          "answer": element.name,
          "service_id": element.service_master_id
        },
        answer:{
          "id": element.service_id,
          "answer":element.answer,
          "service_id": element.service_master_id

        },
        comment: element.notes,
        selected_answer:element.selected_answer,
        is_from_all_service: element.is_service_from_master ? true : false
      }
      serviceList.push(service)
    });
    localStorage.setItem(Constants.APP.SERVICE_LIST, JSON.stringify(serviceList))
    this.sharedService.updateServiceList("update")
  }

  fetchVehcileByID(id:any) {
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    let userDetails;
    if (userdata != undefined) {
      userDetails = JSON.parse(userdata)
    }
    this.BookService.fetchVehicleByUser(userDetails.user_id).subscribe({
      error: (error) => {
      }, // errorHandler 
      next: (res) => {
        if (res.statusCode == 1) {
          let userVehcileData = res.vechicle
            userVehcileData.forEach((element: any) => {
              if (element.vechicle_id == id) {
                element.vechicle_id = element.vechicle_id
                element.model_year = element.start_production_year
                element.vechile_make_name = element.make_name
                element.vechile_model_name = element.model_name
                element.vechile_make = element.make_id
                element.vechile_model = element.model_id
                element.generation = element.generation_id
                element.series = element.series_id
                element.img = element.logo
                sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(element))
              }
            });
            this.PassService.getVehicleData()
          }
        }, // nextHandler
    });
  }

  saveVehcileForUserData() {
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    let userDetails;
    if (userdata != undefined) {
      userDetails = JSON.parse(userdata)
    }
    let vehicleData = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE)
    if (vehicleData != null) {
      let vehicleDataDetails = JSON.parse(vehicleData)
      let payload = {
        "user_id": userDetails.user_id,
        "make_id": vehicleDataDetails.vechile_make,
        "model_id": vehicleDataDetails.vechile_model,
        "series_id": vehicleDataDetails.series,
        "generation_id": vehicleDataDetails.generation,
        "trim_id": vehicleDataDetails.vechile_engine,
        "fuel_type": vehicleDataDetails.fuel_type,
        "year": vehicleDataDetails.model_year,
        "transmission_type": vehicleDataDetails.transmission_type,
        "mileage": Number(vehicleDataDetails.current_mileage)
      }
      this.BookService.addVehicleByUser(payload).subscribe({
        error: (error) => {
        },
        next: (res) => {
          if (res.statusCode == 1) {
            vehicleDataDetails.vechicle_id = res.vechicle_id
            sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(vehicleDataDetails))
            this.saveEstimateDataFromLogin('')
          }
        },
      });
    }
  }

  saveEstimateDataFromLogin(data: string) {
    let serviceData = this.getServiceListPayload()
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    let userDetails: any;
    if (userdata != undefined) {
      userDetails = JSON.parse(userdata)
    }
    let vehicleData = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE)
    if (vehicleData != null) {
      let vehicleDataDetails = JSON.parse(vehicleData)
      let payload:any = {
        "user_id": userDetails.user_id,
        "vechicle_id": vehicleDataDetails.vechicle_id,
        "garage_count": vehicleDataDetails.garage_count || this.viewDetailsEstimate.garage_count || 0,
        "status": "estimate",
        service: serviceData
      }
      let quoteId = sessionStorage.getItem(Constants.APP.QUOTE_ID)
      if(quoteId != undefined){
        payload.quote_id = Number(quoteId)
      }else{
        payload.zip_code = this.address
      }
      if(this.newRequest){
        delete payload.quote_id
        payload.garage_count = 0
        payload.zip_code = this.viewDetailsEstimate.zip_code
      }
       
      let applied_data = JSON.parse(localStorage.getItem(Constants.APP.APPLIED_GARAGES_ID) || '{}')
      payload.applied_garage_details = applied_data
      this.BookService.saveEstimate(payload).subscribe({
        next: (res) => {
          this.isDataLoading = false
          this.quoteId = res?.data?.quote_id
          let vehicleData = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE)
          if (vehicleData != null) {
            let vehicleDataDetails = JSON.parse(vehicleData)
            vehicleDataDetails.quote_id = res?.data?.quote_id
            sessionStorage.setItem(Constants.APP.QUOTE_ID,vehicleDataDetails.quote_id)
            sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(vehicleDataDetails))
            this.PassService.setUpdateFromEstimate(false)
            
            if(this.messagePopup){
              this.getEstimateData('message')
            }
            if(this.isFromLoginSaveEstimate && !this.messagePopup){
              this.Router.navigate(['/dashboard'])
            }
            else{
              // this.sharedService.openMessagePopup()
            }

            let notification_data = {
              types : "New Job",
              reference_id : res?.data?.quote_id,
              title : `${userDetails.first_name} ${userDetails.last_name}`,
              content : `Job Id: ${res.data.quote_id} newly registered on Arabama`
            }
            this.socketService.emit('adminNotification',notification_data)
            if(res.notification_garages){
              res.notification_garages.forEach((notification_data: any) => {
                this.socketService.emit('garageNotificationById',notification_data)
              });
            }     
          }
        },
        error: (err) => {
          this.PassService.setUpdateFromEstimate(true)
          this.utilsClass.openErrorSnackBar(this.Translate.instant('COMMON.ESTIMATION_NOT_SAVED'))
          this.isDataLoading = false
        }
      })
    }
  }

  fetchVehcile() {
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    let userDetails;
    if (userdata != undefined) {
      userDetails = JSON.parse(userdata)
    }
    this.BookService.fetchVehicleByUser(userDetails.user_id).subscribe({
      error: (error) => {
          this.saveVehcileForUserData()
      }, // errorHandler 
      next: (res) => {
        if (res.statusCode == 1) {
          let userVehcileData = res.vechicle
          let vehicleData = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE)
          if (vehicleData != null) {
            let vehicleDataDetails = JSON.parse(vehicleData)
            let isExists = false
            userVehcileData?.forEach((element: any) => {
              let modelYear = vehicleDataDetails.model_year || '';
              if (element.start_production_year === modelYear && element.make_id == vehicleDataDetails.vechile_make && element.vechile_model == vehicleDataDetails.model_id  && element.trim_id == vehicleDataDetails.vechile_engine) {
                isExists = true
                element.vechicle_id = element.vechicle_id
                element.model_year = element.start_production_year
                element.vechile_make_name = element.make_name
                element.vechile_model_name = element.model_name
                element.vechile_make = element.make_id
                element.vechile_model = element.model_id
                element.generation = element.generation_id
                element.series = element.series_id
                element.img = element.logo
                element.garage_count = vehicleDataDetails.garage_count || this.viewDetailsEstimate.garage_count
                sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(element))
              }
            });
            if (!isExists) {
              this.saveVehcileForUserData()
            }else{
              this.saveEstimateDataFromLogin('')
            }
          }
        }
      }, // nextHandler
    });
  }
  saveEstimate() {
    this.Router.navigate(['/auth'], { state: { isFromEstimateValue: true } })
  }
  serviceChanged(event: any) {
    if(event.id === 'ratings'){
      this.ratingSort()
    } else if (event.id === 'distance'){
      this.distanceSort()
    } else if (event.id === 'price'){
      this.priceSort()
    }
  }

  numberOnly(event: any): void {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/\D/g, ''); 
  }
  verifyOTP() {
    if(this.otpDetails.otp1 && this.otpDetails.otp2 && this.otpDetails.otp3 && this.otpDetails.otp4){
    this.addLoader()
    let userOTP = this.otpDetails.otp1 + this.otpDetails.otp2 + this.otpDetails.otp3 + this.otpDetails.otp4
  
    let payload = {
      "first_name": this.firstNameFormControl.value,
      "last_name": this.lastNameFormControl.value,
      "email": this.emailIdFormControl.value,
      "phone_no": this.phoFormControl.value.replace(/[(\s)]/gi, ''),
      "notification": this.checkStatus ? 1 : 0,
      "member_approval": this.checkMember_approval ? 1 : 0,
      "commercial_message": this.checkCommercial_msg ? 1 : 0,
      "otp" : userOTP
    }
    this.BookService.authUserRegistration(payload).subscribe(
      (res)=>{
        if(res.statusCode ===1){
          let user_data = res.data
          let notification_data = {
              types : "New Driver",
              reference_id : user_data.user_id,
              title : `${user_data.first_name} ${user_data.last_name}`,
              content : `${user_data.first_name} ${user_data.last_name} newly registered on Arabama`
          }
          this.isLoading = false
          this.utilsClass.openSuccessSnackBar(this.Translate.instant(res.message))
          localStorage.setItem(Constants.APP.SESSION_USER_DATA, JSON.stringify(res.data))
          localStorage.setItem(Constants.APP.SESSION_ID, JSON.stringify(res.data.session_id))
          this.isFromLoginSaveEstimate = true   
          this.fetchVehcile()
          this.removeLoader()  
        }
      },(err =>{
        this.removeLoader()
        this.utilsClass.openErrorSnackBar(this.Translate.instant(err.error.message))
      }))
    }
  }
  backspace(event: any, otpTab: number) {
    if (event.key == "Backspace") {
      if (otpTab === 4) {
        if (this.otpDetails.otp4 == '') {
          this.otp3.nativeElement.focus()
        }
      } else if (otpTab === 3) {
        if (this.otpDetails.otp3 == '') {
          this.otp2.nativeElement.focus()
        }
      } else if (otpTab === 2) {
        if (this.otpDetails.otp2 == '') {
          this.otp1.nativeElement.focus()
        }
      }
    }
  }
  
  tabChange(val: number) {
    let current_id = 'otp' + val
    val = val + 1
    let id = 'otp' + val
    let ele = document.getElementById(id) as HTMLInputElement;
    let current_ele = document.getElementById(current_id) as HTMLInputElement;
    if (current_ele.value != '') {
      ele?.focus()
    } else {
      current_ele?.focus()
    }
  }
  sendOtp(a: string) {
    if (a === 'Resend') {
      // this.isLoading = true;
    }

    this.addLoader()
    document.getElementById('btnLogin')?.classList.add('is-loading')
          this.otpDetails.otp1 = '';
          this.otpDetails.otp2 = '';
          this.otpDetails.otp3 = '';
          this.otpDetails.otp4 = '';
          this.otptimer()
          let payload = {
            "first_name": this.firstNameFormControl.value,
            "last_name": this.lastNameFormControl.value,
            "email": this.emailIdFormControl.value,
            "phone_no": this.phoFormControl.value.replace(/[(\s)]/gi, ''),
            "notification": this.checkStatus ? 1 : 0,
            "member_approval": this.checkMember_approval ? 1 : 0,
            "commercial_message": this.checkCommercial_msg ? 1 : 0,
          }
          this.BookService.saveUserRegistration(payload).subscribe(
            (res)=>{
              if(res.statusCode ===1){
                this.isOtpSend = true
                this.removeLoader()
              }
            },(err =>{
              this.removeLoader()
              this.utilsClass.openErrorSnackBar(this.Translate.instant(err.error.message))
            }))
  }

  getCheckboxValue(e: Event){
    const checkbox = e.target as HTMLInputElement;    
    this.checkStatus = checkbox.checked;
    this.btnValidate()
    
  }
  memberCheckbox(e: Event){
    const checkbox = e.target as HTMLInputElement;    
    this.checkMember_approval = checkbox.checked;
    this.btnValidate()    
  }
  messageCheckbox(e: Event){
    const checkbox = e.target as HTMLInputElement;    
    this.checkCommercial_msg = checkbox.checked;
    
  }
  removeLoader(){
    const button = document.getElementById('btnSignUp');
    button?.classList.remove('is-loading');
  }
  addLoader(){
    const button = document.getElementById('btnSignUp');
    button?.classList.add('is-loading');
  }
  otptimer() {

    var m = "03";
    var s = "00";
  
    const timer = () => {
        this.expire = m + ':' + s;
      if (parseInt(s) == 0) {
        s = "59";
      } else {
        s = sec(parseInt(s));
      }
      if (parseInt(m) >= 0 && parseInt(s) == 59) {
        m = sec(parseInt(m))
      }
      if (parseInt(m) >= 0) {
        setTimeout(function () {
          timer();
        }, 1000);
        return;
      }
    }
  
    timer();
  
  }
  ngOnDestroy(): void {
    this.isEstimateRoute.emit(false);
    this.PassService.setShowMapView(false)
    this.isAlive = false
    this.clearSearchSubscription.unsubscribe();
  }

  signup(){
    this.Router.navigate(['/signup'],{queryParams : {isFromSignupSaveEstimate : true}})
  }
}
function sec(a: number): string {

  if ((a - 1) < 10 && (a - 1) >= 0) return "0" + (a - 1) + "";
  return (a - 1) + ""
  }
  
  interface OTP {
  otp1: string,
  otp2: string,
  otp3: string,
  otp4: string
  }