import { environment } from 'src/environments/environment';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout/layout.component';
import { IndexComponent } from './pages/index/index.component';
import { HeaderComponent } from './components/header/header.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeSearchBarComponent } from './components/home-search-bar/home-search-bar.component';
import { CardOneServicesListComponent } from './components/card-one-services-list/card-one-services-list.component';
import { CardTwoBookingStepsComponent } from './components/card-two-booking-steps/card-two-booking-steps.component';
import { CardTwoBookingStepsTwoComponent } from './components/card-three-booking-steps-two/card-two-booking-steps-two.component';
import { CardFourGuaranteedServiceComponent } from './components/card-four-guaranteed-service/card-four-guaranteed-service.component';
import { CardFiveCommentsComponent } from './components/card-five-comments/card-five-comments.component';
import { HomeBannerOneComponent } from './components/home-banner-one/home-banner-one.component';
import { CommentsLineItemComponent } from './components/comments-line-item/comments-line-item.component';
import { FooterComponent } from './components/footer/footer.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ServiceRequestComponent } from './pages/service-request/service-request.component';
import { ServiceStepperComponent } from './components/service-stepper/service-stepper.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { StepperComponent } from './components/stepper/stepper.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AgmCoreModule } from '@agm/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { AgmDirectionModule } from 'agm-direction';   
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu'


import { StepServicesRotorsOneComponent } from './components/step-services-rotors-one/step-services-rotors-one.component';
import { StepServicesRotorsTwoComponent } from './components/step-services-rotors-two/step-services-rotors-two.component';
import { StepServicesRotorsThreeComponent } from './components/step-services-rotors-three/step-services-rotors-three.component';
import { PopularServicesRightNavListComponent } from './components/popular-services-right-nav-list/popular-services-right-nav-list.component';
import { ServiceRequestVehicleComponent } from './components/service-request-vehicle/service-request-vehicle.component';
import { ServiceRequestLayoutComponent } from './layout/service-request-layout/service-request-layout.component';
import { ServiceRequestEstimatesComponent } from './components/service-request-estimates/service-request-estimates.component';
import { EstimateLineItemComponent } from './components/estimate-line-item/estimate-line-item.component';
import { ServiceRequestBookComponent } from './components/service-request-book/service-request-book.component';
import { ServiceRequestCalenderLineItemComponent } from './components/service-request-calender-line-item/service-request-calender-line-item.component';
import { ServiceRequestConfirmComponent } from './components/service-request-confirm/service-request-confirm.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ServicesListComponent } from './components/services-list/services-list.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { RatingModule } from 'ng-starrating';
import { NoDataFoundComponent } from './components/no-data-found/no-data-found.component';
import { LocationSelectComponent } from './components/location-select/location-select.component';
import { LocationComponent } from './components/location/location.component';
import { CurrentLocationComponent } from './components/current-location/current-location.component';
import { ExtistingVehicleComponent } from './components/extisting-vehicle/extisting-vehicle.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UserHeaderComponent } from './components/user-header/user-header.component';
import { ViewDetailsComponent } from './dialog/view-details/view-details.component';
import { EstimateTabComponent } from './tabs/estimate-tab/estimate-tab.component';
import { ReviewsTabComponent } from './tabs/reviews-tab/reviews-tab.component';
import { LocationTabComponent } from './tabs/location-tab/location-tab.component';
import { CustomNativeDateAdapter } from './adapter/CustomNativeDateAdapter';
import { MapEstimateComponent } from './pages/map-estimate/map-estimate.component';
import { MapViewComponent } from './components/map-view/map-view.component';
import { MapQuoteViewComponent } from './components/map-quote-view/map-quote-view.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AccountInfoComponent } from './tabs/account-info/account-info.component';
import { PasswordInfoComponent } from './tabs/password-info/password-info.component';
import { PaymentComponent } from './tabs/payment/payment.component';
import { VehicleComponent } from './tabs/vehicle/vehicle.component';
import { ReviewsComponent } from './tabs/reviews/reviews.component';
import { SafePipe } from './custom/SafePipe';
import { ReviewsLineItemComponent } from './components/reviews-line-item/reviews-line-item.component';
import { LocationDirectionComponent } from './dialog/location-direction/location-direction.component';
import { NewLocationSearchComponent } from './components/new-location-search/new-location-search.component';
import { ExistingVehcileLineComponent } from './components/existing-vehcile-line/existing-vehcile-line.component';
import { VehcileDeleteConfirmDialogComponent } from './dialog/vehcile-delete-confirm-dialog/vehcile-delete-confirm-dialog.component';
import { SignupHeaderComponent } from './components/signup-header/signup-header.component';
import { NewLoginUiComponent } from './pages/new-login-ui/new-login-ui.component';
import { DashboardEstimateLineItemComponent } from './components/dashboard-estimate-line-item/dashboard-estimate-line-item.component';
import { CommonAlertDialogComponent } from './dialog/common-alert-dialog/common-alert-dialog.component';
import { ServiceHistoryComponent } from './tabs/service-history/service-history.component';
import { VehcileEditConfirmDialogComponent } from './dialog/vehcile-edit-confirm-dialog/vehcile-edit-confirm-dialog.component';
import { AddReviewsComponent } from './dialog/add-reviews/add-reviews.component';
import { MessageComponent } from './components/message/message.component';
import { MsgDatePipe } from './pipes/msg-date.pipe';
import { UploadProfileImageComponent } from './components/upload-profile-image/upload-profile-image.component';
import { HelpComponent } from './pages/help/help.component';
import { SideNotificationComponent } from './components/side-notification/side-notification.component';
import { ViewGarageComponent } from './dialog/view-garage/view-garage.component';
import { ViewQuoteComponent } from './components/view-quote/view-quote.component';
import { JobDetailsComponent } from './components/job-details/job-details.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { LegalPolicyComponent } from './pages/legal-policy/legal-policy.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import { TurkeyCurrencyPipe } from './pipes/turkey-currency.pipe';
import { GarageSelectionDialogComponent } from './dialog/garage-selection-dialog/garage-selection-dialog.component';

registerLocaleData(localeTr);

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    IndexComponent,
    HeaderComponent,
    NavbarComponent,
    HomeSearchBarComponent,
    CardOneServicesListComponent,
    CardTwoBookingStepsComponent,
    CardTwoBookingStepsTwoComponent,
    CardFourGuaranteedServiceComponent,
    CardFiveCommentsComponent,
    HomeBannerOneComponent,
    CommentsLineItemComponent,
    FooterComponent,
    ServiceRequestComponent,
    ServiceStepperComponent,
    StepperComponent,
    StepServicesRotorsOneComponent,
    StepServicesRotorsTwoComponent,
    StepServicesRotorsThreeComponent,
    PopularServicesRightNavListComponent,
    ServiceRequestVehicleComponent,
    ServiceRequestLayoutComponent,
    ServiceRequestEstimatesComponent,
    EstimateLineItemComponent,
    ServiceRequestBookComponent,
    ServiceRequestCalenderLineItemComponent,
    ServiceRequestConfirmComponent,
    LoginComponent,
    SignupComponent,
    ServicesListComponent,
    NoDataFoundComponent,
    LocationSelectComponent,
    LocationComponent,
    CurrentLocationComponent,
    ExtistingVehicleComponent,
    DashboardComponent,
    UserHeaderComponent,
    ViewDetailsComponent,
    EstimateTabComponent,
    ReviewsTabComponent,
    LocationTabComponent,
    MapEstimateComponent,
    MapViewComponent,
    MapQuoteViewComponent,
    ProfileComponent,
    AccountInfoComponent,
    PasswordInfoComponent,
    PaymentComponent,
    VehicleComponent,
    ReviewsComponent,
    SafePipe,
    ReviewsLineItemComponent,
    LocationDirectionComponent,
    NewLocationSearchComponent,
    ExistingVehcileLineComponent,
    VehcileDeleteConfirmDialogComponent,
    SignupHeaderComponent,
    NewLoginUiComponent,
    DashboardEstimateLineItemComponent,
    CommonAlertDialogComponent,
    ServiceHistoryComponent,
    VehcileEditConfirmDialogComponent,
    AddReviewsComponent,
    MessageComponent,
    MsgDatePipe,
    UploadProfileImageComponent,
    HelpComponent,
    SideNotificationComponent,
    ViewGarageComponent,
    ViewQuoteComponent,
    JobDetailsComponent,
    DropdownComponent,
    FaqComponent,
    FilterDropdownComponent,
    ComingSoonComponent,
    TurkeyCurrencyPipe,
    LegalPolicyComponent,
    GarageSelectionDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatInputModule, 
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    CdkAccordionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    MatTabsModule,
    Ng2SearchPipeModule,
    MatTooltipModule,
    MatMenuModule,
    TranslateModule.forRoot({
      defaultLanguage: 'trk',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
    }),
    SocialLoginModule,
    AgmDirectionModule,
    RatingModule,
    AgmCoreModule.forRoot({
      apiKey: environment.apiKey,
      libraries: ['places']
    })
  ],
  providers: [HttpClientModule,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,  
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('161109651340-gn91j6fjebck5f68pcp6m1srfmcakri0.apps.googleusercontent.com')
          },
          
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('937142706952817')
          }
        ]
      }
    }, 
       MatDatepickerModule],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
