import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from '../Constants/constants';
import { Utils } from '../common/utils';

@Injectable({
  providedIn: 'root'
})
export class BookingServiceService {

  constructor(private http: HttpClient,
              private utilsCalss: Utils) { }

  getServicesAndPincode(): Observable<any>{
    return this.http.get(Constants.BASE_URL+Constants.API.GET_SERVICES_AND_PINCODES,{params: {country_code: this.utilsCalss.getCurrentLanguage()}})
  }

  getServiceList(body:any):Observable<any> {
    return this.http.post(Constants.BASE_URL+Constants.API.GET_POPULAR_SERVICE,body);
  }

  getCarYearList():Observable<any> {
    return this.http.get(Constants.BASE_URL+Constants.API.GET_VECHICLE_MODEL_YEAR,{params: {country_code: this.utilsCalss.getCurrentLanguage()}});
  }

  getCarMakeList(year : string):Observable<any> {
    return this.http.get(Constants.BASE_URL+Constants.API.GET_VECHICLE_BRAND,{params: {country_code: this.utilsCalss.getCurrentLanguage(),year: year}});
  }

  getCarModelList(year : string,makeId: string):Observable<any> {
    return this.http.get(Constants.BASE_URL+Constants.API.GET_VECHICLE_MODEL,{params: {country_code: this.utilsCalss.getCurrentLanguage(),year: year,make_id: makeId}});
  }

  getCarSeriesList(year : string,makeId: string,modelId: string):Observable<any> {
    return this.http.get(Constants.BASE_URL+Constants.API.GET_VECHICLE_SERIES,{params: {country_code: this.utilsCalss.getCurrentLanguage(),year: year,make_id: makeId,model_id: modelId}});
  }

  getCarTrimList(year : string,makeId: string,modelId: string,seriesName: string):Observable<any> {
    return this.http.get(Constants.BASE_URL+Constants.API.GET_VECHICLE_TRIM,{params: {country_code: this.utilsCalss.getCurrentLanguage(),year: year,make_id: makeId,model_id: modelId,series_name: seriesName}});
  }

  getDirectQuotes(body:any):Observable<any>{
    return this.http.post(Constants.BASE_URL+Constants.API.GET_VECHICLE_QUOTES_DIRECT,body);
  }

  getProblemList(body:any):Observable<any> {
    return this.http.post(Constants.BASE_URL+Constants.API.GET_PROBLEM_LIST,body)
  }

  getServiceByCategory(id: string):Observable<any>{
    return this.http.get(Constants.BASE_URL+Constants.API.GET_SERVICE_BY_CATEGORY,{params:{id: id}})
  }

  //user registration and login api
  saveUserRegistration(body: any):Observable<any>{
    return this.http.post(Constants.BASE_URL+Constants.API.USER_REGISTRATION,body)
  }

  authUserRegistration(body:any):Observable<any>{
    return this.http.post(Constants.BASE_URL+Constants.API.AUTH_REGISTRATION,body)
  }

  login(body: any):Observable<any>{
    return this.http.post(Constants.BASE_URL+Constants.API.USER_LOGIN,body)
  }

  socialLogin(body: any):Observable<any>{
    return this.http.post(Constants.BASE_URL+Constants.API.SOCIAL_LOGIN,body)
  }

  updateProfile(body:any):Observable<any>{
    return this.http.patch(Constants.BASE_URL+Constants.API.UPDATE_PROFILE,body)
  }

  addVehicleByUser(body:any):Observable<any>{
    return this.http.put(Constants.BASE_URL+Constants.API.ADD_VECHICLE,body)
  }

  fetchVehicleByUser(id: string):Observable<any>{
    return this.http.get(Constants.BASE_URL+Constants.API.FETCH_VECHICLE,{params:{user_id: id}})
  }

  removeVehicleByUser(body:any):Observable<any>{
    return this.http.patch(Constants.BASE_URL+Constants.API.REMOVE_VECHICLE,body)
  }

  requestOTP(body:any):Observable<any>{
    return this.http.post(Constants.BASE_URL+Constants.API.REQUEST_OTP,body)
  }

  verifyOTP(body:any):Observable<any>{
    return this.http.post(Constants.BASE_URL+Constants.API.VERIFIY_OTP,body)
  }

  //ESTIMATE
  saveEstimate(body:any):Observable<any>{
    return this.http.post(Constants.BASE_URL+Constants.API.SAVE_ESTIMATE,body)
  }

  fetchAppointment(id:string):Observable<any>{
    return this.http.get(Constants.BASE_URL+Constants.API.FETCH_ALL_APPOINTMENT,{params:{user_id: id}})
  }

  getAllServiceList():Observable<any>{
    return this.http.get(Constants.BASE_URL+Constants.API.GET_ALL_SERVICES_CATEGORY)
  }

  fetchReviewByUser(id:string):Observable<any>{
    return this.http.get(Constants.BASE_URL+Constants.API.GET_ALL_REVIEWS,{params:{user_id: id}})
  }

  addReviewByUser(body:any):Observable<any>{
    return this.http.post(Constants.BASE_URL+Constants.API.ADD_USER_REVIEWS,body)
  }

  //address found
  fetchAddressFromZipcode(postalcode:string):Observable<any>{
    return this.http.get('https://api.worldpostallocations.com/pincode',{params:{postalcode: postalcode,countrycode:'TR'}})
  }

  fetchJobTimings(body:any):Observable<any>{
    return this.http.post(Constants.BASE_URL+Constants.API.FETCH_JOB_TIMINGS,body)
  }

  addTickets(body:any):Observable<any>{
    return this.http.post(Constants.BASE_URL+Constants.API.ADD_TICKET,body)
  }
  fetchCity():Observable<any>{
    return this.http.get(Constants.BASE_URL+Constants.API.FETCH_CITY)
  }
  fetchDistrict(body:any):Observable<any>{
    return this.http.post(Constants.BASE_URL+Constants.API.FETCH_DISTRICT,body)
  }

  deleteAccount(body:any):Observable<any>{
    return this.http.post(Constants.BASE_URL+Constants.API.DELETE_ACCOUNT,body)
  }

  fetchDriver(body:any):Observable<any>{    
    return this.http.post(Constants.BASE_URL+Constants.API.FETCH_DRIVER,body)
  }
  fetchLegalCategory(body:any): Observable<any>{    
    return this.http.post(Constants.BASE_URL+Constants.API.FETCH_LEGAL_CATEGORY, body)
  }
}

export interface USER{
  commercial_updated_at: any;
  commercial_message?: any;
  date_create: any;
  email: string;
  firstName: string;
  lastName: string;
  mobile: string;
  userId: string;
  session_id: string;
  dob: string;
  gender: string;
  occupation: string;
  address: string;
  city: string;
  province: string;
}
