    <nav class="navbar" role="navigation" aria-label="main navigation" >
      <div
        class="navbar-brand is-pa-l-10" 
      >
        <img
          routerLink="/home"
          class="is-clickable"
          src="assets/img/arabama.png"
          style="display: block"/>
      </div>
    </nav>
    <div class="pad-60">
        <div class="kpi-dashboard pb-0">
            <div class="columns m-0">
                <div class="column is-narrow">
                    <h2 class=" pt-3 has-text-weight-bold" style="font-size: 24px;">
                      Legal Policy
                    </h2>
                </div>
            </div>
        </div>
        <div *ngIf="legalCategories?.length > 0" class="columns m-0">
            <div class="column is-2 sidebar-area">
                <nav class="pt-0">
                    <ul class="pt-4">
                      <li *ngFor="let category of legalCategories" class="pb-4">
                        <a (click)="categoryChange(category)" [ngClass]="selectedCategoryId == category.id? 'is-active': ''" class="category-text" style="font-size: 16px;">{{category.category_name}}</a>
                      </li>
                    </ul>
                </nav>
            </div>
            <div *ngIf="selectedItem" class="column mb-4" style="background-color: #fafafa;">
                  <div class="columns mb-0 px-5 pt-3 is-flex is-justify-content-space-between">
                      <div class="column is-narrow">
                          <h2 class="has-text-weight-bold category-title" style="font-size: 22px;">
                            {{selectedItem.category_name}}
                          </h2>
                          
                      </div>
                  </div>
                  <div *ngIf="selectedItem.category_description" class="columns px-5 pt-2 mb-0">
                      <div class="column pt-0">
                          <!-- {{selectedItem.category_description}} -->
                          <div style="font-size: 14px;" [innerHTML]="selectedItem.category_description"></div>
                      </div>
                  </div>
                  <div *ngIf="selectedItem?.subcategories" class="columns is-multiline p-5">
                        <div *ngFor="let item of selectedItem?.subcategories; let i = index" class="">
                            <mat-expansion-panel [expanded]="currentStep == i" (opened)="setStep(i)" (closed)="currentStep != i" class="w-100">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="mr-0">
                                       <p class="has-text-weight-semibold" style="font-size: 17px;">{{item.subcategory_name}}</p>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div>
                                  <p *ngIf="item.subcategory_subtitle" class="has-text-weight-semibold pb-3" style="font-size: 15px;">{{item.subcategory_subtitle}}</p>
                                  <!-- <p *ngIf="item.subcategory_description" style="font-size: 14px;">{{item.subcategory_description}}</p> -->
                                  <div *ngIf="item.subcategory_description" style="font-size: 14px;" [innerHTML]="item.subcategory_description"></div>

                                </div>
                            </mat-expansion-panel>
                        </div>  
                  </div>
              </div>
        </div>
    </div>
    