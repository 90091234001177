<div class="signup-fixed">
  <nav class="navbar" role="navigation" aria-label="main navigation" style="min-height: 48px;">
    <div
      class="navbar-brand is-pa-l-10" 
      [class.is-active]="showBurgerMenu"
    >
      <img
        routerLink="/home"
        class="is-clickable"
        src="assets/img/arabama.png"
        style="display: block"
      />
      <div class="is-flex is-align-items-center" style="margin-left: auto !important;">
        <div *ngIf="userData" class="ham-dropdown msg-icon icon-container mr-2 mbl-icon is-flex is-align-items-center">
          <div class="is-flex is-align-items-center is-justify-content-center is-clickable" (click)="notification('notify')">
            <img  class="pl-3 mr-3 icon is-medium notify-icon" src="/assets/svg/bell.svg" alt="" >
          <div *ngIf="notification_count > 0" class="notification mobile-notification p-0 is-flex is-justify-content-center is-align-items-center">
            <div class="has-text-weight-bold is-size-7">{{notification_count < 100 ? notification_count : 99}}
            </div>
          </div>
          </div>
          </div>
     
          <a
            (click)="showBurgerMenu = !showBurgerMenu"
            role="button"
            class="navbar-burger nav-toggle is-hidden-desktop ham-menu-icon"
            aria-label="menu"
            aria-expanded="false"
            data-target="myNavBar"
          >
            <img
              class="is-hidden-desktop ham-active"
              src="../../../assets/img/ham-menu.png"
            />
            <img
              class="is-hidden-desktop close-ham"
              src="../../../assets/img/ham-close.png"
            />
          </a>
      </div>
    </div>
    <div id="myNavBar" class="navbar-menu" [class.is-active]="showBurgerMenu">
      <div *ngIf="!userData" class="navbar-end">
        <div class="navbar-item">
          <a routerLink="/signup" class="navbar-item">
            <span class="has-text-weight-bold">{{
              "HOME.TEXT_62" | translate
            }}</span>
          </a>
        </div>
        <div class="navbar-item">
          <a routerLink="/auth" class="navbar-item">
            <span class="has-text-weight-bold">{{
              "HOME.TEXT_55" | translate
            }}</span>
          </a>
        </div>
        <div class="navbar-item">
          <a (click)="goToServices()" class="navbar-item">
            <span class="has-text-weight-bold">
              {{ "HOME.TEXT_61" | translate }}
            </span>
          </a>
        </div>
      </div>
      <div *ngIf="userData" class="navbar-end">
        <div class="ham-dropdown">
          <a routerLink="/dashboard" class="navbar-item is-flex">
            <img src="../../../assets/svg/dashboard.svg" alt="" width="23px" height="23px">
            <span class="has-text-left pl-2 is-flex pt-1">
              {{'DASHBOARD.MY_DASHBOARD' | translate}}</span
            >
          </a>
        </div>

        <div class="ham-dropdown">
          <a class="navbar-item is-flex"  (click)="clickProfile()">
            <img src="../../../assets/svg/my-account.svg" alt="" width="23px" height="23px">
            <span class="has-text-left pl-2 is-flex pt-1">
              {{'DASHBOARD.PROFILE' | translate}}</span
            >
          </a>
        </div>
        <div class="ham-dropdown">
          <a class="navbar-item is-flex"  (click)="carParking()">
            <img src="../../../assets/svg/my-car-park.svg" alt="" width="23px" height="23px">
            <span class="has-text-left pl-2 is-flex pt-1">
              {{'DASHBOARD.CAR_PARKING' | translate}}</span
            >
          </a>
        </div>
        <div class="ham-dropdown">
          <a class="navbar-item is-flex" (click)="serviceHistory()">
            <img src="../../../assets/svg/service-his.svg" alt="" width="23px" height="23px">
            <span class="has-text-left pl-2 is-flex pt-1">
              {{'DASHBOARD.SERVICE_HISTORY' | translate}}</span
            >
          </a>
        </div>
        <div class="ham-dropdown">
          <a class="navbar-item is-flex"  (click)="reviews()">
            <img src="../../../assets/svg/customer_feedbacks.svg" alt="" width="23px" height="23px">
            <span class="has-text-left pl-2 is-flex pt-1">
              {{'PROFILE.REVIEWS' | translate}}</span
            >
          </a>
        </div>

        <div class="ham-dropdown">
          <a class="navbar-item is-flex" (click)="logout()">
            <img src="../../../assets/svg/exit.svg" alt="" width="23px" height="22px">
            <span class="has-text-left pl-2 is-flex pt-1">
              {{ "DASHBOARD.LOGOUT" | translate }}</span
            >
          </a>
        </div>
       
      </div>
      <div *ngIf="userData" class="navbar-end">
        
        <div class="navbar-item desk-menu">
          <a class="navbar-item custom_msg nav-park" (click)="carParking()">
              <span>{{'DASHBOARD.CAR_PARKING' | translate}}</span>
          </a>
      </div>
      <div class="navbar-item desk-menu">
          <a class="navbar-item custom_msg nav-profile" (click)="clickProfile()">
              <span>{{'DASHBOARD.PROFILE' | translate}}</span>
          </a>
      </div>
      <div class="navbar-item name-container is-clickable desk-menu" (mouseenter)="nameHover = true" (mouseleave)="nameHover = false">
        <div class="username">
            {{userData.first_name}} {{userData.last_name}} <span><img width="14px" src="../../../assets/svg/down-arrow-green.svg" alt=""></span>
        </div>
        <div class="menu-options py-2" *ngIf="nameHover">
            <div class="option" (click)="homePage()">
                <div class="option-img">
                    <img src="https://asdev.nisissolutions.com/dashboard.svg" alt="">
                </div>
                <a class="navbar-item home-page" >
                    {{'DASHBOARD.MY_DASHBOARD' | translate}}
                </a>
            </div>
            <div class="option" (click)="serviceHistory()">
              <div class="option-img">
                  <img src="https://asdev.nisissolutions.com/service-his.svg" alt="">
              </div>
              <a class="navbar-item ser-history" >
                  {{'DASHBOARD.SERVICE_HISTORY' | translate}}
              </a>
          </div>
          <div class="option" (click)="reviews()">
              <div class="option-img">
                  <img src="https://asdev.nisissolutions.com/reviews.svg" alt="">
              </div>
              <a class="navbar-item reviews" >
                  {{'PROFILE.REVIEWS' | translate}}
              </a>
          </div>
            <div class="option" (click)="logout()">
                <div class="option-img">
                    <img src="https://asdev.nisissolutions.com/exit.svg" alt="">
                </div>
                <a class="navbar-item exit">
                    {{'DASHBOARD.LOGOUT' | translate}}
                </a>
            </div>
        </div>
    </div>
        <div class="desk-menu msg-icon icon-container desk mr-2 is-flex is-align-items-center">
          <div class="is-flex is-align-items-center is-justify-content-center is-clickable" (click)="notification('notify')">
            <img  class="pl-3 mr-3 icon is-medium notify-icon" src="/assets/svg/bell.svg" alt="" >
          <div *ngIf="notification_count > 0" class="notification p-0 is-flex is-justify-content-center is-align-items-center">
            <div class="has-text-weight-bold is-size-7">{{notification_count < 100 ? notification_count : 99}}
            </div>
          </div>
          </div>
      </div>
      </div>

      <div class="navbar-item is-hidden">
        <a routerLink="/auth" class="navbar-item">
          <span class="has-text-weight-bold">{{
            "HOME.TEXT_55" | translate
          }}</span>
        </a>
      </div>
    </div>
  </nav>
</div>
<div class="side-notify" [class.slide-in]="show_notification" [class.slide-out]="!show_notification">
  <app-side-notification class="notify-content" [showNotification]="show_notification" (hideNotification)="hideNotification($event)" (notificationCount)="notification_count = ($event)"></app-side-notification>
</div>