<div class="header-container"> 
  <app-signup-header></app-signup-header>
    <div class="columns mt-3 is-centered"> 
        <div class="column is-one-third-desktop  is-one-third-widescreen is-two-thirds-tablet  ml-5 mr-5">
            <div class="mt-5 p-6 login-card">
            <div class= "has-text-weight-bold  signin-title mb-5">{{ 'COMMON.SIGN_IN' | translate}}</div>
            <div class="control mb-4">
                <input class="input" [formControl]="userNameFormcontrol" 
                       type="email" (ngModelChange)="onInputChange($event)" :placeholder="{{ 'COMMON.EMAIL' | translate}}">
                <label *ngIf="!isValidEmail" class="pl-2 text-size-11" style="margin-left: 3px;color:red">Enter valid email</label>
            </div>
            <div class="control has-icons-right">
              <input id="inputPass" [formControl]="passwordFormControl" class="input" 
                     type="password" (ngModelChange)="onInputPasswordChange($event)" placeholder="{{ 'COMMON.PASSWORD' | translate}}">
                <span class="icon is-small is-right" (click)="onClikShowPassword()" style="align-items: center;height: 100%;pointer-events: all;">
                  <i id="passEye" class="fas fa-eye-slash"></i>
                </span>
            </div>
            <div class="is-pulled-right is-size-7 pb-3">
              <button class="button is-white" style="color: #666666;">{{ 'COMMON.FORGET_PASSWORD' | translate}}</button>
            </div>
            <div class="control p-2">
              <button id="btnLogin" [ngClass] = "(!btnSignInEnabled)  ? 'button-disabled' : 'has-background-success'" style="color: white;font-weight: bold;border-radius: 12px;" (click)="clickLogin()" class="button has-background-success is-fullwidth">{{ 'COMMON.SIGN_IN_BTN' | translate}}</button>
          </div> 
            <div class="has-text-centered py-5">
              <span class="is-size-12 has-text-weight-bold"  (click)="signUP()" style="cursor: pointer;"> {{ 'COMMON.SIGNUP_WITH' | translate}} </span>
            </div>
          
            <div class="column has-text-centered">
              <div class="column">    
                <span class="icon-text">
                  <span class="icon has-text-link mr-2" (click)="loginWithFaceBook()">
                    <img src="assets/svg/facebook.svg" />
                  </span>
                  <span class="is-size-7">{{ 'COMMON.FACEBOOK' | translate}}</span>
                  <span class="icon has-text-danger ml-2" (click)="loginWithGmail()">
                    <img src="assets/img/gmail.png" />
                  </span>
                  <span class="is-size-7">{{ 'COMMON.E_MAIL_TEXT' | translate}}</span>
                  <span class="icon ml-2">
                    <img src="assets/img/cellphone.png" />
                  </span>
                  <span class="is-size-7">{{ 'COMMON.PHONE_TEXT' | translate}}</span>
                </span>
              </div> 
            </div>   
            </div>     
        </div> 
    </div>
</div>