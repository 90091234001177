<div @slideInOut #mapLoad>
  <agm-map (mapReady)="mapReading()" [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false"
  [zoomControl]="true">

  <agm-marker *ngFor="let m of markers; let i = index" [animation]="'DROP'" [latitude]="m.lat" [longitude]="m.lng"
    [label]="m.labelOption" [iconUrl]="icon" (markerClick)="markerClicked(m)" (mouseOver)="onMouseOver(infoWindow)"
    (mouseOut)="onMouseOut(infoWindow)">
    <agm-info-window [disableAutoPan]="false" #infoWindow>
        <div *ngFor="let garage of m.garages" class="is-flex">
          <span class="has-text-weight-bold is-size-7">{{ garage.garage_name }}</span>
          <span class="has-text-weight-bold is-size-7 ml-2">{{ garage.distance.toFixed(2) }} Km</span>
        </div>
    </agm-info-window>
  </agm-marker>
  </agm-map>
  
</div>
