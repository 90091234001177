
        <div class="rating-block" style=" border-radius: 0px 0px 20px 20px;background-color: #FAFAFA;"> 
            <div class="card-content heading-color-1">
                <div class="like-wrapper"> 
                    <div class="stars">
                        <img src="assets/svg/star_green.svg">
                        <img src="assets/svg/star_green.svg">
                        <img src="assets/svg/star_green.svg">
                        <img src="assets/svg/star.svg">
                        <img src="assets/svg/star.svg"> 
                     </div>
                <div class="has-text-weight-bold like-wrapper">{{ 'HOME.TEXT_30' | translate }}
                <div class=" like-cta ml-2">
                    <i class="fas fa-thumbs-up is-size-5"></i>
                </div>
                </div>
                </div>
                <br>
                <div >
                    <span class="rating-headline pt-5">{{ 'HOME.TEXT_31' | translate }}</span><br>
                    <span class="text-size-14">1 {{ 'HOME.TEXT_48' | translate }}</span><br>
                    <div class="mt-3"><span class="cmd-tag is-primary is-light">Ford focus</span>
                    <span class="cmd-tag is-primary is-light m-1">Clutch replacement</span>
                     </div>
                </div>
                <br><br>
                <p class="rating-content">My car’s service was done in 4 hours which is really awesome & the concept of free pickup drop is appreciated. Will recommend arabama to all of my friends and family. </p>
             </div><br>
              <div style="background: #F0F2EA;
                border-radius: 0px 0px 20px 20px;padding: 10px 20px; ">
                <span class="garage-dtl">{{ 'HOME.TEXT_33' | translate }}</span>
                <span class="is-pulled-right reservation">500 {{ 'HOME.TEXT_35' | translate }}</span><br>
                <span class="place">{{ 'HOME.TEXT_34' | translate }}</span>
                </div>
          </div>
