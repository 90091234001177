import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BookingServiceService } from 'src/app/services/booking-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
date: any;
legalCategories: any;

constructor(public translate: TranslateService, private router: Router, private BookingService: BookingServiceService) { }

  ngOnInit(): void {
    this.date = moment(new Date()).format('YYYY');
    this.fetchAllCategory();
  }

  fetchAllCategory() {
    this.BookingService.fetchLegalCategory({}).subscribe({
      next: (res) => {
        this.legalCategories = res.data        
      }, error: (err)=> {
        console.log(err)
      }
    })

}
  openInNewTab(category: any) {    
    const baseUrl = window.location.origin;
    const fullUrl = `${baseUrl}/#/legal-policy/${category.id}`;
    const newTab = window.open(fullUrl, '_blank');
    if (newTab) {
      newTab.opener = null;
    } else {
      console.error('Failed to open new tab');
    }
  }
}
