import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Constants } from 'src/app/Constants/constants';
import { ChatService } from 'src/app/services/chat.service';
import { SocketService } from 'src/app/services/socket.service';
import * as moment from 'moment';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
  @Output() hideMessage = new EventEmitter<any>();
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  @Input() estimation: any;
  @Input() quoteId: any;

  userData: any;
  message!: string;
  message_list: any = [];
  isUserOnline: boolean = false;
  disableBtn: boolean = false;
  constructor(private socketService: SocketService, private chatService: ChatService) {
    
   }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem(Constants.APP.SESSION_USER_DATA)|| "{}");
    this.getMessages();
    this.setupSocketListeners();

  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.scrollToBottom()
    }, 200);
  }
  setupSocketListeners() {
    this.socketService
      .listen('chat')
      .subscribe((data) => {
        if(data.job_id === this.quoteId && (data.sender_id === this.estimation.id || data.receiver_id === this.estimation.id)){
          this.message_list.push(data)
          this.scrollToBottom();

        }
      });
  }
  getInitials(author: string): string {
    const words = author.split(' ');
    const initials = words.slice(0, 2).map(name => name.charAt(0)).join('').toUpperCase();
    return initials;
  }

  hidePopup(){
    this.hideMessage.emit(false);
  }
  sendMessage(){
    if(this.message){
      this.disableBtn = true
      this.chatService
      .sendChatMsg(
        this.message,
        this.quoteId,
        this.userData.user_id,
        this.estimation.id
      )
      .subscribe({
        next: (res) => {
          if (res.statusCode == 1) {
            this.socketService.emit('chat', {
              message_text: this.message,
              receiver_id: this.estimation.id,
              sender_id: this.userData.user_id,
              job_id: this.quoteId,
              first_name: this.userData.first_name,
              last_name: this.userData.last_name,
              date_create: moment(Date()).format('DD-MMM-YYYY, h:mm A'),
              sender_type: 'driver'
            });
          }
          this.message = '';
          this.disableBtn = false
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
  }
  getMessages() {
    this.chatService
      .getChatMsg(this.quoteId, this.estimation.id)
      .subscribe({
        next: (res) => {
          if (res.statusCode == 1) {
            this.message_list = res.data
            this.scrollToBottom() 
            this.setReadMsg()
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }
  private scrollToBottom() {
    if (this.chatContainer && this.chatContainer.nativeElement) {
      const container = this.chatContainer.nativeElement;
      container.scrollTop = container.scrollHeight;
    }
  }
   onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent the default behavior of creating a new line
      this.sendMessage(); // Call the send message function
    }
  }
  setReadMsg() {
    this.chatService
      .setReadMsg(this.quoteId, this.estimation.id)
      .subscribe({
        next: (res) => {
          if (res.statusCode == 1) {
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  ngOnDestroy() {
    this.setReadMsg()
  }
}
