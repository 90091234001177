import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingServiceService } from 'src/app/services/booking-service.service';

@Component({
  selector: 'app-legal-policy',
  templateUrl: './legal-policy.component.html',
  styleUrls: ['./legal-policy.component.scss']
})
export class LegalPolicyComponent implements OnInit {
  selectedCategoryId: any;
  legalCategories: any;
  selectedItem: any;
  currentStep!: number


  constructor(private BookingService: BookingServiceService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.selectedCategoryId = this.route.snapshot.paramMap.get('id');
    
    this.fetchAllCategory();
  }
  fetchAllCategory() {
    this.BookingService.fetchLegalCategory({}).subscribe({
      next: (res) => {
        this.legalCategories = res.data
        this.legalCategories.forEach((item:any)=>{
          if(this.selectedCategoryId == item.id){
            this.selectedItem = item
          }
        })        
      }, error: (err)=> {
        console.log(err)
      }
    })
  }
  categoryChange(category: any){    
    this.selectedCategoryId = category.id
    this.legalCategories.forEach((item:any)=>{
      if(this.selectedCategoryId == item.id){
        this.selectedItem = item
      }
    })    
  }
  setStep(step: any) {
    this.currentStep = step;
  }
}
