import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { SlideInOutAnimation } from 'src/app/animation/SlideInOutAnimation';
import { Constants } from 'src/app/Constants/constants';
import { PassDataService } from 'src/app/services/pass-data.service';
import { MatDialog } from '@angular/material/dialog';
import { GarageSelectionDialogComponent } from 'src/app/dialog/garage-selection-dialog/garage-selection-dialog.component';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({transform: 'translateY(-100%)'}),
    animate('200ms ease-in', style({transform: 'translateY(0%)'}))
  ]),
  transition(':leave', [
    animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
  ])
]);
@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
  animations: [SlideInOutAnimation]
})
export class MapViewComponent implements OnInit, OnChanges {
  @Output() sendViewDetails = new EventEmitter<string>();
  zoom: number = 10;
  lat: number = 41.115137;
  lng: number = 28.979530;
  markers: any[] = []
  userLocationMarkerAnimation: string = '';
  icon: any = {
    url: ('/assets/img/marker_green.png'),
    labelOrigin: { x: 42, y: 17 },
    scaledSize: {
      height: 50,
      width: 85
    }
  };
  constructor(private passService: PassDataService, private dialog: MatDialog) {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
  ngOnInit(): void {
    let locationList = sessionStorage.getItem(Constants.APP.LOCATION_ARRAY);
    if (locationList != null) {
      let locationListArr = JSON.parse(locationList);
        const groupedLocations = locationListArr.reduce((acc: any, curr: any) => {
        const key = `${curr.lat},${curr.lng}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(curr);
        return acc;
      }, {});
  
      for (let key in groupedLocations) {
        const garages = groupedLocations[key];
        const firstGarage = garages[0];
  
        this.markers.push({
          lat: Number(firstGarage.lat),
          lng: Number(firstGarage.lng),
          garages: garages,
          labelOption: {
            color: 'white',
            fontSize: '14px',
            fontWeight: 'bold',
            text: garages.length > 1 ? `${garages.length} Garages` : `${garages.length} Garage`,
          },
          draggable: false,
        });
      }
  
      if (this.markers.length > 0) {
        this.lat = this.markers[0].lat;
        this.lng = this.markers[0].lng;
      }
    }
  }
  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
  }

  mapReading() {
    this.userLocationMarkerAnimation = 'BOUNCE';
  }
  triggerEscapeKey(): void {
    const escEvent = new KeyboardEvent('keydown', {
      key: 'Escape',
      keyCode: 27, // Standard key code for Escape
      code: 'Escape',
      bubbles: true,
      cancelable: true,
      view: window
    });
    document.dispatchEvent(escEvent);
  }

  markerClicked(marker: any) {
    if (marker.garages.length === 1) {
      const garage = marker.garages[0];
      this.passService.setGarageValue(garage);
      this.sendViewDetails.emit();
    } else {
      this.showGarageSelection(marker.garages);
    }
  }
  openGarageDetails(garage: any) {
    this.passService.setGarageValue(garage); 
    this.sendViewDetails.emit();
  }
  showGarageSelection(garages: any[]) {
    const dialogRef = this.dialog.open(GarageSelectionDialogComponent, {
      width: '400px',
      data: { garages },
    });
  
    dialogRef.afterClosed().subscribe((selectedGarage) => {
      if (selectedGarage) {
        this.openGarageDetails(selectedGarage);
      }
    });
  }
  onMouseOver(infoWindow: any) {
    infoWindow.open();
    setTimeout(() => {
      var x = document.getElementsByClassName('gm-ui-hover-effect')[0]?.remove();
    }, 5);
  }

  onMouseOut(infoWindow: any) {
    infoWindow.close();
  }

}
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

