<div class="columns m-0 sec-title-wrapper">
    <div class="column is-8 px-0" style="align-items: center;display: flex;">
        <button class="button back_button" (click)="onBackPressed()">
          <span class="icon is-small is-left"  style="height: 60px; color: #fff;">
            <i class="fas fa-arrow-left"></i>
          </span>
        </button>
        <span class="has-text-weight-bold ml-4 is-size-6-mobile	" style="font-size: 1.3rem;">
            {{'DASHBOARD.SERVICE_HISTORY' | translate}}
        </span>
      </div>
</div>
<div *ngIf="isDataLoading" class="spinner-wrapper" style="height: 435px;">
    <mat-spinner [diameter]="50"></mat-spinner>
</div>
<div *ngIf="!isDataLoading"  class="service-history min-height-tab mb-5">
    <div *ngIf="serviced_details.length > 0">
        <div *ngIf="!isMobile" class="columns m-0 pb-3" >
            <div class="column is-narrow pt-1 is-flex is-align-items-center">
                <div class="is-flex is-align-items-center">
                    <img src="../../../assets/svg/veh-info.svg" width="25px" height="25px"/>
                </div>
                <div class="pl-3 filter-box">
                    <h4 >{{'COMMON.VEHICLE' | translate}}</h4>
                <div class="input-box is-flex px-1" (click)="showVehicleOptions = !showVehicleOptions">
                    <div class="input-field pl-1" >
                        {{selectedVehicle.name}}
                    </div>
                    <div class="dropdown-icon">
                        <img src="../../../assets/svg/VectorUp.svg" class="" alt="">
                    </div>
                </div>
                <div class="options" *ngIf="showVehicleOptions">
                    <div *ngFor="let option of vehicle_name">   
                        <p (click)="vehicleChange(option)">{{option.name}}</p>
                    </div>
                </div>
            </div>
            
            </div>
            <div class="column is-4 pt-1 is-flex is-align-items-center">
                <div class="is-flex is-align-items-center">
                    <img src="../../../assets/svg/date-icon.svg" width="24px" height="24px"/>
                </div>
                <div class="pl-3">
                    <h4 >{{'COMMON.YEAR' | translate}}</h4>
                    <div class="input-box is-flex px-1 year-box" (click)="showYearOptions = !showYearOptions">
                        <div class="input-field pl-1" >
                            {{selectedYear}}
                        </div>
                        <div class="dropdown-icon">
                            <img src="../../../assets/svg/VectorUp.svg" class="" alt="">
                        </div>
                    </div>
                    <div class="options year-options" *ngIf="showYearOptions">
                        <div *ngFor="let option of vehicle_years">   
                            <p (click)="yearChange(option)">{{option}}</p>
                        </div>
                    </div>
                    </div>
                </div>
            <div class="column is-fluid pt-1 is-flex-tablet is-justify-content-end">
                <div class="service-no">
                    <p class="service-count">{{serviced_details.length}}</p>
                    <p class="has-text-weight-bold"> {{'COMMON.SERVICE' | translate}}</p>
                </div>
            </div>
        </div>
        <div *ngIf="isMobile" >
            <div class="columns is-flex m-0">
                <div class="column is-fluid p-0 is-flex is-align-items-center veh-filter">
                        <div class="pl-3">
                            <div class="is-flex is-align-items-center">
                                <img src="../../../assets/svg/veh-info.svg" width="25px" height="25px"/>
                                <h4 class="pt-1 pl-2">{{'COMMON.VEHICLE' | translate}}</h4>
                            </div>
                            <div class="input-box is-flex px-1 mt-2 vehicle-box" (click)="showVehicleOptions = !showVehicleOptions">
                                <div class="input-field pl-1" style="overflow: hidden; white-space: nowrap;">
                                    {{selectedVehicle.name}}
                                </div>
                                <div class="dropdown-icon">
                                    <img src="../../../assets/svg/VectorUp.svg" class="" alt="">
                                </div>
                            </div>
                            <div class="options vehicle-options" *ngIf="showVehicleOptions">
                                <div *ngFor="let option of vehicle_name">   
                                    <p (click)="vehicleChange(option)">{{option.name}}</p>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="column is-narrow p-0 is-flex is-align-items-center year-filter">
                        <div class="pl-3">
                            <div class="is-flex is-align-items-center">
                                <img  src="../../../assets/svg/date-icon.svg" width="24px" height="24px"/>
                                <h4 class="pl-2 pt-1">{{'COMMON.YEAR' | translate}}</h4>
                            </div>
                            <div class="input-box is-flex px-1 year-box mt-2" (click)="showYearOptions = !showYearOptions">
                                <div class="input-field pl-1" >
                                    {{selectedYear}}
                                </div>
                                <div class="dropdown-icon">
                                    <img src="../../../assets/svg/VectorUp.svg" class="" alt="">
                                </div>
                            </div>
                            <div class="options year-options" *ngIf="showYearOptions">
                                <div *ngFor="let option of vehicle_years">   
                                    <p (click)="yearChange(option)">{{option}}</p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>

            <div class="column is-4 pt-0 pb-4 is-flex is-justify-content-center mt-2">
                <div class="service-no">
                    <p class="service-count">{{serviced_details.length}}</p>
                    <p class="has-text-weight-bold"> {{'COMMON.SERVICE' | translate}}</p>
                </div>
            </div>
        </div>
    </div>

    
    <div class="m-5 ml-3" *ngIf="serviced_details.length == 0 && !isDataLoading">
        <div class="colums pt-4">
            <div class="column is-12 has-text-centered p-0">
                <div class="no-envents is-flex is-flex-direction-column is-justify-content-center">
                    <div class="mb-2 pt-2 is-flex-tablet is-justify-content-start is-align-items-center px-3">
                        <div class="appoiment-img is-flex is-justify-content-center">
                            <img class="image no-service" src="../../../assets/svg/service-his.svg">
                        </div>
                        <p class="text-size-20 my-3 mx-6 content" style="color:#a3a3a3">{{'COMMON.NO_SERVICE' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div *ngFor="let item of filteredServices;let i = index" class="service-history-accor">
        <mat-expansion-panel [expanded]="currentStep == i" (opened)="setStep(i)" (closed)="currentStep != i" class="w-100">
            <mat-expansion-panel-header>
                <mat-panel-title class="is-justify-content-space-between mr-0">
                    <div class="columns w-100 is-align-items-center is-flex-mobile">
                        <div class="column is-fluid">
                            <h4>{{item.service_date}} - {{item.garage[0].name}}</h4>
                        </div>
                        <div class="column is-4 is-flex is-justify-content-end is-4-mobile service-exp-col">
                        <div class="service-exp" style="width: 150px;">{{item.service_duration}}</div>
                        </div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="columns m-0">
                <div class="column is-fluid p-0">
                    <div class="columns m-0 vehicle-details is-multiline pb-2">
                 
                        <div class="column is-5 service-parts pl-2 is-flex is-align-items-center py-1">
                                <img class="image is-32x32" src="/assets/svg/service_detail.svg" alt="">
                            <div class="ml-4 is-flex is-flex-direction-column is-justify-content-center">
                                <h4>{{'DASHBOARD.SERVICES' | translate}}</h4>
                                <ul *ngFor="let item of item.items;index as i">
                                    <li class="">{{item.name}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="column is-5 service-location pl-2 is-flex is-align-items-center py-1">
                                <img class="image is-32x32" src="/assets/svg/garage_location.svg" alt="">
                            <div class="ml-4 is-flex is-flex-direction-column is-justify-content-center">
                                <h4>{{'HOME.LOCATION' | translate}}</h4>
                                <ul>
                                    <li class="">{{item.zip_code}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="column is-5 service-km pl-2 is-flex is-align-items-center py-1">
                                <img class="image is-32x32" src="/assets/svg/veh-info.svg" alt="">
                            <div class="ml-4 is-flex is-flex-direction-column is-justify-content-center">
                                <h4>{{'COMMON.VEHICLE' | translate}}</h4>
                                <p class="">{{item.vehicle_name}}</p>
                            </div>
                        </div>
                        <div class="column is-5 service-km pl-2 is-flex is-align-items-center py-1">
                                <img class="image is-32x32" src="/assets/svg/km.svg" alt="">
                            <div class="ml-4 is-flex is-flex-direction-column is-justify-content-center">
                                <h4>{{'PROFILE.MILEAGE' | translate}}</h4>
                                <p class="">{{item.vehicle_details[0].mileage}}</p>
                            </div>
                        </div>
                        <div class="column is-5 pl-2 is-flex is-align-items-center pt-1 py-1">
                                <img class="image is-32x32" src="/assets/svg/reviews.svg" alt="">
                            <div class="ml-4 is-flex is-flex-direction-column is-justify-content-center">
                                <h4>{{'COMMON.RATE_YOUR_SERVICE' | translate}}</h4>
                                                            <!-- <div class="star-input">
                                <ul class="is-flex">
                                    <li><a href="#"> <img src="../../../assets/svg/star-line.svg"></a></li>
                                    <li><a href="#"> <img src="../../../assets/svg/star-line.svg"></a></li>
                                    <li><a href="#"> <img src="../../../assets/svg/star-line.svg"></a></li>
                                    <li><a href="#"> <img src="../../../assets/svg/star-line.svg"></a></li>
                                    <li><a href="#"> <img src="../../../assets/svg/star-line.svg"></a></li>

                                </ul>
                            </div> -->
                            <star-rating [value]="item.reviews[0]?.ratings ? item.reviews[0].ratings : 0" [totalstars]="5" checkedcolor="#AAD043" uncheckedcolor="#838383" size="20px" (rate)="starValue($event,item)"></star-rating>
                            </div>
                        </div>
                        <div class="column is-5 pl-2 is-flex is-align-items-center pt-1 py-1" >
                                <img class="image is-32x32" src="/assets/svg/feedback.svg" alt="" [ngClass]="!item.reviews[0]?.comment? '': 'is-clickable'" (click)="jobReview(item)">
                            <div class="ml-4 is-flex is-flex-direction-column is-justify-content-center"  >
                                <h4 [ngClass]="!item.reviews[0]?.comment? '': 'is-clickable'" (click)="jobReview(item)">{{'COMMON.SHARE_YOUR_EXPERIENCE' | translate}}</h4>
                                <div *ngIf="item.reviews[0]?.comment" class="">
                                    <div *ngIf="item.reviews[0]?.comment.length < 25" class="comments">
                                        {{item.reviews[0]?.comment}}
                                    </div>
                                    <div *ngIf="item.reviews[0]?.comment.length > 25 && item.showMore" class="comments">
                                        {{item.reviews[0]?.comment.slice(0, 25)}} <span class="is-clickable more" (click)="item.showMore = false">{{'DASHBOARD.MORE' | translate}}</span>
                                    </div>
                                    <div *ngIf="item.reviews[0]?.comment.length > 25 && !item.showMore" class="comments">
                                        {{item.reviews[0]?.comment}} <span class="is-clickable more" (click)="item.showMore = true">{{'DASHBOARD.LESS' | translate}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    </div>
                <div class="column is-12-mobile is-4-tablet price-details px-0 is-flex is-flex-direction-column is-align-items-end">
                    <!-- <div class="history-price mb-2 px-4 py-3 has-text-centered" style="width: 145px;">
                        <h4 class="text-size-18 mb-3">{{'COMMON.ESTIMATED' | translate}}</h4>
                        <h4 class="mb-3">Premium</h4>
                        <p class="has-text-weight-bold is-size-5">{{item.amount_to_pay}}</p>
                    </div> -->
                    <div class="history-price mb-2 px-4 py-3 has-text-centered" style="width: 145px;">
                        <h4 class="text-size-18 mb-3">{{'COMMON.ACTUAL' | translate}}</h4>
                        <p class="has-text-weight-bold is-size-5">{{item.total_amount}}</p>
                        <p class="view-details color-arabama-green is-size-6 pt-3 is-clickable" (click)="viewQuotation(item)">{{'COMMON.VIEW_DETAILS' | translate}}</p>
                    </div>
                </div>
                <div class="view-details-mobile has-text-centered pb-2">
                    <p class=" color-arabama-green is-size-6 is-clickable" (click)="viewQuotation(item)">{{'COMMON.VIEW_DETAILS' | translate}}</p>
                </div>
            </div>
            </mat-expansion-panel>
    </div>
</div>
