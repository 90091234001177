<h1 mat-dialog-title class="select-garage mb-2 font-18 is-flex is-justify-content-space-between">
    Select a Garage
    <button mat-icon-button mat-dialog-close class="close-button is-flex is-align-items-center is-justify-content-center" aria-label="Close">
      <mat-icon>close</mat-icon>
    </button>
  </h1>
  <div mat-dialog-content>
  <mat-list>
    <mat-list-item *ngFor="let garage of data.garages">
      <div class="is-flex is-align-items-center pb-1">
        <h3 class="font-16 garage-name pr-2 is-clickable" (click)="selectGarage(garage)">{{ garage.garage_name }}</h3>
        <p class="font-14">{{ garage.distance.toFixed(2) }} Km</p>
      </div>
    </mat-list-item>
  </mat-list>
</div>
