<!-- <div class="service-option">
  <div class="back-color columns ps-relative mt-2 mb-0">
    <div class="column is-12-mobile is-4-tablet is-3-desktop profile-field">
        <div class="profile-img">
          <img [src]="image" class="image">
        </div> 
    </div>
    <div class="column is-12-mobile is-8-tablet is-9-desktop">
      <div class="">
        <div class="content p-3">
          <div class="is-size-6-mobile is-size-5-tablet has-text-weight-semibold">{{estimateItem.name}}</div>
          <div class="star-rating">
            <star-rating [value]=estimateItem.rating.cum_rating [totalstars]="5"
              checkedcolor="#AAD043" uncheckedcolor="#C7C6C6" size="23px" [readonly]="true"></star-rating>
            <span *ngIf="estimateItem.rating.cum_rating" class="rating-count text-size-13 mt-7 text-size-13">({{estimateItem.rating.cum_rating | number:
              '1.0-1'}} /{{estimateItem.rating.totalRating}} )</span>
          </div>
          <div class="is-size-6 pt-1 pb-1" style="color: #4a4a4a;"><span> <strong>{{estimateItem.distance | number :
                '1.2-2'}} km</strong></span> - {{estimateItem.state}}</div>
          <div class="features-wrapper columns is-multiline pl-3 pt-1 my-0">
            <div class="features-inner pb-1"
              *ngFor="let item of estimateItem?.amenities?.slice(0, amenitiesLength) index as i;">
              <div [ngClass]="i !=0 ? '' : ''" class="d-flex is-align-items-center">
                  <img class="image is-24x24" src="{{item.logo_url}}" />
                <span class="text-size-14 pl-1 pt-1">{{ item.name }}</span>
              </div>
            </div>
            <div class="is-clickable more pt-1" *ngIf="estimateItem?.amenities?.length > defaultAmenitiesCount && amenitiesLength !== estimateItem?.amenities?.length" (click)="amenitiesLength = estimateItem?.amenities?.length">+{{estimateItem?.amenities?.length - defaultAmenitiesCount}} {{'DASHBOARD.MORE' | translate}}</div>
            <div class="is-clickable more pt-1" *ngIf="estimateItem?.amenities?.length === amenitiesLength && estimateItem?.amenities?.length !== defaultAmenitiesCount" (click)="amenitiesLength = defaultAmenitiesCount">{{'DASHBOARD.LESS' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="media-right" style="margin-right: 10px !important;">
      <button (click)="clickClose()" class="mt-3 delete pop-close"></button>
    </div>
  </div>

  <div class="my-4">  
    <mat-tab-group>
      <mat-tab label="{{'COMMON.OPENING_HOURS' | translate}}">
        <app-location-tab [estimate_item]="estimateItem"></app-location-tab>
      </mat-tab>
      <mat-tab label="{{'COMMON.REVIEWS' | translate}}">
        <app-reviews-tab style="overflow: scroll;" [reviews]="estimateItem.reviews"></app-reviews-tab>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
   -->


   <div class="service-option is-flex" >
    <div class="back-color columns ps-relative is-multiline mt-2 mx-0 mb-0">
      <div class="media-right close-icon is-hidden-tablet">
        <button (click)="clickClose()" class="mt-3 delete pop-close"></button>
      </div>
      <div class="column py-0 is-12-mobile is-12-tablet is-12-desktop">
        <div class="">
          <div class="content p-3 pt-0">
            <div class="is-flex-mobile">
              <div class="profile-img">
                <img [src]="image" class="image">
              </div> 
              <div class="details-box">
                <div class="has-text-weight-semibold font-17">{{estimateItem.name}}</div>
              <div class="star-rating">
                <star-rating [value]=estimateItem.rating.cum_rating [totalstars]="5"
                  checkedcolor="#AAD043" uncheckedcolor="#C7C6C6" size="23px" [readonly]="true"></star-rating>
                <span *ngIf="estimateItem.rating.cum_rating" class="rating-count text-size-13 mt-7 text-size-13">({{estimateItem.rating.cum_rating | number:
                  '1.0-1'}} /{{estimateItem.rating.totalRating}} )</span>
              </div>
              <div class="pt-1 pb-1 distance font-14" style="color: #4a4a4a;"><span> <strong >{{estimateItem.distance | number :
                    '1.2-2'}} km</strong></span> - {{estimateItem.state}}</div>
              </div>
            </div>
            <div class="features-wrapper columns is-multiline pl-3 pt-1 my-0">
              <div class="features-inner pb-1"
                *ngFor="let item of estimateItem?.amenities?.slice(0, amenitiesLength) index as i;">
                <div [ngClass]="i !=0 ? '' : ''" class="d-flex is-align-items-center">
                    <img class="image is-24x24" src="{{item.logo_url}}" />
                  <span class="text-size-14 pl-1 pt-1">{{ item.name }}</span>
                </div>
              </div>
              <div class="is-clickable more pt-1" *ngIf="estimateItem?.amenities?.length > defaultAmenitiesCount && amenitiesLength !== estimateItem?.amenities?.length" (click)="amenitiesLength = estimateItem?.amenities?.length">+{{estimateItem?.amenities?.length - defaultAmenitiesCount}} {{'DASHBOARD.MORE' | translate}}</div>
              <div class="is-clickable more pt-1" *ngIf="estimateItem?.amenities?.length === amenitiesLength && estimateItem?.amenities?.length !== defaultAmenitiesCount" (click)="amenitiesLength = defaultAmenitiesCount">{{'DASHBOARD.LESS' | translate}}</div>
            </div>
            <div class="features-wrapper columns mb-0 pb-1 is-multiline pl-4 pt-3">
              <div class="features-inner pt-2" *ngFor="let item of estimateItem?.specialization?.slice(0, specializationLength) index as i;">
                <div [ngClass]="i !=0 ? ' ' : ''" class="d-flex is-align-items-center specialization-data">
                  <span class="font-14 px-3 py-1 m-0 pt-1" style="line-height: 1.2;">{{ item.name }}</span>
                </div>
              </div>
              <div class="is-clickable more pt-3" *ngIf="estimateItem?.specialization?.length > defaultSpecializationCount && specializationLength !== estimateItem?.specialization?.length" (click)="specializationLength = estimateItem?.specialization?.length">+{{estimateItem?.specialization?.length - defaultSpecializationCount}} {{'DASHBOARD.MORE' | translate}}</div>
              <div class="is-clickable more pt-3" *ngIf="estimateItem?.specialization?.length === specializationLength && estimateItem?.specialization?.length !== defaultSpecializationCount" (click)="specializationLength = defaultSpecializationCount">{{'DASHBOARD.LESS' | translate}}..</div>
            </div>
            <div class="columns is-flex m-0 pt-2">
              <div *ngIf="estimateItem.network_name === 'Bosch Car Service'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
                <img class="ml-1 my-1" src="../../../assets/img/bosch_car_service.jpg" alt="" style="width: 30px; height: 30px;">          
              </div>
              <div *ngIf="estimateItem.tse_quality_checked === '1'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
                <img class="ml-1 my-1" src="../../../assets/img/tse.png" alt="" style="width: 45px; height: 30px;">
              </div>
              <div *ngIf="estimateItem.exhaust_emission_service === 'yes'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
                <img class="ml-1 my-1" src="../../../assets/img/exhaust_emission.png" alt="" style="min-width: 55px; width: 55px; height: 30px;">
      
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  
    <div class="my-4 ml-4 tab-box" >  
      <div class="media-right close-icon is-hidden-mobile">
        <button (click)="clickClose()" class="mt-3 delete pop-close"></button>
      </div>
      <mat-tab-group>
        <mat-tab label="{{'COMMON.OPENING_HOURS' | translate}}">
          <app-location-tab [estimate_item]="estimateItem"></app-location-tab>
        </mat-tab>
        <mat-tab label="{{'COMMON.REVIEWS' | translate}}">
          <app-reviews-tab style="overflow: scroll;" [reviews]="estimateItem.reviews"></app-reviews-tab>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>