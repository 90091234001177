<div class="content p-2">
  <div class="columns">
    <div class="column is-10-desktop is-12-tablet py-0">
      <p class="text-size-14 has-text-weight-normal heading-color-1 mb-0 pb-2 pt-4">{{ 'SERVICE.TEXT_27' | translate }}</p>
      <div class="columns my-0 ml-0">
        <div class="column pl-0 pb-2 pr-2">
          <div class="field is-flex" *ngIf="!isOtpSend">
            <div class="control w-100">
              <input id="firstname" class="input" type="text" (focus)="onFocus('firstname')" (blur)="onBlur('firstname')"  
              [formControl]="firstNameFormControl" (ngModelChange)="onInputFirstNameChange($event)">
              <label [ngClass]="isFirstNameValid? 'floating-label' : 'floating-label-new'" [class.active]="isFirstNameActive" for="firstname">{{ 'COMMON.FIRST_NAME' | translate }}</label>
              <label *ngIf="!isFirstNameValid" class="pl-2 text-size-11" style="margin-left: 3px;color:red">{{'ERROR_MESSAGE.error_valid_first_name'|translate}}</label>
            </div>
            <div class="icon star-icon is-size-4 has-text-danger"
            >*</div>
          </div>
        </div>
        <div class="column pl-0 pb-2 pr-2">
          <div class="field is-flex" *ngIf="!isOtpSend">
            <div class="control w-100">
              <input id="lastname" class="input" type="text" (focus)="onFocus('lastname')" (blur)="onBlur('lastname')"
              [formControl]="lastNameFormControl" (ngModelChange)="onInputLastNameChange($event)">
              <label [ngClass]="isLastNameValid? 'floating-label' : 'floating-label-new'" [class.active]="isLastNameActive" for="lastname">{{ 'COMMON.LAST_NAME' | translate }}</label>
              <label *ngIf="!isLastNameValid" class="pl-2 text-size-11" style="margin-left: 3px;color:red">{{'ERROR_MESSAGE.error_valid_last_name'|translate}}</label>
            </div>
            <div class="icon star-icon is-size-4 has-text-danger"
            >*</div>
          </div>
        </div>
      </div>
     <div class="columns my-0 ml-0">
      <div class="column pl-0 pb-2 pr-2">
        <div class="field is-flex">
          <div class="control w-100">
          <input id="email" class="input" type="text" (focus)="onFocus('email')" (blur)="onBlur('email')"
            [formControl]="emailIdFormControl" (ngModelChange)="onInputEmailChange($event)" [readOnly]="isOtpSend">
            <label [ngClass]="isEmailValid? 'floating-label' : 'floating-label-new'" [class.active]="isEmailActive" for="email">{{ 'COMMON.EMAIL_ID' | translate }}</label>
            <label *ngIf="!isEmailValid" class="pl-2 text-size-11" style="margin-left: 3px;color:red">{{'ERROR_MESSAGE.error_valid_email'|translate}}</label>
          </div>
          <div *ngIf="!isOtpSend" class="icon star-icon is-size-4 has-text-danger"
          >*</div>
        </div>
      </div>
      <div class="column pl-0 pb-2 pr-2">
        <div class="field is-flex" *ngIf="!isOtpSend">
          <div class="control w-100">
            <input id="phone" class="input" type="text" min="3" maxlength="17" (input)="numberOnly($event)"
            (focus)="onFocus('phone')" (blur)="onBlur('phone')" (ngModelChange)="onInputPhChange($event)"
            [formControl]="phoFormControl" [value]=[ph]>
            <label [ngClass]="isPhnValid? 'floating-label' : 'floating-label-new'" [class.active]="isPhoneActive" for="phone">{{ 'COMMON.PHONE' | translate }}</label>
            <label *ngIf="!isPhnValid" class="pl-2 text-size-11" style="margin-left: 3px;color:red">{{'ERROR_MESSAGE.error_valid_phone'|translate}}</label>
          </div>
          <div class="icon star-icon is-size-4 has-text-danger"
          >*</div>
        </div>
      </div>
     </div>
     <div class="columns my-0 ml-0">
      <div class="column pl-0 pb-2 pr-2">
        <div class="field is-flex" *ngIf="!isOtpSend">
          <div class="control w-100">
            <app-filter-dropdown [placeholder]="'COMMON.SELECT_CITY' | translate" [optionList]="this.optionCity" (optionChanged)="selectCity($event)"></app-filter-dropdown>
  
            <label *ngIf="!isCityValid" class="pl-2 text-size-11" style="margin-left: 3px;color:red">{{'ERROR_MESSAGE.error_city_required'|translate}}</label>
          </div>
          <div class="icon star-icon is-size-4 has-text-danger"
          >*</div>
        </div>
      </div>
      <div class="column pl-0 pb-2 pr-2">
        <div class="field is-flex" *ngIf="!isOtpSend">
          <div class="control w-100">
            <app-filter-dropdown [placeholder]="'COMMON.SELECT_PROVINCE' | translate" [defaultValue]="districtFormControl.value" [disable]="disableDistrict" [optionList]="this.optionDistrict" (optionChanged)="selectProvince($event)"></app-filter-dropdown>
            <label *ngIf="!isDistictValid" class="pl-2 text-size-11" style="margin-left: 3px;color:red">{{'ERROR_MESSAGE.error_distict_required'|translate}}</label>
          </div>
          <div class="icon star-icon is-size-4 has-text-danger"
          >*</div>
        </div>
      </div>
     </div>

      <div class="field is-hidden" *ngIf="!isOtpSend">
        <div class="control has-icons-right">
          <input id="inputPass" [formControl]="pwdFormControl" class="input" type="password"
                (ngModelChange)="onInputPasswordChange($event)" placeholder="{{ 'COMMON.PASSWORD' | translate}}">
          <span class="icon is-small is-right"  (click)="onClikShowPassword()" style="align-items: center;height: 100%;pointer-events: all;">
            <i id="passEye" class="fas fa-eye-slash"></i>
          </span>
        </div>
        <label *ngIf="!isPwdValid" class="pl-2 text-size-11" style="margin-left: 3px;color:red">{{'ERROR_MESSAGE.error_valid_pwd'|translate}}</label>
      </div>
     
      <div class="pt-2" *ngIf="!isOtpSend">
        <p class="has-text-weight-semibold check-text">arabamaservis.com <span class="is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'SERVICE.TEXT_55' | translate }}</span>{{ 'SERVICE.TEXT_56' | translate }}</p>
      </div>
      <div class="field ml-3 pt-2" *ngIf="!isOtpSend">
          <div class="columns is-align-items-center">  
            <div class="column p-0 d-flex"> 
              <div class="pt-3">
                <input type="checkbox" [formControl]="memberApprovalFormControl" (change)="memberCheckbox($event)"> 
              </div>
              <div class="ml-3">
                <div class="is-flex">
                  <div class="contct-conf pt-3 pb-1 has-text-weight-bold check-text">{{ 'SERVICE.TEXT_50' | translate }}</div>  
                  <div class="pt-1 pl-1 star-icon is-size-4 has-text-danger"
                   >*</div>              
                </div>
               
                <div *ngIf="!showMember_approval">
                  <p class="check-content"><span class="has-text-weight-semibold is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'SERVICE.TEXT_51' | translate }}</span><span class="is-clickable more pl-1" (click)="showMember_approval = true">{{'DASHBOARD.MORE' | translate}}</span>
                  </p>
                </div>
                <div *ngIf="showMember_approval">
                <p class="check-content"><span class="has-text-weight-semibold is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'SERVICE.TEXT_51' | translate }}</span>{{ 'SERVICE.TEXT_52' | translate }}<span class="is-clickable more pl-1" (click)="showMember_approval = false">{{'DASHBOARD.LESS' | translate}}</span>
                </p>
                </div>
              </div>          
            </div>
          </div>
      </div> 
      <div class="field ml-3" *ngIf="!isOtpSend">
          <div class="columns is-align-items-center">  
            <div class="column p-0 d-flex"> 
              <div class="pt-3">
                <input type="checkbox" [formControl]="contactConfirmFormControl" (change)="getCheckboxValue($event)"> 
              </div>
              <div class="ml-3">
                <div class="is-flex">
                  <div class="contct-conf pt-3 pb-1 check-text has-text-weight-semibold">{{ 'SERVICE.TEXT_28' | translate }}</div> 
                  <div class="pt-1 pl-1 star-icon is-size-4 has-text-danger"
                     >*</div>
                </div>       
                <div *ngIf="!showContact_confirm">
                  <p class="check-content mb-0">{{ 'SERVICE.TEXT_46' | translate }}<span class="has-text-weight-semibold is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'SERVICE.TEXT_47' | translate }}</span><span class="is-clickable more pl-1" (click)="showContact_confirm = true">{{'DASHBOARD.MORE' | translate}}</span>
                  </p>
                </div>
                <div *ngIf="showContact_confirm">
                <p class="check-content mb-0">{{ 'SERVICE.TEXT_46' | translate }}<span class="has-text-weight-semibold is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'SERVICE.TEXT_47' | translate }}</span>{{ 'SERVICE.TEXT_48' | translate }}
                </p>
                <p class="check-content">{{ 'SERVICE.TEXT_49' | translate }}<span class="is-clickable more pl-1" (click)="showContact_confirm = false">{{'DASHBOARD.LESS' | translate}}</span>
                </p>
                </div>
              </div>      
            </div>
          </div>
      </div> 
      <div class="field ml-3 pb-1" *ngIf="!isOtpSend">
        <div class="columns is-align-items-center">  
          <div class="column p-0 d-flex"> 
            <div class="pt-3">
              <input type="checkbox" [formControl]="commercialMsgFormControl" (change)="messageCheckbox($event)"> 
            </div>
            <div class="ml-3">
              <div class="contct-conf pt-3 pb-1 has-text-weight-bold check-text">{{ 'SERVICE.TEXT_53' | translate }}</div>   
              <div *ngIf="!showCommercial_msg">
                <p class="check-content">{{ 'SERVICE.TEXT_54' | translate }}<span class="is-clickable more pl-1" (click)="showCommercial_msg = true">{{'DASHBOARD.MORE' | translate}}</span>
                </p>
              </div>
              <div *ngIf="showCommercial_msg">
              <p class="check-content">{{ 'SERVICE.TEXT_54' | translate }}{{ 'SERVICE.TEXT_54.1' | translate }}<span class="is-clickable more pl-1" (click)="showCommercial_msg = false">{{'DASHBOARD.LESS' | translate}}</span>
              </p>
              </div>
            </div>
           
          </div>
        </div>
    </div> 
      <div *ngIf="!isOtpSend">
        <div class="control pt-2">
          <button id="btnSignUp" [disabled]="!btnDisabled" [ngClass]="btnDisabled ? 'is-success' : 'button-disabled'" class="button button-cta is-fullwidth submit-btn" (click)="sendOtp('')">{{ 'SERVICE.TEXT_30' |
            translate }}</button>
        </div>
        <div class="pt-2 has-text-centered ">
          <span class="route-link has-text-weight-bold has-text-centered"> {{ 'COMMON.SIGNUP'
            | translate }} <span (click)="clickLoginBook()" class="login-route">{{ 'COMMON.LOGIN_ROUTE'
              | translate }}</span> </span>
        </div>
      </div>
      <p *ngIf="isOtpSend" class="otp-text mb-0 pt-2">{{"COMMON.PLEASE_ENTER_OTP" | translate}}</p>
      <div *ngIf="isOtpSend">
        <div class="columns is-one-quarter is-mobile mt-2 mx-2 otp-wrapper is-flex-direction-column">
          <div class="column is-12" *ngIf="expire !== '00:00'">
            <input #otp1 id="otp1" inputmode="numeric" (keydown.backspace)="backspace($event, 1)" (keypress)="numberOnly($event)"
              [(ngModel)]="otpDetails.otp1" class="otp-bg mx-3" type="text" (keyup)="tabChange(1)" maxlength="1"
              autocomplete="off" (input)="verifyOTP()"/>
            <input #otp2 id="otp2" inputmode="numeric" (keydown.backspace)="backspace($event, 2)" (keypress)="numberOnly($event)"
              [(ngModel)]="otpDetails.otp2" class="otp-bg mx-3" type="text" (keyup)="tabChange(2)" maxlength="1"
              autocomplete="off" (input)="verifyOTP()"/>
            <input #otp3 id="otp3" inputmode="numeric" (keydown.backspace)="backspace($event, 3)" (keypress)="numberOnly($event)"
              [(ngModel)]="otpDetails.otp3" class="otp-bg mx-3" type="text" (keyup)="tabChange(3)" maxlength="1"
              autocomplete="off" (input)="verifyOTP()"/>
            <input #otp4 id="otp4" inputmode="numeric" (keydown.backspace)="backspace($event, 4)" (keypress)="numberOnly($event)"
              [(ngModel)]="otpDetails.otp4" class="otp-bg mx-3" type="text" (keyup)="tabChange(4)" maxlength="1"
              autocomplete="off" (input)="verifyOTP()"/>
          </div>
        </div>
        <div class="control p-2 has-text-centered">
          <div class="has-text-weight-bold" *ngIf="expire !== '00:00'">
            {{ expire }}
          </div>
          <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
        </div>
        <div class="control">
          <button *ngIf="expire === '00:00'" id="btnVerify" (click)="expire !== '00:00' ? verifyOTP() : sendOtp('Resend')"
            style="color: white; font-weight: bold; border-radius: 12px" class="button is-success is-fullwidth is-size-6 is-clickable">
            <p class="submit-btn" *ngIf="expire !== '00:00'">
              {{ "COMMON.VERIFY_OTP" | translate }}
            </p>
            <p class="submit-btn" *ngIf="expire === '00:00'">
              {{ "COMMON.RESEND_OTP" | translate }}
            </p>
          </button>
        </div>
        <div *ngIf="!isOtpSend" class="pt-2 has-text-centered ">
          <span class="route-link has-text-weight-bold has-text-centered"> {{ 'COMMON.SIGNUP'
            | translate }} <span (click)="clickLoginBook()" class="login-route">{{ 'COMMON.LOGIN_ROUTE'
              | translate }}</span> </span>
        </div>
      </div>
    </div>
  </div>
</div>