
<div class="is-flex">
  <div class="pt-4 has-text-centered view-garage-header">
    <div class="pt-1 is-flex is-justify-content-center">
      <div class="garage-img">
        <img [src]="image" class="image">
      </div> 
    </div>
    <div class="rating-buttons pb-1 pt-2">
      <div class="button is-small is-primary" style="cursor: default;">
          <div class="star-rating is-flex is-align-items-center"><i class="fas fa-star is-size-7 mr-1"></i> <span class="pt-1 font-14">{{ estimate_item?.rating?.cum_rating }}</span></div>
      </div>
      <p class="has-text-weight-semibold pt-2 font-14">{{estimate_item.openTime}} - {{estimate_item.closeTime}}</p>
    </div>
    <div class="pt-3">
      <p class="has-text-weight-semibold font-14 opening-hrs pb-2">Working hours</p> 
    <ul *ngFor="let item of estimate_item.timings;index as i" class="pb-2">
      <div class="columns m-0 is-flex is-justify-content-center"> 
          <p class=" has-text-weight-semibold day-color has-text-left">{{item.day}}</p>  
          <p class="has-text-weight-bold time-color has-text-left">{{item.time}}</p>
      </div> 
    </ul>
  </div>
  </div>
  <div class="my-4 tab-box">
    <div>
      <div class="font-18 has-text-weight-semibold">{{estimate_item.name}}</div>
      <div class="pt-2 pb-1 distance font-14" style="color: #4a4a4a;"><span> <strong >{{estimate_item.distance | number :
        '1.2-2'}} km</strong></span> - {{estimate_item.city}} / {{estimate_item.state}}
      </div>
      <div *ngIf="estimate_item?.specialization.length > 0" class="features-wrapper columns m-0 pb-1 is-multiline">
        <div class="features-inner pt-2" *ngFor="let item of estimate_item?.specialization index as i;">
          <div [ngClass]="i !=0 ? ' ' : ''" class="d-flex is-align-items-center specialization-data">
            <span class="font-14 px-3 py-1 m-0 pt-1" style="line-height: 1.2;">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="estimate_item?.amenities.length > 0" class="features-wrapper columns is-multiline pt-2 m-0">
        <div class="features-inner pb-1"
          *ngFor="let item of estimate_item?.amenities index as i;">
          <div [ngClass]="i !=0 ? '' : ''" class="d-flex is-align-items-center">
              <img class="image is-24x24" src="{{item.logo_url}}" />
            <span class="text-size-14 pl-1 pt-1">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="columns is-flex m-0 pt-2">
        <div *ngIf="estimate_item.network_name === 'Bosch Car Service'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
          <img class="mr-2 my-1" src="../../../assets/img/bosch_car_service.jpg" alt="" style="width: 30px; height: 30px;">          
        </div>
        <div *ngIf="estimate_item.tse_quality_checked === '1'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
          <img class="mr-2 my-1" src="../../../assets/img/tse.png" alt="" style="width: 45px; height: 30px;">
        </div>
        <div *ngIf="estimate_item.exhaust_emission_service === 'yes'" class="column is-narrow-mobile is-narrow-desktop is-narrow-tablet is-flex is-align-items-center p-0">
          <img class="mr-2 my-1" src="../../../assets/img/exhaust_emission.png" alt="" style="min-width: 55px; width: 55px; height: 30px;">
  
        </div>
      </div>
      <div *ngIf="estimate_item.about" class="font-14 pt-4">
        <p class="has-text-weight-semibold font-14 mb-0">About garage</p>
        <div class="pt-1">
          {{estimate_item.about}}
      </div>
      </div>
    </div>
   
  <div *ngIf="estimate_item?.media.length > 0" class="image-gallery pt-3">
      <div *ngFor="let media of estimate_item?.media;" class="image-item">
          <img [src]="media.img_url" alt="" class="photo-image">
      </div>
  </div>
  </div>
</div>
