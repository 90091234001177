
<div style="background-color: #F9F9F9">
    <div class="content">
        <div class="columns m-0 is-align-self-center service-content">
            <div class="column popular-service py-0 pb-2 ">
                <h3 class="pad-l-100 mt-4 pt-5">{{ 'HOME.TEXT_3' | translate }}</h3>
               
                <div class="columns is-pa-15 has-text-centered is-multiline popular-service-list mb-0  pad-l-100">
                    <div (click)="routeServices(item)" class="card service-card cursor-default column p-0 p-1 is-flex is-align-items-center" *ngFor="let item of popularService;index as i">
                        <div>
                            <img src='assets/svg/{{item.icon}}' />
                        <p class="has-text-left"> {{item.answer}} </p>
                        </div>
                    </div>
                    <div class="has-text-right" (click)="goToServices()">
                        <h5 class="has-text-success cursor-default all-service">  {{'SERVICE.TEXT_32' | translate}} </h5>
                </div>
                </div>
               
            </div>
            <div class="column popular-service py-0 problem pb-2">
                <h3 class="pad-r-100 mt-4 pt-5">{{ 'HOME.TEXT_56' | translate }}</h3>
                
                <div class="columns is-pa-15 has-text-centered is-multiline popular-service-list pad-r-100">
                    <div (click)="routeProblem(item)" class="card service-card cursor-default column p-0 p-1 is-flex is-align-items-center" *ngFor="let item of problemList;index as i">
                        <div>
                          
                            <img  src='assets/svg/{{item.icon}}' />

                            <p class="has-text-left"> {{item.answer}} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>

