import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss']
})
export class FilterDropdownComponent implements OnInit {
  @ViewChild('inputField') inputField!: ElementRef;
  @Input() optionList : string[] = [];
  @Output() optionChanged = new EventEmitter<any>();
  @Input() placeholder = '';
  @Input() defaultValue : any;
  @Input() disable: boolean = false;
  @Input() background : any;
  @Input() editVehicle : any;
  inputValue: string = '';
  selectedOption: string = '';
  showOptions: boolean = false;
  filteredOption: string [] = [];
  isActive: boolean= false

  static currentOpenDropdown: FilterDropdownComponent | null = null;

  constructor(private elementRef: ElementRef,  private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.filteredOption = this.optionList
    this.inputValue = this.defaultValue
    this.selectedOption = this.inputValue
  }
  ngOnChanges(changes: SimpleChanges){
    this.showOptions = false
        if (changes['defaultValue']) {
        this.inputValue = this.defaultValue
        this.selectedOption = this.inputValue
        if(this.selectedOption == '' && !this.disable){
          this.showOptions = true
        }
    }
    if (changes['optionList']) {
      if(this.optionList?.length === 1){
        this.inputValue = this.optionList[0]
        this.selectedOption = this.optionList[0]
        this.optionChanged.emit(this.selectedOption)
      }
    }
  }
  ngAfterViewChecked(){    
   if(this.inputValue){
    this.isActive = true;
   }
   this.cdRef.detectChanges();
  }
  inputChange(){
    let tempOptionStart = this.optionList?.filter((option: any) => option.toLowerCase().startsWith(this.inputValue?.trim().toLowerCase()));
    let tempOptionIncludes = this.optionList?.filter((option: any) =>{
      if(option.toLowerCase().includes(this.inputValue?.trim().toLowerCase())){
        if(tempOptionStart.some((item: string) => item.toLowerCase() === option.toLowerCase())){
          return false
        }
        return true
      }
      return false
    });
    this.filteredOption = [...tempOptionStart, ...tempOptionIncludes]
    if(this.inputValue.length == 0){
      this.selectedOption = ''
      this.optionChanged.emit(this.selectedOption)
    }
  }
  clearInput(){
    this.inputValue = ''
    this.filteredOption = this.optionList
    this.inputField.nativeElement.focus();
    this.selectedOption = ''
    this.optionChanged.emit(this.selectedOption)
  }
  formatInput(input: string): string {
    if (!this.inputValue || !input) {
      return input;
    }
    const escapedInputValue = this.inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regEx = new RegExp(escapedInputValue, 'gi');
    return input.replace(regEx, '<strong>$&</strong>');
  }
  selectOption(data: any) {
      this.inputValue = data; 
      this.showOptions = false;
      this.selectedOption = data
      this.optionChanged.emit(this.selectedOption)
  }

  clickInput(){
    if (FilterDropdownComponent.currentOpenDropdown && FilterDropdownComponent.currentOpenDropdown !== this) {
      FilterDropdownComponent.currentOpenDropdown.showOptions = false;
    }
    this.showOptions = true;
    FilterDropdownComponent.currentOpenDropdown = this;
    this.inputField.nativeElement.focus();
    if(this.inputValue == '' || this.inputValue == undefined){
      this.filteredOption = this.optionList
    }else{
      this.inputChange()
    } 
  }
  onFocus() {  
    this.isActive = true;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const ClickedElement = event.target as HTMLElement
    const filterContainer = ClickedElement.closest('.filter-container');
    const btnClick = ClickedElement.classList.contains('btn-click');
    const arrowImg = ClickedElement.classList.contains('arrow-img');
    const option = ClickedElement.classList.contains('option');

    if (!filterContainer && !btnClick && !arrowImg && !option) {
      this.showOptions =  false;
         this.isActive = false
      
      if(this.selectedOption){
        this.inputValue = this.selectedOption
      }
      if(this.inputValue){
        this.isActive = true;
       }
    } 
  }

}
