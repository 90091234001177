
<div class="content-inner">
  <div class="header-content">
    <h4 class="has-text-weight-bold pt-1 is-hidden"> {{'SERVICE.TEXT_20' | translate}}
      <img class="is-hidden" (click)="previousStep()" src="assets/svg/arrow-left-circle.svg" style="float: right;" />
    </h4>
  <div>
      <div class="is-flex is-justify-content-space-between show-map-wrapper pb-1">
          <div  *ngIf="vechicleEstimateDetails.length !== 0" class="map-btn is-flex is-justify-content-space-around">
              <button  id="btnShowMap" (click)="onClickShowMap()" class="button button-common has-text-weight-semibold">{{ showMapText }}  <img class="location-icon" src="assets/svg/show-map.svg" /></button>
              <div *ngIf="vechicleEstimateDetails?.length > 1" style="width: 160px;" class="is-hidden-tablet">
                <app-dropdown [option_list]="[{name: 'COMMON.RATINGS', id: 'ratings'},{name:'COMMON.DISTANCE', id:'distance' },{ name:'COMMON.PRICE', id: 'price' }]" [selected_value]="'COMMON.RATINGS'" [alignmentEnd]="true"
                (option_changed)="serviceChanged($event)"></app-dropdown>
              </div>
              <!-- <p *ngIf="!isDataLoading" class="has-text-centered pt-4 pb-1 has-text-weight-semibold view-details is-clickable" style="color: #aad043; line-height: 0;" (click)="showQuoteDetail()">{{"SERVICE.TEXT_45" | translate}}</p> -->
          </div>
          <div *ngIf="vechicleEstimateDetails?.length > 1" class="buttons is-flex is-justify-content-end is-hidden-mobile">
              <div id="btnRating" class="filter-link is-clickable px-2 text-size-14 is-success" (click)="ratingSort()">{{'COMMON.RATINGS' |
                  translate}}</div>
              <div id="btnDistance" class="filter-link is-clickable px-2 text-size-14" (click)="distanceSort()" >{{'COMMON.DISTANCE' | translate}}</div>
              <div id="btnPrice" class="filter-link is-clickable px-2 text-size-14" (click)="priceSort()" >{{'COMMON.PRICE' | translate}}</div>
          </div>
      
      </div>
  </div>
  <div *ngIf="vechicleEstimateDetails.length !== 0 && !isMobile" class="columns m-0">
    <div class="column is-narrow p-0 is-flex is-align-items-center">
      <div class="pt-1 has-text-weight-bold is-flex is-align-items-center text-size-14"><p class="garage_count pt-1 mr-2">{{vechicleEstimateDetails.length}}</p> {{displayMatches}}</div>

    </div>
    <div class="column is-fluid pr-0 py-0 pl-2">
      <div class="px-1 pt-1">
        <p class="text-size-14">{{'DASHBOARD.APPOINTMENT_TEXT_1' | translate}}</p>
        <p class="text-size-14">{{'DASHBOARD.APPOINTMENT_TEXT_1.1' | translate}}</p>
      </div>

    </div>
  </div>
  <div *ngIf="vechicleEstimateDetails.length !== 0 && isMobile">
    <div class="pt-1 has-text-weight-bold px-1 is-flex is-align-items-center text-size-14"><p class="garage_count mr-2">{{vechicleEstimateDetails.length}}</p> {{displayMatches}}</div>
  <div class="is-flex px-1 text-size-14">
   <p>{{'DASHBOARD.APPOINTMENT_TEXT_1' | translate}}<span *ngIf="!showMore" class="more" (click)="showMore = true">
    {{'DASHBOARD.MORE' | translate}}</span><span *ngIf="showMore"> {{'DASHBOARD.APPOINTMENT_TEXT_1.1' | translate}}</span>
  <span *ngIf="showMore" class="more" (click)="showMore = false">
    {{'DASHBOARD.LESS' | translate}}
  </span>
  </p>
  </div>
  </div>
  </div>
    <!-- <br> -->
    <div class="">
        <div *ngIf="isDataLoading" class="spinner-wrapper" style="height: 300px;">
            <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <!-- <div *ngIf="vechicleEstimateDetails.length === 0 && !isDataLoading">
            <app-no-data-found></app-no-data-found>
        </div> -->
        <div class="columns is-multiline my-0 ml-0" >
            <div class="column is-6-desktop is-12-tablet pl-0 pr-3 pt-2 pb-0" *ngFor="let item of vechicleEstimateDetails">
                <app-estimate-line-item [quoteId]="quoteId" [estimate_item]="item" [quotation]="quotation"></app-estimate-line-item>
            </div>
        </div>
    </div>
</div>
<div *ngIf="vechicleEstimateDetails.length == 0 && userId && !isDataLoading" class="p-5">
  <p class="text-size-20 has-text-weight-semibold color-arabama-green">{{ "COMMON.THANKYOU_FOR_REACHING_OUT_ARABAMA_TEXT_2" | translate }} <br><p class="text-size-16 pt-3">{{ "COMMON.THANKYOU_FOR_REACHING_OUT_ARABAMA_TEXT_1" | translate }}</p></p>
  <p class=" text-size-16 pt-3 no-estimate-title">{{ "COMMON.THANKYOU_TEXT_2" | translate }}</p>
  <div class="pt-5">
    <button class="button is-success has-text-weight-semibold continue-btn is-flex is-justify-content-center" routerLink="/dashboard">{{ "COMMON.GO_TO_DASHBOARD" | translate }}
    </button>  
  </div>
</div>
<div *ngIf="vechicleEstimateDetails.length == 0 && !userId && !isDataLoading" class="px-5 py-2">
    <p class="text-size-20 has-text-weight-semibold color-arabama-green">{{ "COMMON.THANKYOU_FOR_REACHING_OUT_ARABAMA_TEXT_2" | translate }}</p>
    <p class="text-size-16 pt-3 no-estimate-title pb-3">{{ "COMMON.THANKYOU_TEXT_1" | translate }}</p>
    <!-- <div class="pt-3 register-field" >
        <div class="field" *ngIf="!isOtpSend">
          <div class="control">
            <input class="input" type="text" :placeholder="{{ 'COMMON.FIRST_NAME' | translate }}" [formControl]="firstNameFormControl"  (ngModelChange)="onInputFirstNameChange($event)"
               >
               <label *ngIf="!isFirstNameValid" class="pl-2 text-size-11" style="margin-left: 3px;color:red">{{'ERROR_MESSAGE.error_valid_first_name'|translate}}</label>
          </div>
        </div>
        <div class="field" *ngIf="!isOtpSend">
          <div class="control">
            <input class="input  " type="text" :placeholder="{{ 'COMMON.LAST_NAME' | translate }}" [formControl]="lastNameFormControl" (ngModelChange)="onInputLastNameChange($event)"
               >
               <label *ngIf="!isLastNameValid" class="pl-2 text-size-11" style="margin-left: 3px;color:red">{{'ERROR_MESSAGE.error_valid_last_name'|translate}}</label>

          </div>
        </div>
        <div class="field">
          <div class="control">
            <input class="input" type="text" :placeholder="{{ 'COMMON.EMAIL_ID' | translate }}" [formControl]="emailIdFormControl" (ngModelChange)="onInputEmailChange($event)"  [readOnly]="isOtpSend"
               >
               <label *ngIf="!isEmailValid" class="pl-2 text-size-11" style="margin-left: 3px;color:red">{{'ERROR_MESSAGE.error_valid_email'|translate}}</label>

          </div>
        </div>
        <div class="field" *ngIf="!isOtpSend">
          <div class="control">
            <input class="input" type="text" min="3" minlength="17" maxlength="17" (input)="numberOnly($event)"
            :placeholder="{{ 'COMMON.PHONE' | translate }}" [formControl]="phoFormControl" (ngModelChange)="onInputPhChange($event)" [value]=[ph]
               >
               <label *ngIf="!isPhnValid" class="pl-2 text-size-11" style="margin-left: 3px;color:red">{{'ERROR_MESSAGE.error_valid_phone'|translate}}</label>

          </div>
        </div>   -->

        <!-- <div class="control pt-2">
          <button [disabled]="!btnDisabled" [ngClass]="btnDisabled ? 'is-success' : 'button-disabled'" class="button button-cta is-fullwidth submit-btn" (click)="loginService()" >{{ "COMMON.NOTIFY_ME" | translate }}</button>
        </div>
        <div class="pt-2 has-text-centered ">
          <span class="text-size-14 has-text-weight-bold has-text-centered" (click)="saveEstimate()"> {{ 'COMMON.SIGNUP'
            | translate }}<span class="login-route"> {{ 'COMMON.LOGIN_ROUTE'
              | translate }}</span> </span>
        </div> -->
        <!-- <div class="pt-2" *ngIf="!isOtpSend">
          <p class="has-text-weight-semibold check-text">arabamaservis.com <span class="is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'SERVICE.TEXT_55' | translate }}</span>{{ 'SERVICE.TEXT_56' | translate }}</p>
        </div>
        <div class="field ml-3 pt-2" *ngIf="!isOtpSend">
            <div class="columns is-align-items-center">  
              <div class="column p-0 d-flex"> 
                <div class="pt-3">
                  <input type="checkbox" [formControl]="memberApprovalFormControl" (change)="memberCheckbox($event)"> 
                </div>
                <div class="ml-3">
                  <div class="contct-conf pt-3 pb-1 has-text-weight-bold check-text">{{ 'SERVICE.TEXT_50' | translate }}</div>               
                  <div *ngIf="!showMember_approval">
                    <p class="check-content"><span class="has-text-weight-semibold is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'SERVICE.TEXT_51' | translate }}</span><span class="is-clickable more pl-1" (click)="showMember_approval = true">{{'DASHBOARD.MORE' | translate}}</span>
                    </p>
                  </div>
                  <div *ngIf="showMember_approval">
                  <p class="check-content"><span class="has-text-weight-semibold is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'SERVICE.TEXT_51' | translate }}</span>{{ 'SERVICE.TEXT_52' | translate }}<span class="is-clickable more pl-1" (click)="showMember_approval = false">{{'DASHBOARD.LESS' | translate}}</span>
                  </p>
                  </div>
                </div>          
              </div>
            </div>
        </div> 
        <div class="field ml-3" *ngIf="!isOtpSend">
            <div class="columns is-align-items-center">  
              <div class="column p-0 d-flex"> 
                <div class="pt-3">
                  <input type="checkbox" [formControl]="contactConfirmFormControl" (change)="getCheckboxValue($event)"> 
                </div>
                <div class="ml-3">
                  <div class="contct-conf pt-3 pb-1 check-text has-text-weight-semibold">{{ 'SERVICE.TEXT_28' | translate }}</div> 
                  <div *ngIf="!showContact_confirm">
                    <p class="check-content mb-0">{{ 'SERVICE.TEXT_46' | translate }}<span class="has-text-weight-semibold is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'SERVICE.TEXT_47' | translate }}</span><span class="is-clickable more pl-1" (click)="showContact_confirm = true">{{'DASHBOARD.MORE' | translate}}</span>
                    </p>
                  </div>
                  <div *ngIf="showContact_confirm">
                  <p class="check-content mb-0">{{ 'SERVICE.TEXT_46' | translate }}<span class="has-text-weight-semibold is-clickable" style="color: #aad043; text-decoration: underline;">{{ 'SERVICE.TEXT_47' | translate }}</span>{{ 'SERVICE.TEXT_48' | translate }}
                  </p>
                  <p class="check-content">{{ 'SERVICE.TEXT_49' | translate }}<span class="is-clickable more pl-1" (click)="showContact_confirm = false">{{'DASHBOARD.LESS' | translate}}</span>
                  </p>
                  </div>
                </div>      
              </div>
            </div>
        </div> 
        <div class="field ml-3 pb-1" *ngIf="!isOtpSend">
          <div class="columns is-align-items-center">  
            <div class="column p-0 d-flex"> 
              <div class="pt-3">
                <input type="checkbox" [formControl]="commercialMsgFormControl" (change)="messageCheckbox($event)"> 
              </div>
              <div class="ml-3">
                <div class="contct-conf pt-3 pb-1 has-text-weight-bold check-text">{{ 'SERVICE.TEXT_53' | translate }}</div>   
                <div *ngIf="!showCommercial_msg">
                  <p class="check-content">{{ 'SERVICE.TEXT_54' | translate }}<span class="is-clickable more pl-1" (click)="showCommercial_msg = true">{{'DASHBOARD.MORE' | translate}}</span>
                  </p>
                </div>
                <div *ngIf="showCommercial_msg">
                <p class="check-content">{{ 'SERVICE.TEXT_54' | translate }}{{ 'SERVICE.TEXT_54.1' | translate }}<span class="is-clickable more pl-1" (click)="showCommercial_msg = false">{{'DASHBOARD.LESS' | translate}}</span>
                </p>
                </div>
              </div>
             
            </div>
          </div>
      </div> 
        <div *ngIf="!isOtpSend">
          <div class="control pt-2">
            <button id="btnSignUp" [disabled]="!btnDisabled" [ngClass]="btnDisabled ? 'is-success' : 'button-disabled'" class="button button-cta is-fullwidth submit-btn" (click)="sendOtp('')">{{ 'SERVICE.TEXT_30' |
              translate }}</button>
          </div>
          <div class="pt-2 has-text-centered ">
            <span class="route-link has-text-weight-bold has-text-centered"> {{ 'COMMON.SIGNUP'
              | translate }} <span (click)="saveEstimate()" class="login-route">{{ 'COMMON.LOGIN_ROUTE'
                | translate }}</span> </span>
          </div>
        </div>
        <div *ngIf="isOtpSend">
          <div class="columns is-one-quarter is-mobile mt-2 mx-2 otp-wrapper is-flex-direction-column">
            <div class="column is-12" *ngIf="expire !== '00:00'">
              <input #otp1 id="otp1" inputmode="numeric" (keydown.backspace)="backspace($event, 1)" (keypress)="numberOnly($event)"
                [(ngModel)]="otpDetails.otp1" class="otp-bg mx-3" type="text" (keyup)="tabChange(1)" maxlength="1"
                autocomplete="off" (input)="verifyOTP()"/>
              <input #otp2 id="otp2" inputmode="numeric" (keydown.backspace)="backspace($event, 2)" (keypress)="numberOnly($event)"
                [(ngModel)]="otpDetails.otp2" class="otp-bg mx-3" type="text" (keyup)="tabChange(2)" maxlength="1"
                autocomplete="off" (input)="verifyOTP()"/>
              <input #otp3 id="otp3" inputmode="numeric" (keydown.backspace)="backspace($event, 3)" (keypress)="numberOnly($event)"
                [(ngModel)]="otpDetails.otp3" class="otp-bg mx-3" type="text" (keyup)="tabChange(3)" maxlength="1"
                autocomplete="off" (input)="verifyOTP()"/>
              <input #otp4 id="otp4" inputmode="numeric" (keydown.backspace)="backspace($event, 4)" (keypress)="numberOnly($event)"
                [(ngModel)]="otpDetails.otp4" class="otp-bg mx-3" type="text" (keyup)="tabChange(4)" maxlength="1"
                autocomplete="off" (input)="verifyOTP()"/>
            </div>
          </div>
          <div class="control has-text-centered">
            <div class="has-text-weight-bold" *ngIf="expire !== '00:00'">
              {{ expire }}
            </div>
            <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
          </div>
          <div class="control">
            <button *ngIf="expire === '00:00'" id="btnVerify" (click)="expire !== '00:00' ? verifyOTP() : sendOtp('Resend')"
              style="color: white; font-weight: bold; border-radius: 12px" class="button is-success is-fullwidth is-size-6 is-clickable">
              <p class="submit-btn" *ngIf="expire !== '00:00'">
                {{ "COMMON.VERIFY_OTP" | translate }}
              </p>
              <p class="submit-btn" *ngIf="expire === '00:00'">
                {{ "COMMON.RESEND_OTP" | translate }}
              </p>
            </button>
          </div> -->
          <!-- <div class="pt-2 has-text-centered ">
            <span class="route-link has-text-weight-bold has-text-centered"> {{ 'COMMON.SIGNUP'
              | translate }} <span (click)="clickLoginBook()" class="login-route">{{ 'COMMON.LOGIN_ROUTE'
                | translate }}</span> </span>
          </div> -->
        <!-- </div>
      </div> -->
  </div>
  <div *ngIf="vechicleEstimateDetails.length == 0 && !userId && !isDataLoading" [ngClass]="isMobile? 'has-text-centered' : 'is-flex is-justify-content-space-between is-align-items-center'" class="px-5 py-2 ">
  <button class="button is-success has-text-weight-semibold continue-btn" (click)="signup()">{{'SERVICE.TEXT_30' | translate}}</button>

  <div class="pt-1">
    <span class="route-link has-text-weight-bold has-text-centered"> {{ 'COMMON.SIGNUP'
      | translate }} <span routerLink="/auth" class="login-route" (click)="saveEstimate()">{{ 'COMMON.LOGIN_ROUTE'
        | translate }}</span> </span>
  </div>
</div>