    <nav class="navbar" role="navigation" aria-label="main navigation" >
      <div
        class="navbar-brand is-pa-l-10" 
      >
        <img
          routerLink="/home"
          class="is-clickable"
          src="assets/img/arabama.png"
          style="display: block"/>
      </div>
    </nav>
    <div class="pad-60">
        <div class="kpi-dashboard pb-0">
            <div class="columns m-0">
                <div class="column is-narrow">
                    <h2 class="pad-top has-text-weight-bold" style="font-size: 24px;">
                      Legal policy
                    </h2>
                </div>
            </div>
        </div>
        <div *ngIf="legalCategories?.length > 0" class="columns m-0">
            <div class="column is-2 py-0 sidebar-area">
                <nav class="pt-0">
                    <ul class="pad-top">
                      <li *ngFor="let category of legalCategories" class="pb-4">
                        <a (click)="categoryChange(category)" [ngClass]="selectedCategoryId == category.id? 'is-active': ''" class="category-text" style="font-size: 16px;">{{category.category_name}}</a>
                      </li>
                    </ul>
                </nav>
            </div>
            <div *ngIf="selectedItem" class="column mb-4" style="background-color: #fafafa;">
                  <div class="columns mb-0 px-5 pt-3 is-flex is-justify-content-space-between">
                      <div class="column is-narrow">
                          <h2 class="has-text-weight-bold category-title" style="font-size: 22px;">
                            {{selectedItem.category_name}}
                          </h2>
                          
                      </div>
                  </div>
                  <div *ngIf="selectedItem.category_description" class="columns px-5 pt-2 mb-0">
                      <div class="column pt-0">
                          <div style="font-size: 14px;" [innerHTML]="selectedItem.category_description"></div>
                      </div>
                  </div>
                 
              </div>
        </div>
    </div>
    