import { Component, OnInit } from '@angular/core';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { Constants } from 'src/app/Constants/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from 'src/app/common/utils';
import * as moment from 'moment';

@Component({
  selector: 'app-view-quote',
  templateUrl: './view-quote.component.html',
  styleUrls: ['./view-quote.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-40%)' }), // Start invisible and off-screen
        animate('0.7s ease-in-out', style({ opacity: 1, transform: 'translateX(0%)' })), // Slide in and become visible
      ]),
      transition(':leave', [
        animate('0.7s ease-in-out', style({ opacity: 0, transform: 'translateX(100%)' })), // Slide out and become invisible
      ]),
    ])
  ],
})
export class ViewQuoteComponent implements OnInit {

  constructor(private sharedService: SharedServiceService, private router: ActivatedRoute,
private route: Router, private utils:Utils) { }
  stepperCount = 3;
  image: any = '';
  estimateItem: any;
  quotation: any;
  amenitiesLength: number = 3;
  defaultAmenitiesCount: number = 3; 
  specializationLength: number = 3;
  defaultSpecializationCount: number = 3; 
  showGallery : boolean = false
  from: any;

  ngOnInit(): void {
    if(this.utils.isMobile()){
      this.defaultAmenitiesCount = 2
      this.amenitiesLength = 2 
      this.defaultSpecializationCount = 2
      this.specializationLength = 2 
    }
    this.router.queryParams.subscribe(params => {
      this.from = params['from'];
    });
    if (history.state != undefined) {
      let data = history.state.data
      if (data && data != null) {
        this.estimateItem = data.dataItem
        this.quotation = data.quotation
        if(data.dataItem.logo_url != null){
          this.image = data.dataItem.logo_url
        }else{
          this.image = 'assets/img/no-image.png';
        }
        sessionStorage.setItem(Constants.APP.SESSION_VIEW_QUOTE, JSON.stringify(history.state.data))
      } else if(sessionStorage.getItem(Constants.APP.SESSION_VIEW_QUOTE)){
        data = JSON.parse(sessionStorage.getItem(Constants.APP.SESSION_VIEW_QUOTE) || '{}')
        this.estimateItem = data.dataItem
        this.quotation = data.quotation
        if(data.dataItem.logo_url != null){
          this.image = data.dataItem.logo_url
        }else{
          this.image = 'assets/img/no-image.png';
        }
      } else{
        this.route.navigate(['service/estimates'])
      }
    }
    this.getOpeningHours()
    if(this.estimateItem.about.length > 50){
      this.estimateItem.showMore = true
    }else{
      this.estimateItem.showMore = false
    } 
    
  }
  getOpeningHours(){
    // const date = moment();
    // this.estimateItem.timings.forEach((element:any) => {
      
    //    if(element.start_time && element.end_time){
    //       if(date.day() === element.day_of_week){
    //          const now = moment();
    //          const closingTimeMoment = moment(element.end_time, "HH:mm");
    //          const diff = moment.duration(closingTimeMoment.diff(now));
    //          // Determine open status
    //          this.estimateItem.openStatus = diff.asHours() > 0;
    //          // Format the opening and closing times
    //          this.estimateItem.openTime = moment(element.start_time, "HH:mm").format("HH:mm");
    //          this.estimateItem.closeTime = closingTimeMoment.format("HH:mm");
             
    //       }
    //    }
    // });
    if (this.estimateItem.timings != undefined) {
      this.estimateItem.timings.sort((a: any, b: any) => {
        if ((a.day_of_week != undefined && b.day_of_week != undefined) || (a.day_of_week !== '' && b.day_of_week !== '')) {
          return a.day_of_week > b.day_of_week ? 1 : -1
        }
        return -1
      }
      );
      for (let index = 1; index < 8; index++) {
        const findTiming = this.estimateItem.timings.find((element: any) => element.day_of_week === index);
        if (findTiming == undefined) {
          this.estimateItem.timings.push({
            day_of_week: index,
          })
        }
      }
      this.estimateItem.timings.forEach((element: any, index: number) => {
        element.day = moment().locale('tr').weekday(index).format('ddd')
        if (element.start_time.length !== 0 && element.end_time.length !== 0) {
          let start_time = moment(element.start_time, 'HH:mm').format('h:mm  ')
          let end_time = moment(element.end_time, 'HH:mm').format('  HH:mm ')
          element.time = start_time +' - '+ end_time
        }else{
          element.time = 'Kapalı'
        }
      });
    }
  }
  garageGallery(){
    this.showGallery = true
  }
  onClickClose() {
    localStorage.removeItem(Constants.APP.SERVICE_LIST)
    localStorage.removeItem("address")
    localStorage.removeItem("zipcode")
    // sessionStorage.removeItem(Constants.APP.SESSION_VECHICLE_LIST)
    sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
    sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
    sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
    sessionStorage.removeItem(Constants.APP.QUOTE_ID)
    this.sharedService.clearData()
    document.body.classList.remove('inner-stepper');
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    if (userdata != undefined) {
      this.route.navigate(['/dashboard'])
    } else {
      this.route.navigate(['/home'])
    }
  }
  backPressed(){
    if(this.quotation){
      sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
      sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
      sessionStorage.removeItem(Constants.APP.QUOTE_ID)
      localStorage.removeItem(Constants.APP.SERVICE_LIST)
      if(this.from === 'dashboard'){
        this.route.navigate(['/dashboard'])
      } else if(this.from = 'service-history'){
        this.route.navigate(['/profile/service-history']) 
      }

    }else{
      if(this.from == 'map'){
        this.route.navigate(['service/estimates'], {state: {from: this.from} })
      }else{
        this.route.navigate(['service/estimates'])
      }
    }
  }
  ngOnDestroy(){
    sessionStorage.removeItem(Constants.APP.SESSION_VIEW_QUOTE)
  }
}
