import { Component, ElementRef, OnInit, ViewChild,OnDestroy, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'src/app/common/utils';
import { Constants } from 'src/app/Constants/constants';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { PassDataService } from 'src/app/services/pass-data.service';
import { takeWhile } from 'rxjs';
import { MatCalendar } from '@angular/material/datepicker';
import * as moment from 'moment';
import { CustomNativeDateAdapter } from 'src/app/adapter/CustomNativeDateAdapter';
import { BookingServiceService } from 'src/app/services/booking-service.service';
import { DateAdapter } from '@angular/material/core';
import { SocketService } from 'src/app/services/socket.service';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-service-request-book',
  templateUrl: './service-request-book.component.html',
  styleUrls: ['./service-request-book.component.scss'],
})
export class ServiceRequestBookComponent implements OnInit,OnDestroy {
  @ViewChild('calendar2') calendar2: MatCalendar <Date>| undefined ;
  @ViewChild('calendar1') calendar1: MatCalendar<Date> | undefined;
    @ViewChild('dateList') dateList : any = ElementRef;
  panelOpenState = false;
  selectedTimeDate: any = [];
  isAliva: boolean = true;
  selected = new Date();
  minDate = new Date();
  isAlternateButton:boolean=true;
  isAlternateDate: boolean = true;
  cal1Header:string = ''
  cal2Header:string = ''
  date = new Date();
  selectedMonth = new Date();
  moment1Cal : any = moment()
  moment2Cal : any = moment()
  selectedDate = new Date();
  isShowingSelected: boolean = false
  isShowingCalendar: boolean = false
  selectedDateList: any = [];
  selectedCalIndex: any = [];
  isTimeMatch: boolean = true;
  isTimeMisMatch: boolean = false;
  isFromDashBoardViewDetails: boolean = false;
  isFromLoginViewDetails: boolean = false
  isDataLoading: boolean = false
  viewDetailsEstimate: any = {}
  private serviceData: any = {}
  vechicleEstimateDetails: any = []
  alive : boolean = true
  constructor(private sharedService: SharedServiceService,
    private BookingService: BookingServiceService,
    private route: Router,
    private translate: TranslateService,
    private UtilsClass: Utils,
    private passDataService: PassDataService,
    private _dateAdapter: DateAdapter<Date>,
    private socketService: SocketService,
    private mapsApiLoader : MapsAPILoader) { }

  appointment = [{
      name: "Randevu zamanı",
      id: 1
    }
  ]

  ngOnInit(): void {
    if (history.state != undefined) {
      if (history.state.estimateViewItem) {
          this.viewDetailsEstimate = history.state.estimateViewItem
          sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(this.viewDetailsEstimate.vehicle_details[0]))
        this.isDataLoading = true
        this.fetchAddressFromZipcode()
        this.viewDetailsPayloadEstimate()
        this.getEstimateData()   
       
        
      }
      else{
        this.isFromLoginViewDetails = true;
      }
    }
    
    this.getCalenderHeader()
    sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
    this.passDataService.getBackPressed().pipe(takeWhile(()=>this.isAliva)).subscribe((data)=>{
      if(data === '4'){
        this.previousStep()
      }
    })

    this.sharedService.deleteAppointment$.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.appointment.pop()
      this.isAlternateButton = true
    })
  }

  ngAfterViewInit(){ setTimeout(()=>{ this.sharedService.updateData('4'); },500) }

  scrollToLeft(){
    this.dateList.nativeElement.scrollLeft -=150;
  }

  scrollToRight(){
    this.dateList.nativeElement.scrollLeft += 150;
  }

  nextStepper() {
    document.getElementById('btncontinue')?.classList.add('is-loading')
    if(this.selectedCalIndex.calIndex == 2){
      if(this.selectedCalIndex.datePick == false){
        this.isAlternateDate = false;
      }
      else{     
        this.isAlternateDate = true;
      }
    }     
    
    if(this.selectedTimeDate.length > 0 && this.selectedCalIndex.datePick == true && this.selectedTimeDate[0].datePick == true && this.isTimeMatch == true){
      sessionStorage.setItem(Constants.APP.SESSION_ESTIMATE_TIME,JSON.stringify(this.selectedTimeDate))
      this.sharedService.updateData('5');  
      this.sharedService.setEstimateTimeData(JSON.stringify(this.selectedTimeDate))
      let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
      if (userdata == undefined && this.isTimeMatch == true && this.isAlternateDate == true ) {
        document.getElementById('btncontinue')?.classList.remove('is-loading')
        this.route.navigate(['/service/confirm'])
      }else if(this.isTimeMatch == true && this.isAlternateDate == true){
        this.bookAppointment()
      }
      else if(this.isAlternateDate == false){
        this.UtilsClass.openErrorSnackBar('DASHBOARD.ALTERNATIVE_TIME_MUST_NEEDED');
        document.getElementById('btncontinue')?.classList.remove('is-loading')
      }
      else{
        if(this.isTimeMisMatch ==  true){ 
          this.UtilsClass.openErrorSnackBar('DASHBOARD.ALTERNATIVE_TIME_MUST_GREATER_THAN_FIRST')
        document.getElementById('btncontinue')?.classList.remove('is-loading')
      }
      else{
        this.UtilsClass.openErrorSnackBar('DASHBOARD.SAME_APPOINTMENT_TIME_CANNOT_SELECTED')
        document.getElementById('btncontinue')?.classList.remove('is-loading')
      }
      }   
    }else{
      this.UtilsClass.openErrorSnackBar("DASHBOARD.SELECT_DATE_AND_TIME") 
      document.getElementById('btncontinue')?.classList.remove('is-loading')
    }
    sessionStorage.removeItem(Constants.APP.LAST_INDEX_TIME)
  }

  viewDetailsPayloadEstimate() {
    let serviceList: any = []
    this.viewDetailsEstimate.items.forEach((element: any) => {
      serviceList.push({
        "id": element.service_id,
        "comments": element.notes,
        "is_from_all_service": element.is_service_from_master ? true : false,
        "service_id" : element.service_master_id,
        "selected_answer" : element.selected_answer
      
      })
    });
    this.serviceData = {
      "zip_code": this.viewDetailsEstimate.zip_code,
      "user_id": this.viewDetailsEstimate.user_id,
      "vechicle_id": this.viewDetailsEstimate.vechicle_id,
      "service": serviceList
    }
    if(this.viewDetailsEstimate.quote_id){
        this.serviceData.quote_id = this.viewDetailsEstimate.quote_id
    }
    sessionStorage.setItem(Constants.APP.QUOTE_ID,this.viewDetailsEstimate.quote_id);
    this.passDataService.setUpdateFromEstimate(false)
   
  }

  getEstimateData() {
    this.BookingService.getDirectQuotes(this.serviceData).subscribe({
      next: (res) => {   
        if (res.statusCode == 1) {
          this.vechicleEstimateDetails = res.garages
          let serviceList = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE);
          if (serviceList != null) {
            let serviceListDetails = JSON.parse(serviceList)
            serviceListDetails.garage_count = this.vechicleEstimateDetails.length
            sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(serviceListDetails))
          }         
          this.vechicleEstimateDetails.forEach((item: any) => {
            if (item.quote == null) {
              item.quote = {
                total: 0,
                subtotal: 0
              }
            } else if (item.quote != null) {
              item.quote.total = Number(this.viewDetailsEstimate.total_amount).toLocaleString('tr', { style: 'currency', currency: 'TRY' })
              item.quote.finalPrice = this.viewDetailsEstimate.total_amount;
            }
      
            if(item.reviews != null){
              item.rating = {}
              let totalRating = 0;
              item.reviews.forEach((element:any) => {
                 totalRating += element.ratings;
              });
              item.rating.cum_rating = totalRating / item.reviews.length
              item.rating.totalRating = item.reviews.length
            }

            if(item.id === this.viewDetailsEstimate.garage_id){
              item.quote.items.forEach((element:any)=>{
                this.viewDetailsEstimate.items.forEach((element1:any)=>{
                    if(element1.service_id == element.service_id){
                      element.estimation_type = element1.estimation_type;
                    }
                });
              });
      
              item.invoice_quote.total = item.invoice_quote.total.toLocaleString('tr',{style: 'currency', currency: 'TRY'}) 
              sessionStorage.setItem(Constants.APP.SESSION_ESTIMATE_DETAILS,JSON.stringify(item));
         this.sharedService.setEstimateData(item)
         this.isFromDashBoardViewDetails = true
           }     
          })
        }
        this.isDataLoading = false
      },
      error: (err) => {
        this.isDataLoading = false
      }
    })
    
   
  }

  fetchAddressFromZipcode() {
    this.mapsApiLoader.load().then(()=>{
    var geocoder = new google.maps.Geocoder();
    var southWest = new google.maps.LatLng(40.811404,28.595554);
    var northEast = new google.maps.LatLng(41.199239,29.428805);
    var istanbulBounds = new google.maps.LatLngBounds(southWest, northEast);
   
    let _this = this
    let address = ''
    let pincode = ''
    geocoder.geocode({ address: this.viewDetailsEstimate.zip_code, componentRestrictions: { country: "TR" }, bounds: istanbulBounds },  (result, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        let addressDetails = result[0].address_components;
       
        if (addressDetails.length > 0) {
          addressDetails.forEach(element => {
            if (element.types[0].endsWith('postal_code')) {
              pincode = element.long_name;
            }
            if (element.types[0].endsWith('administrative_area_level_4')) {
              address = element.long_name
            }
            if (element.types[0].endsWith('administrative_area_level_2')) {
              address = address + " " + element.long_name
            }
            if (element.types[0].endsWith('administrative_area_level_1')) {
              address = address + ' - ' + element.long_name
            }
            localStorage.setItem("zipcode", pincode);
            localStorage.setItem("address", address);
          });
         
          _this.sharedService.setAddress(address)
          _this.fetchVehcileByID(_this.viewDetailsEstimate.vechicle_id)
        }
       
      }
    });
    if(address.length == 0){
      address = this.viewDetailsEstimate.zip_code
     localStorage.setItem("address", address)
     _this.sharedService.setAddress(address)
   }
  }).catch((err) => {
    console.log(err)
  })

    let serviceList:any = []
    this.viewDetailsEstimate.items.forEach((element:any) => {
      let service={
        question:{
          "id":element.service_id,
          "answer": element.name,
        },
        answer:{
          "id": element.service_id,
          "answer":element.answer,
        },
        comment: element.notes,
        selected_answer : element.selected_answer,
        is_from_all_service: element.is_service_from_master ? true : false
      }
      serviceList.push(service)
    });
    localStorage.setItem(Constants.APP.SERVICE_LIST, JSON.stringify(serviceList))
    this.sharedService.updateServiceList("update")
  }

  fetchVehcileByID(id:any) {
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    let userDetails;
    if (userdata != undefined) {
      userDetails = JSON.parse(userdata)
    }
    this.BookingService.fetchVehicleByUser(userDetails.user_id).subscribe({
      error: (error) => {
      }, // errorHandler 
      next: (res) => {
        if (res.statusCode == 1) {
          let userVehcileData = res.vechicle
            userVehcileData.forEach((element: any) => {
              if (element.vechicle_id == id) {
                element.vechicle_id = element.vechicle_id
                element.model_year = element.start_production_year
                element.vechile_make_name = element.make_name
                element.vechile_model_name = element.model_name
                element.vechile_make = element.make_id
                element.vechile_model = element.model_id
                element.generation = element.generation_id
                element.series = element.series_id
                element.img = element.logo
                sessionStorage.setItem(Constants.APP.SESSION_ORDER_SERVICE, JSON.stringify(element))    
              }
            });
            this.passDataService.getVehicleData()
          }
        }, // nextHandler
    });
  }

  previousStep() {
    this.sharedService.updateData('3');
    this.route.navigate(['/service/estimates'])
  }

  getDateTime(data: any):void{
    if(data.calIndex == 1){
      this.appointment.splice(1,1);
      this.selectedTimeDate.splice(1,1);
      this.selected
      this.isAlternateButton = true;
    }
    this.isTimeMatch = true
    this.selectedCalIndex.datePick = true;

    if(this.selectedTimeDate.length > 0){
      let findId =false,pos = 0
      this.selectedTimeDate.forEach((element:any,index: number) => {
        if(element.selected_id.id === data.selected_id.id){
          findId = true
         
          pos = index
        }
      });
      if(!findId){
        this.selectedTimeDate.push(data)
      }else{
        this.selectedTimeDate[pos]=data
      }
    }else{
      
      this.selectedTimeDate.push(data);
    }
    this.isShowingSelected = true
    let selectedText = data.day + ", "+data.month +" "+data.date +" at "+ data.selectedTime
    if(this.selectedDateList.length == 0){
      this.selectedDateList.push(selectedText)
    }else if(this.selectedDateList.length == 1 && data.calIndex == 1){
      this.selectedDateList[0] = selectedText
    }else if(this.selectedDateList.length == 1){
      this.selectedDateList.push(selectedText)
    }else if(this.selectedDateList.length == 2 && data.calIndex == 2){
      this.selectedDateList[1]  = selectedText
    }
    if(this.selectedTimeDate.length == 2){
     
      let firstAppTime = new Date(this.selectedTimeDate[0].exact_date + ' '+this.selectedTimeDate[0].selectedTime).getTime()
      let secondAppTime = new Date(this.selectedTimeDate[1].exact_date + ' '+this.selectedTimeDate[1].selectedTime).getTime()
     
      if(firstAppTime === secondAppTime || firstAppTime > secondAppTime){
        this.isTimeMatch = false;
        
        if(firstAppTime === secondAppTime){
          this.isTimeMisMatch = false;
        this.UtilsClass.openErrorSnackBar('DASHBOARD.SAME_APPOINTMENT_TIME_CANNOT_SELECTED')
        return 
        }
        else if(firstAppTime > secondAppTime){
          this.isTimeMisMatch = true;
          this.UtilsClass.openErrorSnackBar('DASHBOARD.ALTERNATIVE_TIME_MUST_GREATER_THAN_FIRST')
        return
        }
      }
      else{
        this.isTimeMatch = true;
      }
    }
    this.sharedService.setEstimateTimeData(JSON.stringify(this.selectedTimeDate))
    sessionStorage.setItem(Constants.APP.SESSION_ESTIMATE_TIME,JSON.stringify(this.selectedTimeDate))
  }

  clickAlternateDay(){
    if(this.selectedTimeDate.length != 0 && this.selectedCalIndex.datePick == true){
      this.appointment.push({
        name: "Alternatif randevu zamanı",
        id: 2
    })
    this.isAlternateButton = false
    }else{
      this.UtilsClass.openErrorSnackBar('DASHBOARD.FIRST_APPOINTMENT_MANDATORY')
    }
   
  }

  getCalenderHeader(){
    this.cal1Header = this.moment1Cal.locale('tr').format('MMMM YYYY')
    this.cal2Header = this.moment2Cal.locale('tr').add(1,'M').format('MMMM YYYY')
    this.selectedMonth.setMonth(this.selectedMonth.getMonth() + 1);
    this.calendar1?.updateTodaysDate();
    this._dateAdapter.setLocale('tr')
  }

  previousClicked() {
    if(this.calendar1 != undefined){
      this.calendar1.activeDate = this._dateAdapter?.addCalendarMonths(this.calendar1.activeDate,-1)
      this.cal1Header = this.moment1Cal.locale('tr').add(-1,'M').format('MMMM YYYY')
    }
    if(this.calendar2 != undefined){
      this.calendar2.activeDate = this._dateAdapter?.addCalendarMonths(this.calendar2.activeDate,-1)
      this.cal2Header = this.moment2Cal.locale('tr').add(-1,'M').format('MMMM YYYY')
    }

  }

  nextClicked() {
    if(this.calendar1 != undefined){
      this.calendar1.activeDate = this._dateAdapter?.addCalendarMonths(this.calendar1.activeDate, 1) 
      this.cal1Header = this.moment1Cal.locale('tr').add(1,'M').format('MMMM YYYY')
    }
    if(this.calendar2 != undefined){
      this.calendar2.activeDate = this._dateAdapter?.addCalendarMonths(this.calendar2.activeDate, 1) 
      this.cal2Header = this.moment2Cal.locale('tr').add(1,'M').format('MMMM YYYY')
    }
  }
  currentDateFilter = (d: Date): boolean => {
    return  moment().isBefore(d);;
  }

  onSelect(event:any) {
    this.selectedDate = event;
  }

  clickViewCalendar(event:any){
  }
  clickMonthevent(data:any){
    this.selectedCalIndex = data;
    if(this.appointment[1] && this.selectedCalIndex.calIndex == 1){
      this.appointment.splice(1,1);
      this.selectedTimeDate.splice(1,1);
      this.selected
      this.isAlternateButton = true;
    }
    if(this.selectedTimeDate.length > 0){
      this.selectedTimeDate.forEach((element:any,index:number) => {
        if(element.calIndex == this.selectedCalIndex.calIndex){
          element.datePick = this.selectedCalIndex.datePick;
        }
      });
    }
    
    if(this.selectedDateList.length ==0){
      this.isShowingSelected = false
    }
  }

  

  clickChangeTime(){
  }

  

  bookAppointment(){
    let userdata = localStorage.getItem(Constants.APP.SESSION_USER_DATA)
    let estimateData = sessionStorage.getItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
    let applied_garage_details = localStorage.getItem(Constants.APP.APPLIED_GARAGES_ID)
    let serviceData = this.getServiceListPayload()
    if (userdata != undefined) {
      let userDetails = JSON.parse(userdata)
      let serviceList = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE);
      let quote_id = sessionStorage.getItem(Constants.APP.QUOTE_ID);
      if (serviceList != null && estimateData != null) {
        let serviceListDetails = JSON.parse(serviceList)
        let estimate_Details = JSON.parse(estimateData)
        let payload: any = {
          
          vechicle_id: serviceListDetails.vechicle_id,
          service: serviceData,
          user_id: userDetails.user_id,
          garage_id: estimate_Details.id,
          status:"booked",
          estimation_type: estimate_Details.quote.estimation_type, 
          estimation: estimate_Details.quote.items,   
          amount_to_pay: estimate_Details.quote.finalPrice,
          total_amount: estimate_Details.quote.finalPrice,
          appointments:{
            1: this.selectedTimeDate[0].exact_date + " "+this.selectedTimeDate[0].selectedTime,
            2: (this.selectedTimeDate[1] != undefined) ? this.selectedTimeDate[1].exact_date + " "+this.selectedTimeDate[1].selectedTime : ''
        }
        }
        if(applied_garage_details != undefined){
          let applied_garage_data = JSON.parse(applied_garage_details)
          applied_garage_data.forEach((item:any)=>{
            if(item.garage_id == estimate_Details.id ){
              item.status = 'booked'
            }else{
              item.status = 'not booked'
            }
          });
          payload.applied_garage_details = applied_garage_data
         
        }
        if(quote_id != undefined){
          payload.quote_id = Number(quote_id)
        }else{
          payload.zip_code = localStorage.getItem("address")
        }
        this.BookingService.saveEstimate(payload).subscribe({
          next: (res) => {
            
            if(res.statusCode == 1){
              sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_DETAILS)
              sessionStorage.removeItem(Constants.APP.SESSION_ESTIMATE_TIME)
              sessionStorage.removeItem(Constants.APP.SESSION_ORDER_SERVICE)
              sessionStorage.removeItem(Constants.APP.QUOTE_ID)
              localStorage.removeItem(Constants.APP.SERVICE_LIST)
              localStorage.removeItem(Constants.APP.APPLIED_GARAGES_ID)
              document.getElementById('btncontinue')?.classList.add('is-loading')
              this.UtilsClass.openSuccessSnackBar(this.translate.instant(res.message))
              let notification_data = {
                types : "New Job",
                reference_id : '',
                title : `${userDetails.first_name} ${userDetails.last_name}`,
                content : `new Job registered in Arabama`
            }
            this.socketService.emit('adminNotification',notification_data)

               let notification_data_garage = {
                types : "Booked",
                reference_id : quote_id,
                receiver_id : estimate_Details.id,
                title : `Appointment Created`,
                content : `Appointment On ${this.selectedTimeDate[0].exact_date} ${this.selectedTimeDate[0].selectedTime}`
            }
              this.socketService.emit('garageNotificationById',notification_data_garage)
              this.sharedService.setBookedPopup(true)
              this.route.navigate(['/dashboard'])
            }
          },
          error: (err) => {
            this.UtilsClass.openSuccessSnackBar(err.error.message)
            document.getElementById('btncontinue')?.classList.remove('is-loading')
          }
        })
      }
    }
  }

  getServiceListPayload() {
    let serviceList = sessionStorage.getItem(Constants.APP.SESSION_ORDER_SERVICE) || '' ;
    serviceList = JSON.parse(serviceList)
    let serviceDetails: any = localStorage.getItem(Constants.APP.SERVICE_LIST)
    serviceDetails = JSON.parse(serviceDetails)
    let serviceDetailsList: any[] = [];
    serviceDetails.forEach((element: any) => {
      if(!element.question.fromMaster){
      let service = {
        id: element.answer.id,
        comment: element.comment,
        is_from_all_service: element.is_from_all_service,
        service_id : element.question.service_id,
        selected_answer : element.selected_answer
      }
      serviceDetailsList.push(service)
    }
    });
    return serviceDetailsList
  }

  ngOnDestroy(): void {
    this.isAliva = false
    this.alive =false
  }
}
