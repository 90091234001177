import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Constants } from 'src/app/Constants/constants';
import { PassDataService } from 'src/app/services/pass-data.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(40%)' }), // Start invisible and off-screen
        animate('0.7s ease-in-out', style({ opacity: 1, transform: 'translateX(0%)' })), // Slide in and become visible
      ]),
      transition(':leave', [
        animate('0.7s ease-in-out', style({ opacity: 0, transform: 'translateX(100%)' })), // Slide out and become invisible
      ]),
    ])
  ],
})
export class JobDetailsComponent implements OnInit {
  @Input() stepperCount: any;
  @Output() closePopup = new EventEmitter<boolean>();

  constructor(private sharedService: SharedServiceService, private route: Router, private passService: PassDataService) { }

  ngOnInit(): void {
  
  }
 
  backPressed(){
    this.closePopup.emit(false)
  
  }
}
