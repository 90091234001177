import { environment } from "src/environments/environment";
export class Constants {
    public static BASE_URL = environment.BASEURL;
    public static API = {
      GET_SERVICES_AND_PINCODES : 'service/getServiceAndZipCode',
      GET_POPULAR_SERVICE : "service/getPopular",
      GET_VECHICLE_MODEL_YEAR : "car/getYear",
      GET_VECHICLE_BRAND : "car/make",
      GET_VECHICLE_MODEL : "car/model",
      GET_VECHICLE_SERIES : "car/series",
      GET_VECHICLE_TRIM : "car/trim",
      GET_VECHICLE_QUOTES_DIRECT : "garage/quote",
      GET_PROBLEM_LIST: "service/getProblem",
      GET_SERVICE_BY_CATEGORY: "service/getServicesByCategory",
      USER_REGISTRATION: "user/register",
      AUTH_REGISTRATION : "user/authRegister",
      USER_LOGIN: "user/login",
      ADD_VECHICLE: "car/add",
      REMOVE_VECHICLE: "car/remove",
      FETCH_VECHICLE: "car/fetch",
      SOCIAL_LOGIN: "user/socialLogin",
      UPDATE_PROFILE: "user/updateProfile",
      REQUEST_OTP: "user/requestOtp",
      VERIFIY_OTP: "user/verifyOtp",
      SAVE_ESTIMATE: "appointment/request",
      FETCH_ALL_APPOINTMENT: "appointment/fetchAll",
      GET_ALL_SERVICES_CATEGORY: "service/getAllServiceCategory",
      GET_ALL_REVIEWS: "user/reviews",
      ADD_USER_REVIEWS: "user/addReviews",
      FETCH_JOB_TIMINGS : "garage/jobTimings",
      SEND_MSG : "user/sendMsg",
      GET_MSG : "user/getMsg",
      SET_READ_MSG: "user/setReadMsg",
      ADD_TICKET: "user/addTicket",
      FETCH_ALL_NOTIFICATION: "user/fetchAllNotification",
      SET_READ_NOTIFICATION: "user/setReadNotification",
      GET_NOTIFICATION_COUNT: "user/getNotificationCount",
      FETCH_CITY: "user/fetchCity",
      FETCH_DISTRICT: "user/fetchDistrict",
      DELETE_ACCOUNT : "user/deleteUser",
      FETCH_DRIVER : "user/getDriverProfile",
      FETCH_LEGAL_CATEGORY: "user/fetchLegalCategory"

	}

  public static APP = {
    SESSION_ESTIMATE_DETAILS: "estimate_details",
    SESSION_ORDER_SERVICE : "order_servicelist",
    SESSION_VECHICLE_LIST: "vechicle_list",
    SESSION_ESTIMATE_TIME: "estimate_time",
    SESSION_USER_DATA : "user_data",
    SESSION_ID: "session_id",
    SESSION_VIEW_QUOTE: 'view_quote',
    QUOTE_ID: "quote_id",
    USER_LOGIN: "user_login",
    LOCATION_ARRAY: "location_array_list",
    SERVICE_LIST: "service-list",
    APPLIED_GARAGES_ID : "applied_garage_id",
    LAST_INDEX_TIME : "last_index_time"
  }
  
}